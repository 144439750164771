import { GET_KEYS, DELETE_KEY, ADD_KEY, UPDATE_KEY, RESET, RESET_KEYS } from '../actions'
import { getColumnsOfTable, deleteItemFromArray } from '../services'

export default function KeysReducer(state = {
    keys: null,
    fields: null
}, action) {
    switch (action.type) {
        case GET_KEYS:
            const columnsName = getColumnsOfTable(action.payload.data.results[0])
            return {
                ...state,
                keys: action.payload.data.results,
                fields: columnsName
            }
        case DELETE_KEY:
            return { ...state, keys: deleteItemFromArray(state.keys, action.payload, 'ID') }
        case ADD_KEY:
        case UPDATE_KEY:
            return {
                ...state,
                keys: null,
                fields: null
            }
        case RESET_KEYS:
        case RESET:
            return {
                ...state,
                keys: null,
                fields: null
            }
        default:
            return state
    }
}