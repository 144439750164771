import React from 'react'
import PropTypes from "prop-types"
import { useTheme, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableFooter, TablePagination, } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {
        maxHeight: 590,
        overflow: 'auto',
        margin: '10px'
    }, tablecell: {

        align: 'right',
        padding: '1px'
    }, buscador: {
        // marginLeft:623, completa
        marginLeft: 185,
    }
});

const MyCart = (props) => {
    const classes = useStyles();
    const { hasError, productSelected, columns } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, productSelected.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (hasError)
        return <h1>Something went wrong.</h1>;
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <div style={{ margin: '5px', fontWeight: 'bold' }}>CART</div>
                    {props.children}
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                {columns.map(row => {
                                    if (row.Header !== "Actions") {
                                        return <TableCell className={classes.tablecell} >{row.Header}</TableCell>
                                    } else {
                                        return <TableCell className={classes.tablecell} />
                                    }
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? productSelected.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : productSelected).map(row => {
                                    let cells = []
                                    for (const item in columns) {
                                        if (columns.hasOwnProperty(item)) {
                                            const column = columns[item]
                                            let value
                                            if (column.hasOwnProperty('Cell')) {
                                                if (typeof column.Cell === 'function') {
                                                    value = column.Cell(row, column)
                                                }
                                            } else if (column.hasOwnProperty('accessor')) {
                                                if (typeof column.accessor === 'function') {
                                                    value = column.accessor(row)//+column.Cell
                                                } else {
                                                    value = row[column.accessor]
                                                }
                                            } else {
                                                value = row['id']
                                            }
                                            cells.push(<TableCell className={classes.tablecell}>{value}</TableCell>)
                                        }
                                    }
                                    return (<TableRow>
                                        {cells}
                                    </TableRow>)
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell className={classes.tablecell} colSpan={6} />
                                </TableRow>)}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={productSelected.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    selectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Paper>
        </div>
    )
}

MyCart.propTypes = {
    onInputValueChange: PropTypes.func
}

export default MyCart;