import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setNewPassword } from '../actions'
import NewPasswordComponent from '../components/NewPassword'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { withRouter } from 'react-router-dom'

class NewPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                password: '',
                repeatPassword: '',
            },
            isButtonDisabled: false
        }

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.formData.password) {
                return false
            }
            return true
        })
    }

    onInputValueChange = ({ target }) => {
        const { formData } = this.state
        formData[target.name] = target.value
        this.setState({ formData })
    };

    handleSubmit = (password) => {
        this.props.setNewPassword(this.props.signedInUser, password, this.props.history)
        this.setState({ isButtonDisabled: true })
    }

    render() {
        return (
            <NewPasswordComponent
                formData={this.state.formData}
                onInputValueChange={this.onInputValueChange}
                handleSubmit={this.handleSubmit}
                isButtonDisabled={this.state.isButtonDisabled}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        signedInUser: state.Auth.signedInUser
    }
}


export default withRouter(connect(mapStateToProps, { setNewPassword })(NewPassword))