import React, { Component } from 'react'
import { withStyles, AppBar, Tabs, Tab } from '@material-ui/core/'
import { isTablet, isMobile } from 'react-device-detect'
import { PreventNavigation } from '../services'
import MenuAppBar from './MenuAppBar'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    tabRoot: {
        textDecoration: "none",
        color: '#adacb4',
        '&$tabSelected': {
            color: '#00bcd4',
        }
    },
    media: {
        backgroundColor: "#36363c"
    },
    tabSelected: {},
    overrides: {
        tab: { root: { minWidth: 10 } }
    }
})

class SubHeader extends Component {

    constructor(props) {
        super(props)
        this.state = {
            menu_state: 'none',
            selectedTab: this.getDefaultTabSelected(this.props.history.location.pathname, props.menus),
        }
    }

    menuToggleClickHandler = () => {
        if (this.state.menu_state === 'none') {
            this.setState({
                menu_state: 'block',
            })
        }
        else if (this.state.menu_state === 'block') {
            this.setState({
                menu_state: 'none',
            })

        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({ selectedTab: this.getDefaultTabSelected(nextProps.history.location.pathname, nextProps.menus) })
    }

    getDefaultTabSelected = (currentUrl, menus) => {
        let activeTab = 0
        menus.map((item, index) => {
            if (item.link === currentUrl || item.link.split('/')[1] === currentUrl.split('/').slice(1)[0] || currentUrl.split('/')[1].toLowerCase().includes(item.link.split('/')[1].toLowerCase()))
                activeTab = index
            return null
        })
        return activeTab
    }

    renderTabs = () => {
        const menusArray = []
        const { classes } = this.props
        if (isMobile || isTablet) {
            return <MenuAppBar menus={this.props.menus} />
        } else {
            this.props.menus.forEach((item, index) => {
                menusArray.push(<Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label={item.title} key={index} />)
            })
            return menusArray
        }
    }

    handleChange = (event, value) => {
        const isDifferent = PreventNavigation(this.props.history.location.pathname, this.props.menus[value].link)
        if (isDifferent) {
            this.props.history.push(this.props.menus[value].link)
            this.setState({ selectedTab: value })
        }
    };

    render() {
        const { selectedTab } = this.state
        const { classes } = this.props
        return (
            <div style={{ borderTop: "1px solid #514d4e" }} >
                <AppBar position="static" className={classes.media}  >
                    <Tabs
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        onChange={(event, value) => this.handleChange(event, value)}
                    >
                        {this.renderTabs()}
                    </Tabs>
                </AppBar>
            </div>
        )
    }
}

export default withStyles(styles)(SubHeader)