import React from 'react'
import PropTypes from "prop-types"
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, TablePagination, } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {

        overflow: 'auto',
    }, tablecell: {
        minWidth: 170,
        align: 'right',
    }
});

const TableToImportCSV = (props) => {

    const classes = useStyles();
    const { hasError, data, columns, defaultPageSize } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultPageSize);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (hasError)
        return <h1>Something went wrong.</h1>;
    return (
        <div className={classes.root}>
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    {props.children}
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((row, index) => {
                                    let value
                                    if (row.hasOwnProperty('Header')) {
                                        if (typeof row.Header === 'function') {
                                            value = columns[index].Header(row)
                                        }
                                    } else {
                                        value = row['id']
                                    }
                                    return (<TableCell className={classes.tablecell} key={index}>{value}</TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : data).map((row, index) => {
                                    let cells = []
                                    for (const item in columns) {
                                        if (columns.hasOwnProperty(item)) {
                                            const column = columns[item]
                                            let value
                                            if (column.hasOwnProperty('Cell')) {
                                                if (typeof column.Cell === 'function') {
                                                    value = column.Cell(row, column)
                                                }
                                            } else if (column.hasOwnProperty('accessor')) {
                                                if (typeof column.accessor === 'function') {
                                                    value = column.accessor(row)//+column.Cell
                                                } else {
                                                    value = row[column.accessor]
                                                }
                                            } else {
                                                value = row['id']
                                            }
                                            cells.push(value)
                                        }
                                    }
                                    return (<TableRow key={index}>
                                        {cells.map((cell, indexTwo) => {
                                            return (<TableCell key={indexTwo} className={classes.tablecell}>{cell}</TableCell>)
                                        })}
                                    </TableRow>)
                                })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    style={{ marginLeft: -15 }}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{ 'aria-label': 'previous page' }}
                    nextIconButtonProps={{ 'aria-label': 'next page' }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    )
}

TableToImportCSV.propTypes = {
    onInputValueChange: PropTypes.func
};
export default TableToImportCSV;