import React, { Component } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

class Spinner extends Component {
    render() {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '50vh' }}
            >
                <CircularProgress size="150px" variant="indeterminate" />
            </Grid>
        );
    }
}

export default Spinner;