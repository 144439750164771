import { GET_ORDERS, GET_TOP_TEN_ORDERS, DELETE_ORDER, RESET } from '../actions'
import { getColumnsOfTable } from '../services'

const findItem = (element, id) => {
    return element['PO #'] === id
}

const deleteItemFromArray = (ItemsArray, id) => {
    const idToDelete = ItemsArray.findIndex((element) => findItem(element, id))
    delete ItemsArray[idToDelete]
    ItemsArray.splice(idToDelete, 1)
    return ItemsArray
}

export default function OrdersReducer(state = { orders: null, recentOrders: null }, action) {
    switch (action.type) {
        case GET_ORDERS:
            let result = action.payload.data.results
            let orders = []
            for (let item of result) {
                if (!item['PRODUCTS']) {
                    let index = orders.findIndex((element) => {
                        return element['PO #'] === item['PO #']
                    })

                    if (index === -1) {
                        item['OS_ITEM'] = []
                        item['OS_ITEM'].push({ 'TITLE': item['TITLE'], 'QUANTITY': item['QUANTITY'] })
                        orders.push(item)
                    } else {
                        orders[index]['OS_ITEM'].push({ 'TITLE': item['TITLE'], 'QUANTITY': item['QUANTITY'] })
                    }
                } else {
                    orders.push(item)
                }
            }

            if (!state.fields) {
                const columnsName = getColumnsOfTable(action.payload.data.results[0])
                return {
                    ...state,
                    orders,
                    fields: columnsName
                }
            }
            return { ...state, orders }
        case GET_TOP_TEN_ORDERS:
            if (!state.fields) {
                const columnsName = getColumnsOfTable(action.payload.data.results[0])
                return {
                    ...state,
                    recentOrders: action.payload.data.results,
                    fields: columnsName
                }
            }
            return { ...state, recentOrders: action.payload.data.results }
        case DELETE_ORDER:
            return { ...state, orders: deleteItemFromArray(state.orders, action.payload, 'ID') }
        case RESET:
            return { ...state, orders: null, fields: null, recentOrders: null }
        default:
            return state
    }
}
