import { GET_USERS, DELETE_USER, UPDATE_USER, RESET, ADD_USER, GET_GROUPS, ACTIVE_USER } from '../actions'
import { deleteUserFromArray, updateUserFromArray, activeUser } from '../services'

export default function UsersReducer(state = { users: null, groups: null }, action) {
  switch (action.type) {
    case GET_GROUPS:
      return {
        ...state,
        groups: action.payload
      }
    case GET_USERS:
      let users = action.payload
      users.sort((a, b) => a.Username.localeCompare(b.Username))
      return {
        ...state,
        users: action.payload
      }
    case ACTIVE_USER:
      return { ...state, users: activeUser(state.users, action.payload) }
    case DELETE_USER:
      return { ...state, users: deleteUserFromArray(state.users, action.payload) }
    case ADD_USER:
      return {
        ...state,
        users: null,
        groups: null
      }
    case UPDATE_USER:
      return { ...state, users: updateUserFromArray(state.users, action.payload) }
    case RESET:
      return { ...state, groups: null, users: null }
    default:
      return state
  }
}