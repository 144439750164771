import React, { Component } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withStyles, Card, Button, Grid, CardMedia, CardContent, Typography } from "@material-ui/core";
import logo from '../resources/Images/gemvision_authorized_dealer_icon.svg';

const styles = theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: '8%'
    },
    boxCard: {
        width: "800px !important"
    },
    content: {
        padding: "45px 70px !important"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: "100%",
        marginTop: 0,
        marginBottom: "20px"
    },
    media: {
        height: 100,
        backgroundColor: "#36363c",
        backgroundSize: "145px"
    },
    button: {
        marginTop: "35px",
        width: "80%"
    },
    card: {
        padding: "20px"
    }
});

class NewPasswordComponent extends Component {
    render() {
        const {
            formData,
            onInputValueChange,
            handleSubmit,
            classes,
            isButtonDisabled
        } = this.props;
        return (
            <div className={classes.wrapper}>
                <Card className={classes.boxCard}>
                    <CardMedia
                        className={classes.media}
                        image={logo}
                        title="Contemplative Reptile"
                    />
                    <CardContent className={classes.content}>
                        <Typography gutterBottom variant="h5" component="h2">
                            Set New Password
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            Welcome to the Dealer Portal. Complete the access to your Dealer Portal acount by setting your new password.
                        </Typography>
                        <ValidatorForm
                            onSubmit={e => {
                                e.preventDefault();
                                handleSubmit(formData.password)
                            }}
                        >
                            <Grid container >
                                <Grid container item xs={6} justifyContent="center">
                                    <TextValidator
                                        name="password"
                                        label="New Password"
                                        value={formData.password}
                                        className={classes.textField}
                                        onChange={onInputValueChange}
                                        type="password"
                                        validators={['required', 'matchRegexp:(?=.*[0-9])', 'matchRegexp:(?=^.{8,})', 'matchRegexp:(?=.*[a-z])', 'matchRegexp:(?=.*?[.,:;"!¡?¿@#$%^&*+_=|])']}
                                        errorMessages={['This field is required', 'At least 1 number', 'At least 8 characters', 'At least 1 letter', 'At least 1 special character']}
                                    />
                                    <TextValidator
                                        name="repeatPassword"
                                        label="Repeat password"
                                        value={formData.repeatPassword}
                                        className={classes.textField}
                                        onChange={onInputValueChange}
                                        type="password"
                                        validators={['isPasswordMatch', 'required']}
                                        errorMessages={['Password mismatch', 'This field is required']}
                                    />
                                </Grid>
                                <Grid container item xs={6} justifyContent="flex-end">
                                    <Card >
                                        <CardContent className={classes.card}>
                                            <Typography color="textSecondary" gutterBottom>
                                                Password must contain the following:
                                            </Typography>
                                            <Typography color="textSecondary">
                                                - At least 8 characters
                                            </Typography>
                                            <Typography color="textSecondary">
                                                - At least 1 letter (a, b, c...)
                                            </Typography>
                                            <Typography color="textSecondary" >
                                                - At least 1 number (1, 2, 3...)
                                            </Typography>
                                            <Typography color="textSecondary">
                                                - At least 1 special character ($, @, %...)
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid container item xs={12} justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.button}
                                        type="submit"
                                        disabled={isButtonDisabled}
                                    >
                                        Change Password
                                </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </CardContent>
                </Card>
            </div >
        );
    }
}

export default withStyles(styles)(NewPasswordComponent)
