export const TITLE = 'TITLE'
export const PARTNUMBER = 'PARTNUMBER'
export const PRICE = 'PRICE'
export const PRODUCTTYPE = 'PRODUCTTYPE'
export const VERSION = 'VERSION'
export const ISKEY = 'ISKEY'
export const AVAILABLEFORSTOCK = 'AVAILABLEFORSTOCK'
export const USAVAILABLE = 'USAVAILABLE'
export const UKAVAILABLE = 'UKAVAILABLE'
export const AUAVAILABLE = 'AUAVAILABLE'
export const ELSEAVAILABLE = 'ELSEAVAILABLE'
export const ENABLED = 'ENABLED'
export const OPTIONS = 'OPTIONS'
export const NAMEIMAGE = 'NAMEIMAGE'
export const IMAGE = 'IMAGE'
export const IMAGEPATH = 'IMAGEPATH'
export const ProductsHeadersArray = [TITLE, PARTNUMBER, PRICE, PRODUCTTYPE, OPTIONS, VERSION, ISKEY, AVAILABLEFORSTOCK, USAVAILABLE, UKAVAILABLE, AUAVAILABLE, ELSEAVAILABLE, ENABLED]
export const RequiredHeadersArray = [TITLE, PARTNUMBER, PRICE, PRODUCTTYPE, OPTIONS]