import { AuthKeys } from './types'
import history from '../history'
import { RESET } from '.'
import { showNotification } from '../services/notification'
import { Auth } from 'aws-amplify'

let store = require('store/storages/sessionStorage')

const {
    SIGN_IN_USER_SUCCESS,
    SET_NEW_PASSWORD,
    RESET_PASSWORD_REQUIRED,
    SIGN_OUT_USER_SUCCESS,
} = AuthKeys

export const signInUser = function (username, password) {
    return async (dispatch) => {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await Auth.signIn(username, password)
                if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
                    dispatch({
                        type: SET_NEW_PASSWORD,
                        payload: res
                    })
                    history.push('/newpassword')
                    return
                }
                const result = await Auth.currentAuthenticatedUser({ bypassCache: true })
                dispatch({
                    type: SIGN_IN_USER_SUCCESS,
                    payload: result
                })
                if (!!result.signInUserSession.idToken.payload['cognito:groups']) {
                    let date = Date.now()
                    store.write('dateWhenSignIn', date)
                    history.push('/home')
                } else {
                    history.replace('/nogroups')
                }
                resolve()
            } catch (err) {
                let config = ''
                if (err.code === 'PasswordResetRequiredException') {
                    dispatch({ type: RESET_PASSWORD_REQUIRED })
                    history.push('/resetpassword')
                } else if (err.code === 'InvalidParameterException') {
                    config = {
                        icon: 'error',
                        type: 'error',
                        message: 'Invalid username.',
                        autoClose: true,
                    }
                } else {
                    config = {
                        icon: 'error',
                        type: 'error',
                        message: err.message,
                        autoClose: true,
                    }
                }
                if (config) {
                    showNotification(config)
                }
                reject()
            }
        })
    }
}

export const signOutUser = () => {
    return async (dispatch) => {
        try {
            await Auth.signOut()
        } catch (error) {
            console.error('error signing out: ', error)
            return
        }
        // TODO: Check if this of store... can be removed
        store.remove("ViewMode")
        history.replace('/')
        dispatch({ type: SIGN_OUT_USER_SUCCESS })
        dispatch({ type: RESET })
    }
}

export const setNewPassword = (username, newPassword, history) => {
    return async (dispatch) => {
        try {
            const result = await Auth.completeNewPassword(username, newPassword)
            dispatch({
                type: SIGN_IN_USER_SUCCESS,
                payload: result
            })
            if (!!result.signInUserSession.idToken.payload['cognito:groups']) {
                let date = Date.now()
                store.write('dateWhenSignIn', date)
                history.push('/home')
            } else {
                history.replace('/nogroups')          
            }
        } catch (err) {
            console.error(err)
            let config = {
                icon: 'error',
                type: 'error',
                message: err.message,
                autoClose: true,
            }
            showNotification(config)
            history.push('/')
        }
    }
}

export const resetPassword = (username, newPassword, validationCode) => {
    return () => {
        return new Promise(async (resolve, reject) => {
            try {
                await Auth.forgotPasswordSubmit(username, validationCode, newPassword)
                resolve()
            } catch (err) {
                console.error(err.message)
                reject()
            }
        })
    }
}


export const forgotPassword = (email) => {
    return () => {
        return new Promise(async (resolve, reject) => {
            try {
                await Auth.forgotPassword(email)
                showNotification({
                    icon: 'check',
                    type: 'success',
                    message: 'We had sent an email. Please check your email. You may need to check your spam folder',
                    autoClose: true,
                })
                resolve()
            } catch (err) {
                showNotification({
                    icon: 'error',
                    type: 'error',
                    message: err.message,
                    autoClose: true,
                })
                reject()
            }
        })
    }
}


export const registerUser = (cognitoUser) => {
    return (dispatch) => {
        dispatch({
            type: SIGN_IN_USER_SUCCESS,
            payload: cognitoUser
        })
    }
}