import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton, Icon, Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format'

import Generic from '../../components/Generic';
import { getSalesFromApi, deleteSale } from '../../actions';
import ShowOrder from '../../components/Dialog/ShowOrder';
import Spinner from '../../components/Spinner';
import { getProductOfSale } from '../../services'

var dateFormat = require('dateformat');

class Sales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnsForCSV: [
                { id: 'EMAIL' },
                { id: 'COUNTRYID' },
                { id: 'PRODUCTS' }
            ],
            hasError: false,
            search: '',
            configPage: {
                title: 'Sales',
                buttonDownload: true,
                downloadName: 'Sales',
                buttonAddNew: false,
            },
            anchorEl: null,
            selectID: null,
            typeDialog: null,
            addtionalDatas: []
        };
    }

    componentDidMount = () => {
        if (!this.props.SalesReducer.sales) {
            let id = this.props.viewMode === 'Dealers' ? this.props.userId : null;
            this.props.getSalesFromApi(this.props.userToken.idToken.jwtToken, id)
                .catch(() => {
                    this.props.getSalesFromApi(this.props.userToken.idToken.jwtToken, id)
                        .catch(() => this.setState({ hasError: true }))
                })
        } else {
            this.makeColumns(this.props.SalesReducer.fields)
            this.makeData(this.props.SalesReducer.sales)
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.SalesReducer.fields) {
            let id = this.props.viewMode === 'Dealers' ? this.props.userId : null;
            nextProps.getSalesFromApi(nextProps.userToken.idToken.jwtToken, id).catch(() => this.setState({ hasError: true }))
        }
        if (!!nextProps.SalesReducer.sales) {
            this.makeColumns(nextProps.SalesReducer.fields)
            this.makeData(nextProps.SalesReducer.sales)
        }
    }

    deleteItem(row) {
        this.props.deleteSale(this.props.userToken.idToken.jwtToken, row['Sale #'])
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
            selectID: null,
            typeDialog: null,
            addtionalDatas: []
        });
    }

    handleClick = (event, row, type) => {
        if (!row['PRODUCTS'])
            getProductOfSale(this.props.userToken.idToken.jwtToken, row['Sale #']).then((result) => {
                if (result.length === 0)
                    this.setState({ addtionalDatas: [-1] })
                else
                    this.setState({ addtionalDatas: result })
            })
        this.setState({
            anchorEl: event.currentTarget,
            selectID: row['Sale #'],
            typeDialog: type
        });
    };

    makeColumns = (sales) => {
        let arrayOfItems = []
        sales.forEach((item) => {
            switch (item) {
                case 'Sale #':
                    arrayOfItems.push({ Header: item, id: item, accessor: item, style: { textAlign: "center" } }); break;
                case 'Date':
                    arrayOfItems.push({
                        Header: item, id: item, accessor: d =>
                            <div>{dateFormat(d.Date, "fullDate")}</div>
                        , minWidth: 220
                    }); break;
                case 'Total Cost':
                    arrayOfItems.push({
                        Header: item, id: item, accessor: d =>
                            <div ><NumberFormat value={d['Total Cost']} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></div>
                    }); break;
                case 'COMPANY':
                    if (this.props.viewMode !== 'Dealers')
                        arrayOfItems.push({ Header: 'Dealer', accessor: item, id: 'DEALERCOMPANY', minWidth: 220 });
                    break;
                case 'FIRSTNAME':
                    arrayOfItems.push({
                        Header: 'Name', id: 'Name', accessor: d =>
                            <div >{d.FIRSTNAME + " " + d.LASTNAME} </div>
                        , minWidth: 220
                    }); break;
                default: break;
            }
        })
        arrayOfItems.push({
            minWidth: 70,
            Header: 'Actions',
            Cell: row => {
                return (
                    <div>
                        <Grid container justifyContent="center" item xs={12}>
                            <Grid>
                                <IconButton onClick={(e) => this.handleClick(e, row, 'order')}>
                                    <Icon>more_horiz</Icon>
                                </IconButton>
                                {Boolean(this.state.selectID === row['Sale #'])
                                    ? <ShowOrder
                                        open={(this.state.typeDialog === 'order') ? Boolean(this.state.selectID === row['Sale #']) : false}
                                        onClose={this.handleClose}
                                        anchorEl={this.state.anchorEl}
                                        data={row}
                                        addtionalDatas={this.state.addtionalDatas} />
                                    : null}
                            </Grid>
                            {() => {
                                if (this.props.viewMode !== 'Delaers') {
                                    return (<Grid>
                                        <IconButton onClick={() => {
                                            this.deleteItem(row)
                                        }}>
                                            <Icon color="error">close</Icon>
                                        </IconButton>
                                    </Grid>)
                                }
                            }}
                        </Grid>
                    </div>
                )
            }
        })
        this.setState({ columns: arrayOfItems })
    }

    makeData(dataOfSales) {
        this.setState({ content: dataOfSales })
    }

    onInputValueChange = ({ target }) => {
        this.setState({ search: target.value.toLowerCase() })
    }

    render() {
        if (!!this.props.SalesReducer.sales) {
            let data = this.props.SalesReducer.sales
            if (this.state.search) {
                data = data.filter(row => {
                    const array = Object.keys(row)
                    let isIncluded = false
                    array.forEach((columnName) => {
                        if (!isIncluded) {
                            if (typeof row[columnName] === 'string') {
                                if ((row[columnName].toLowerCase()).includes(this.state.search.toLowerCase())) {
                                    isIncluded = true
                                }
                            }
                            else if ((String(row[columnName]).toLowerCase()).includes(this.state.search.toLowerCase())) {
                                isIncluded = true
                            }
                        }
                    })
                    if (isIncluded)
                        return true
                    return false;
                })
            }

            const columns = (this.state.columns) ? this.state.columns.concat(this.state.columnsForCSV) : this.state.columnsForCSV

            return (
                <Generic
                    data={data}
                    columns={columns}
                    onInputValueChange={this.onInputValueChange}
                    hasError={this.state.hasError}
                    configPage={this.state.configPage}
                    history={this.props.history} />
            );
        } else if (this.state.hasError)
            return <Generic hasError={this.state.hasError} />
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        SalesReducer: state.SalesReducer,
        viewMode: state.AppReducer.currentViewMode,
        userId: state.Auth.id
    }
}

export default connect(mapStateToProps, { getSalesFromApi, deleteSale })(Sales);