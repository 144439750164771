import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Form from '../../components/Forms/Key';
import { getDealers, getProduct } from '../../services'
import { addKey, updateKey, getKeysFromApi, resetInventory } from '../../actions';
import Spinner from '../../components/Spinner';

class KeysForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                DEALERID: '',
                PRODUCTID: '',
                TYPE: '',
                KEY: ''
            },
            typeForm: 'Add',
            optionsProduct: [{}],
            optionsDealers: [{}],
        }
    }

    componentDidMount() {
        if (this.props.keys === null)
            this.props.getKeysFromApi(this.props.userToken.idToken.jwtToken)
        else if (this.props.match.params.id)
            this.getInitialState(this.props.match.params.id, this.props.keys)
        getProduct(this.props.userToken.idToken.jwtToken, true)
            .then((result) => this.setState({ optionsProduct: result }))
            .catch(() => getProduct(this.props.userToken.idToken.jwtToken, true).then((result) => this.setState({ optionsProduct: result })));

        getDealers(this.props.userToken.idToken.jwtToken).then((result) => {
            this.setState({ optionsDealers: result });
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!this.props.match.params.id && nextProps.keys !== null)
            this.getInitialState(this.props.match.params.id, nextProps.keys)
        getProduct(this.props.userToken.idToken.jwtToken, true).then((result) => {
            this.setState({ optionsProduct: result });
        });
        getDealers(this.props.userToken.idToken.jwtToken).then((result) => {
            this.setState({ optionsDealers: result });
        });
    }

    getInitialState = (id, keysReducer) => {
        if (keysReducer) {
            keysReducer.forEach((element) => {
                if (element.ID === Number(id)) {
                    for (var aux in this.state.formData) {
                        const { formData } = this.state;
                        formData[aux] = element[aux];
                        this.setState({ formData });
                    }
                    this.setState({ typeForm: 'Edit' })
                }
            })
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    handleOnSubmit = (formData) => {
        let product = this.state.optionsProduct.find((item) => item.ID === Number(formData.PRODUCTID))
        formData.TYPE = product.PRODUCTTYPE

        if (this.props.match.params.id) {
            return this.props.updateKey(this.props.userToken.idToken.jwtToken, this.props.match.params.id, formData, this.props.history).then(() => {
                this.props.resetInventory()
            })
        } else {
            return this.props.addKey(this.props.userToken.idToken.jwtToken, formData, this.props.history)
        }
    }

    render() {
        if (!!this.props.keys)
            return (
                <Form
                    title={`${this.state.typeForm} Key`}
                    handleOnSubmit={this.handleOnSubmit}
                    initialValues={this.state.formData}
                    optionsProduct={this.state.optionsProduct}
                    optionsDealers={this.state.optionsDealers}
                    goBack={this.goBack}
                />
            )
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        keys: state.KeysReducer.keys,
        inventories: state.InventoriesReducer.inventories
    }
}
export default withRouter(connect(mapStateToProps, { addKey, updateKey, getKeysFromApi, resetInventory })(KeysForm));
