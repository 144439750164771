import React, { Component } from 'react';
import { withStyles, Paper, Button, Grid, Typography, FormControlLabel, Radio, FormLabel, MenuItem } from "@material-ui/core";
import { Field, reduxForm, Form } from 'redux-form'

import { renderTextField, renderSelectField, renderRadioButtonGroup } from './CommonInputs'
import { required, requiredNumber, email } from './validator'
import { orderBy } from '../../services';

const styles = theme => ({
  boxCard: {
    padding: '20px',
    boxSizing: 'border-box'
  },
  selectContainer: {
    borderBottom: '1px dashed #9f9f9f',
    paddingBottom: '20px',
    marginBottom: '15px'
  },
  textField: {
    textTransform: "capitalize",
    marginTop: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: '100%',
  },
  selectMaterialUI: {
    textTransform: "capitalize",
    marginTop: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: '100%',
  },
  radioButtonContainer: {
    marginTop: '15px',
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  },
  radioButton: {
    flexDirection: 'row',
    width: 'auto',
  },
  button: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  },
  position: {
    marginTop: 10
  }
})

class Customers extends Component {
  addDealerCombo(viewMode, optionsDealer, classes) {
    if (viewMode !== 'Dealers') {
      return (
        <Field
          name="DEALERID"
          label="Dealer"
          component={renderSelectField}
          validate={[requiredNumber]}
          className={classes.textField}
        >
          {orderBy(optionsDealer, "COMPANY")}
          <MenuItem> - </MenuItem>
          {optionsDealer.map((option, index) => (
            <MenuItem key={index} value={option.DealerID}>
              {option.COMPANY}
            </MenuItem>
          ))}
        </Field>
      )
    }
  }

  render() {
    const { handleSubmit, handleOnSubmit, classes, title, optionsDealer, optionsCountry, goBack, viewMode, initialValues, pristine, submitting } = this.props
    return (
      <div style={{ padding: '20px' }}>
        <Paper className={classes.boxCard}>
          <Grid container className={classes.selectContainer}  >
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
          </Grid >
          <Form onSubmit={handleSubmit(handleOnSubmit)} initialvalues={initialValues}>
            <Grid container>
              <Grid container item alignContent="flex-start" xs={12} sm={4} >
                <Typography gutterBottom variant="h5" component="h2" className={classes.position}>
                  Information
                </Typography>
                {this.addDealerCombo(viewMode, optionsDealer, classes)}
                <Field
                  name="COMPANY"
                  label="Company"
                  component={renderTextField}
                  type="text"
                  validate={[required]}
                  className={classes.textField} />
                <Field
                  name="SALUTATION"
                  label="Salutation"
                  component={renderTextField}
                  type="text"
                  className={classes.textField} />
                <Field
                  name="FIRSTNAME"
                  label="First Name"
                  component={renderTextField}
                  type="text"
                  validate={[required]}
                  className={classes.textField} />
                <Field
                  name="LASTNAME"
                  label="Last Name"
                  component={renderTextField}
                  type="text"
                  validate={[required]}
                  className={classes.textField} />
                <Field
                  name="JOBTITLE"
                  label="Job Title"
                  component={renderTextField}
                  type="text"
                  className={classes.textField} />
                <div className={classes.radioButtonContainer}>
                  <FormLabel component="legend">Role</FormLabel>
                  <Field name="ROLE" className={classes.textField} component={renderRadioButtonGroup}>
                    <FormControlLabel value="Contracts" control={<Radio color="primary" />} label="Contracts" />
                    <FormControlLabel value="Billing" control={<Radio color="primary" />} label="Billing" />
                  </Field>
                </div>
              </Grid>
              <Grid container item alignContent="flex-start" xs={12} sm={4}>
                <Typography gutterBottom variant="h5" component="h2" className={classes.position}>
                  Address
                </Typography>
                <Field
                  name="ADDRESS1"
                  label="Address"
                  component={renderTextField}
                  type="text"
                  validate={[required]}
                  className={classes.textField} />
                <Field
                  name="CITY_STATEPROVINCE"
                  label="City"
                  component={renderTextField}
                  type="text"
                  validate={[required]}
                  className={classes.textField} />
                <Field
                  name="POSTALCODE"
                  label="Postal Code"
                  component={renderTextField}
                  type="text"
                  className={classes.textField} />
                <Field
                  name="COUNTRYID"
                  label="Country"
                  component={renderSelectField}
                  validate={[requiredNumber]}
                  className={classes.textField}
                >
                  {orderBy(optionsCountry, "DISPLAY")}
                  <MenuItem></MenuItem>
                  {optionsCountry.map((option, index) => (
                    <MenuItem key={index} value={option.ID}>
                      {option.DISPLAY}
                    </MenuItem>
                  ))}
                </Field>
                <div className={classes.radioButtonContainer}>
                  <FormLabel component="legend">Location Type</FormLabel>
                  <Field name="LOCATIONTYPE" component={renderRadioButtonGroup}
                    className={classes.textField}>
                    <FormControlLabel value="Commercial" control={<Radio color="primary" />} label="Commercial" />
                    <FormControlLabel value="Residential" control={<Radio color="primary" />} label="Residential" />
                  </Field>
                </div>
              </Grid>
              <Grid container item alignContent="flex-start" xs={12} sm={4}>
                <Typography gutterBottom variant="h5" component="h2" className={classes.position}>
                  Contact
                </Typography>
                <Field
                  name="PHONE"
                  label="Phone"
                  component={renderTextField}
                  type="text"
                  validate={[required]}
                  className={classes.textField} />
                <Field
                  name="FAX"
                  label="Fax"
                  component={renderTextField}
                  type="text"
                  className={classes.textField} />
                <Field
                  name="EMAIL"
                  label="Email"
                  component={renderTextField}
                  type="text"
                  validate={[required, email]}
                  className={classes.textField} />
                <Field
                  name="WEBSITE"
                  label="Website"
                  component={renderTextField}
                  type="text"
                  className={classes.textField} />
              </Grid>
              <Grid item >
                <Grid container item xs={12} justifyContent="center" style={{ marginTop: 30 }}>
                  <Button
                    variant="contained"
                    color="default"
                    size="large"
                    className={classes.button}
                    onClick={goBack}
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Paper >
      </div>
    )
  }
}

export default withStyles(styles)(reduxForm({ form: 'customer' })(Customers))
