import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, Form, formValueSelector } from 'redux-form'
import {
    withStyles, Button, Grid, Typography, FormControlLabel, Radio, FormLabel, Paper,
    MenuItem, Accordion, AccordionDetails, AccordionSummary, Icon, Dialog
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { renderTextField, renderSelectField, renderRadioButtonGroup, renderSelectMultipleField, renderCheckbox } from './CommonInputs'
import { finder, orderBy } from '../../services'
import { required, email } from './validator'
import UsersForm from '../../containers/Users/UsersForm';

const styles = theme => ({
    boxCard: {
        padding: '20px',
        boxSizing: 'border-box'
    },
    selectContainer: {
        borderBottom: '1px dashed #9f9f9f',
        paddingBottom: '20px',
        marginBottom: '15px'
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '100%',
    },
    radioButtonContainer: {
        marginTop: '15px',
        marginLeft: theme.spacing(),
        marginRight: theme.spacing()
    },
    radioButton: {
        flexDirection: 'row',
        width: 'auto',
    },
    button: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing()
    },
    margintop: {
        marginTop: 10
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    Accordion: {
        width: '100%'
    }
})

class Dealers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addUserOpen: false,
            expanded: props.initialValues.CountriesName.map(() => true)
        }
    }

    UNSAFE_componentWillReceiveProps = () => {
        const { initialValues } = this.props
        if (initialValues.contactData.length > 0 && this.state.expanded.length === 0) {
            let expanded = initialValues.contactData.map(() => true)
            this.setState({ expanded })
        }
    }

    renderAccordionsForEachCountry = (countriesNameField) => {
        const { classes, indexMainData } = this.props
        return countriesNameField.map((countryName, index) => {
            return <Accordion className={classes.Accordion} key={countryName} defaultExpanded={true} expanded={this.state.expanded[index]}
                onChange={(event, expanded) => {
                    let newExpanded = [...this.state.expanded]
                    newExpanded[index] = expanded
                    this.setState({ expanded: newExpanded })
                }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                >
                    <Typography className={classes.heading}>{countryName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container xs={12}>
                        <Grid container alignContent="flex-start" item xs={1} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].SALUTATION`}
                                label="Salutation"
                                className={classes.textField}
                                component={renderTextField}
                                type="text" />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={4} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].FIRSTNAME`}
                                label="First Name"
                                className={classes.textField}
                                component={renderTextField}
                                type="text"
                                validate={[required]} />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={5} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].LASTNAME`}
                                label="Last Name"
                                className={classes.textField}
                                component={renderTextField}
                                type="text"
                                validate={[required]} />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={2} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].JOBTITLE`}
                                label="Job Title"
                                className={classes.textField}
                                component={renderTextField}
                                type="text" />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={5} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].ADDRESS1`}
                                label="Address"
                                className={classes.textField}
                                component={renderTextField}
                                type="text"
                                validate={[required]} />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={5} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].CITY_STATEPROVINCE`}
                                label="City"
                                className={classes.textField}
                                component={renderTextField}
                                type="text"
                                validate={[required]} />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={1} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].STATE`}
                                label="US State"
                                className={classes.textField}
                                component={renderTextField}
                                type="text" />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={1} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].POSTALCODE`}
                                label="Postal Code"
                                className={classes.textField}
                                component={renderTextField}
                                type="text" />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={3} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].PHONE`}
                                label="Phone"
                                className={classes.textField}
                                component={renderTextField}
                                type="text"
                                validate={[required]} />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={3} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].FAX`}
                                label="Fax"
                                className={classes.textField}
                                component={renderTextField}
                                type="text" />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={3} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].EMAIL`}
                                label="Email"
                                className={classes.textField}
                                component={renderTextField}
                                type="text"
                                validate={[required, email]} />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={3} style={{ margin: "10px 0px 0px" }} >
                            <Field
                                name={`contactData[${index}].WEBSITE`}
                                label="Website"
                                className={classes.textField}
                                component={renderTextField}
                                type="text" />
                        </Grid>
                        <Grid container alignContent="flex-start" item xs={6} style={{ margin: "20px 0px 0px" }}>
                            <Field
                                name={`isMain`}
                                value={countryName}
                                checked={index === indexMainData}
                                className={classes.textField}
                                style={{ width: 20 }}
                                component={'input'}
                                onChange={(event) => this.props.onChangeMainSelection(event)}
                                type="radio"
                            />
                            <span>Main contact information</span>

                        </Grid>


                    </Grid>
                </AccordionDetails>
            </Accordion >
        })
    }

    renderAddUserButton() {
        return (
            <div style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                    variant="contained"
                    color="default"
                    size="small"
                    onClick={e => {
                        this.openAddUser()
                    }}>
                    <Icon>add</Icon>
                    Add User
                </Button>
            </div>
        );
    }

    openAddUser = () => {
        this.setState({ addUserOpen: true })
    }
    closeAddUser = (createdUser) => {
        if (createdUser) {
            this.props.updateUserOptions()
            this.props.change('COGNITO_ID', createdUser)
        }
        this.setState({ addUserOpen: false })
    }

    renderAddUser() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                onClose={() => this.setState({ addUserOpen: false })}
                open={this.state.addUserOpen}
            >
                <UsersForm isModal={true} closeModal={this.closeAddUser}></UsersForm>
            </Dialog>
        );
    }

    render() {
        const {
            handleOnSubmit,
            productsList,
            classes,
            title,
            optionsUser,
            optionsCountry,
            goBack,
            handleSubmit,
            submitting,
            countriesNameField,
            hasMultiCountriesWithDifferentsAddresses,
            indexMainData,
            valid
        } = this.props;
        orderBy(optionsCountry, "DISPLAY")
        return (
            <div style={{ padding: '20px' }}>
                <Paper className={classes.boxCard}>
                    <Grid container className={classes.selectContainer} >
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                    </Grid >
                    {this.state.addUserOpen && this.renderAddUser()}
                    <Form onSubmit={handleSubmit(handleOnSubmit)} >
                        <Grid container>
                            <Grid container alignContent="flex-start" item xs={12} sm={4}>
                                <Typography gutterBottom variant="h5" component="h2" className={classes.margintop}>
                                    Information
                                </Typography>
                                <Field
                                    name="COGNITO_ID"
                                    label="User"
                                    component={renderSelectField}
                                    validate={[required]}
                                    // selectProps={{ native: true }}
                                    className={classes.textField}
                                >
                                    <MenuItem key={'userNone'} value='userNone'>None</MenuItem>
                                    {optionsUser.map((option, index) => {
                                        const sub = finder(option.Attributes, "sub")
                                        const name = finder(option.Attributes, "name")

                                        return (
                                            <MenuItem key={index} value={sub}>
                                                {name}
                                            </MenuItem>)
                                    })}
                                </Field>
                                {this.renderAddUserButton()}
                                <Field
                                    name="COMPANY"
                                    label="Company"
                                    component={renderTextField}
                                    type="text"
                                    className={classes.textField}
                                    validate={[required]}
                                />
                                <Field
                                    name="MANAGERCODE"
                                    label="Surado Manager Code"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text"
                                    validate={[required]} />
                                <Field
                                    name={`contactData[${indexMainData}].SALUTATION`}
                                    label="Salutation"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text" />
                                <Field
                                    name={`contactData[${indexMainData}].FIRSTNAME`}
                                    label="First Name"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text"
                                    validate={[required]} />
                                <Field
                                    name={`contactData[${indexMainData}].LASTNAME`}
                                    label="Last Name"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text"
                                    validate={[required]} />
                                <Field
                                    name={`contactData[${indexMainData}].JOBTITLE`}
                                    label="Job Title"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text" />
                                <div className={classes.radioButtonContainer}>
                                    <FormLabel component="legend">Role</FormLabel>
                                    <Field name="ROLE" className={classes.textField} component={renderRadioButtonGroup}>
                                        <FormControlLabel value="Contracts" control={<Radio color="primary" />} label="Contracts" />
                                        <FormControlLabel value="Billing" control={<Radio color="primary" />} label="Billing" />
                                    </Field>
                                </div>
                            </Grid>
                            <Grid container alignContent="flex-start" item xs={12} sm={4}>
                                <Typography gutterBottom variant="h5" component="h2" className={classes.margintop}>
                                    Address
                                </Typography>
                                <Field
                                    name={`contactData[${indexMainData}].ADDRESS1`}
                                    label="Address"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text"
                                    validate={[required]} />
                                <Field
                                    name={`contactData[${indexMainData}].CITY_STATEPROVINCE`}
                                    label="City"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text"
                                    validate={[required]} />
                                <Field
                                    name={`contactData[${indexMainData}].STATE`}
                                    label="US State"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text" />
                                <Field
                                    name={`contactData[${indexMainData}].POSTALCODE`}
                                    label="Postal Code"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text" />
                                <Field
                                    name="CountriesName"
                                    label="Country"
                                    component={renderSelectMultipleField}
                                    validate={[required]}
                                    className={classes.textField}
                                    properties={{ id: 'countries' }}
                                    onChange={(event, newValue, previousValue) => {
                                        let newExpanded = [...this.state.expanded]
                                        if (newValue.length > previousValue.length) {
                                            newExpanded.push(true)
                                        }
                                        else {
                                            newExpanded = newExpanded.map(() => true)
                                        }
                                        this.setState({ expanded: newExpanded })
                                        this.props.onChangeCountries(newValue, previousValue)
                                    }
                                    }
                                >
                                    {optionsCountry.map((option, index) => {
                                        return <MenuItem key={index} value={option.DISPLAY}>
                                            {option.DISPLAY}
                                        </MenuItem>
                                    })}
                                </Field>
                                <div className={classes.radioButtonContainer}>
                                    <FormLabel component="legend">Location Type</FormLabel>
                                    <Field name="LOCATIONTYPE" component={renderRadioButtonGroup}
                                        className={classes.textField}>
                                        <FormControlLabel value="Commercial" control={<Radio color="primary" />} label="Commercial" />
                                        <FormControlLabel value="Residential" control={<Radio color="primary" />} label="Residential" />
                                    </Field>
                                </div>
                            </Grid>
                            <Grid container alignContent="flex-start" item xs={12} sm={4}>
                                <Typography gutterBottom variant="h5" component="h2" className={classes.margintop}>
                                    Contact
                                </Typography>
                                <Field
                                    name={`contactData[${indexMainData}].PHONE`}
                                    label="Phone"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text"
                                    validate={[required]} />
                                <Field
                                    name={`contactData[${indexMainData}].FAX`}
                                    label="Fax"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text" />
                                <Field
                                    name={`contactData[${indexMainData}].EMAIL`}
                                    label="Email"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text"
                                    validate={[required, email]} />
                                <Field
                                    name={`contactData[${indexMainData}].WEBSITE`}
                                    label="Website"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text" />
                                <Field
                                    name="FEDEXNUMBER"
                                    label="FedEx Number"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text" />
                                <Field
                                    name="ProductListName"
                                    label="Products"
                                    component={renderSelectMultipleField}
                                    validate={[required]}
                                    className={classes.textField}
                                    properties={{ id: 'productsType' }}
                                >
                                    {!!productsList && productsList.map((option, index) => {
                                        return <MenuItem key={index} value={option.LABEL}>
                                            {option.LABEL}
                                        </MenuItem>
                                    })}
                                </Field>
                            </Grid>
                            {!!countriesNameField && countriesNameField.length > 1 && <FormControlLabel
                                control={
                                    <Field
                                        name="hasMultiCountriesWithDifferentsAddresses"
                                        component={renderCheckbox}
                                        type="checkbox"
                                    />
                                }
                                label="Specify contact information for each country"
                            />}
                            {hasMultiCountriesWithDifferentsAddresses && this.renderAccordionsForEachCountry(countriesNameField)}
                            <Grid container >
                                <Grid container item xs={12} justifyContent="center" style={{ marginTop: 30 }}>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="large"
                                        className={classes.button}
                                        onClick={goBack}
                                        disabled={submitting}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.button}
                                        type="submit"
                                        disabled={submitting}
                                        onClick={() => {
                                            if (!valid)
                                                this.setState({ expanded: this.state.expanded.map(() => true) })
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </Paper>
            </div>
        )
    }
}

const selector = formValueSelector('dealers')
Dealers = connect(
    state => {
        const countriesNameField = selector(state, 'CountriesName')
        const hasMultiCountriesWithDifferentsAddresses = selector(state, 'hasMultiCountriesWithDifferentsAddresses')

        return {
            countriesNameField,
            hasMultiCountriesWithDifferentsAddresses,
        }
    }
)(Dealers)

export default withStyles(styles)(reduxForm({ form: 'dealers' })(Dealers))
