import { GET_DEALERS, DELETE_DEALER, ADD_DEALERS, UPDATE_DEALERS, RESET } from '../actions'
import { getColumnsOfTable, deleteItemFromArray } from '../services'

export default function DealersReducer(state = { fields: null, dealers: null }, action) {
  switch (action.type) {
    case GET_DEALERS:
      const columnsName = getColumnsOfTable(action.payload.data[0])
      let delearDatasWithCountriesIdConvertedToArray = action.payload.data
      delearDatasWithCountriesIdConvertedToArray.sort((a, b) => a.COMPANY.localeCompare(b.COMPANY))
      return {
        ...state,
        dealers: delearDatasWithCountriesIdConvertedToArray,
        fields: columnsName
      }
    case DELETE_DEALER:
      return { ...state, dealers: deleteItemFromArray(state.dealers, action.payload, 'DealerID') }
    case ADD_DEALERS:
      return {
        ...state,
        dealers: null,
        fields: null
      }
    case UPDATE_DEALERS:
      return {
        ...state,
        dealers: null,
        fields: null
      }
    case RESET:
      return {
        ...state,
        dealers: null,
        fields: null
      }
    default:
      return state
  }
}