import React, { Component } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { IconButton, Icon, Switch } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import Generic from '../../components/Generic'
import ConfirmationModal from '../../components/ConfirmationModal'
import { getProductsFromApi, deleteProduct, enableProduct } from '../../actions'
import Spinner from '../../components/Spinner'
import Flag_US from '../../resources/Images/Flag_US.svg'
import Flag_UK from '../../resources/Images/Flag_UK.svg'
import Flag_AU from '../../resources/Images/Flag_AU.svg'
import Globe from '../../resources/Images/globe.svg'

class Products extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            search: '',
            isActive: false,
            configPage: {
                title: 'Products',
                buttonDownload: true,
                downloadName: 'Products',
                buttonAddNew: true,
                buttonImportCSV: true,
            },
            openModal: false,
            deleteRow: null
        }
    }

    componentDidMount() {
        if (!this.props.ProductsReducer.fields) {
            this.props.getProductsFromApi(this.props.userToken.idToken.jwtToken)
                .catch(() => {
                    this.props.getProductsFromApi(this.props.userToken.idToken.jwtToken)
                        .catch(() => this.setState({ hasError: true }))
                })
        } else {
            this.makeColumns(this.props.ProductsReducer.fields)
            this.makeData(this.props.ProductsReducer.products)
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!nextProps.ProductsReducer.fields) {
            this.makeColumns(nextProps.ProductsReducer.fields)
            this.makeData(nextProps.ProductsReducer.products)
        }
    }

    editItem(id) {
        this.props.history.push(`${this.state.configPage.title.toLowerCase()}/form/${id}`)
    }

    deleteItem = (row) => {
        this.setState({ openModal: false })
        this.props.deleteProduct(this.props.userToken.idToken.jwtToken, row.ID)
    }

    enableItem(row) {
        this.props.enableProduct(this.props.userToken.idToken.jwtToken, row.ID, row.ENABLED ? 0 : 1)
    }

    handleChange = name => event => {
        this.setState({ isActive: event.target.checked })
    }

    makeColumns(products) {
        let arrayOfItems = []
        products.forEach((item) => {
            switch (item) {
                case 'TITLE': arrayOfItems.push({ Header: 'Title', accessor: item, id: item, minWidth: 400 }); break
                case 'PARTNUMBER': arrayOfItems.push({ Header: 'P/N', accessor: item, id: item, minWidth: 100, style: { textAlign: "center" } }); break
                case 'productType': arrayOfItems.push({ Header: 'Product Type', accessor: item, id: item, minWidth: 200 }); break
                case 'PRICE':
                    arrayOfItems.push({
                        Header: 'Price', id: item, accessor: d =>
                            <div> <NumberFormat value={d.PRICE} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></div>
                    }); break
                case 'OPTIONS': arrayOfItems.push({ Header: 'Required', accessor: item, id: item, minWidth: 150 }); break
                default: break
            }
        })
        arrayOfItems.push(
            {
                minWidth: 180,
                Header: 'Available',
                id: "AvailableOn",
                Cell: row => {
                    return (
                        <div>
                            {row.USAVAILABLE ? <img src={Flag_US} style={{ width: '30px', padding: '0 3px' }} alt="Logo US" /> : null}
                            {row.UKAVAILABLE ? <img src={Flag_UK} style={{ width: '30px', padding: '0 3px' }} alt="Logo UK" /> : null}
                            {row.AUAVAILABLE ? <img src={Flag_AU} style={{ width: '30px', padding: '0 3px' }} alt="Logo AU" /> : null}
                            {row.ELSEAVAILABLE ? <img src={Globe} style={{ width: '20px', padding: '0 3px' }} alt="Logo Global" /> : null}
                        </div >
                    )
                },
                style: { textAlign: "center" }
            },
            {
                minWidth: 220,
                Header: 'Actions',
                id: "ENABLED",
                style: { textAlign: "center" },
                Cell: row => {
                    return (<div>
                        <IconButton onClick={() => {
                            this.editItem(row.ID)
                        }}>
                            <Icon>edit</Icon>
                        </IconButton>
                        <Switch
                            checked={row.ENABLED === 1}
                            onClick={() => { this.enableItem(row) }}
                        />
                        <IconButton onClick={() => {
                            this.handleClickOpenModal(row)
                        }}>
                            <Icon color="error">close</Icon>
                        </IconButton>
                    </div>
                    )
                }
            })
        this.setState({ columns: arrayOfItems })
    }

    makeData(dataOfProducts) {
        this.setState({ content: dataOfProducts })
    }

    onInputValueChange = ({ target }) => {
        this.setState({ search: target.value.toLowerCase() })
    }

    handleClickOpenModal = (row) => {
        this.setState({ openModal: true, deleteRow: row })
    }

    handleCloseModal = () => {
        this.setState({ openModal: false })
    }

    render() {
        if (!!this.props.ProductsReducer.fields) {
            let data = this.props.ProductsReducer.products
            if (this.state.search) {
                data = data.filter(row => {
                    const array = Object.keys(row)
                    let isIncluded = false
                    array.forEach((columnName) => {
                        if (!isIncluded) {
                            if (typeof row[columnName] === 'string') {
                                if ((row[columnName].toLowerCase()).includes(this.state.search.toLowerCase())) {
                                    isIncluded = true
                                }
                            }
                            else if ((String(row[columnName]).toLowerCase()).includes(this.state.search.toLowerCase())) {
                                isIncluded = true
                            }
                        }
                    })
                    if (isIncluded)
                        return true
                    return false
                })
            }
            return (
                <div>
                    <ConfirmationModal title={'Product'} deleteRow={this.state.deleteRow} handleCloseModal={this.handleCloseModal} openModal={this.state.openModal} deleteItem={this.deleteItem} />
                    <Generic
                        data={data}
                        columns={this.state.columns}
                        onInputValueChange={this.onInputValueChange}
                        hasError={this.state.hasError}
                        configPage={this.state.configPage}
                        history={this.props.history} />
                </div>
            )
        } else if (this.state.hasError)
            return <Generic hasError={this.state.hasError} />
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        ProductsReducer: state.ProductsReducer
    }
}

export default withRouter(connect(mapStateToProps, { getProductsFromApi, deleteProduct, enableProduct })(Products))