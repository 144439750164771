import React, { Component } from 'react';
import { reduxForm, Form } from 'redux-form'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import { Select } from '@material-ui/core'


import { Button, Container, Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, withStyles, IconButton, Icon, MenuItem } from '@material-ui/core'
import SearchBar from '../ToolBars/SearchBar';
import { showNotification } from '../../services/notification'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    selectProduc: {
        marginBottom: '20px'
    },
    gridList: {
        marginBottom: '20px !important'
    },
    gridListTileBar: {
        backgroundColor: '#000000a6'
    },
    icon: {
        color: 'green',
    }, tableCell: {
        align: 'right',
        padding: '1px'
    }
});

class AddProductView extends Component {

    buildBox = (item, index) => {
        const { classes, handlerOnClickAddProduct } = this.props;
        return (
            <TableRow>
                <TableCell className={classes.tableCell}>{item.TITLE}</TableCell>
                <TableCell className={classes.tableCell}><NumberFormat value={item.PRICE} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></TableCell>
                <TableCell className={classes.tableCell}>{item.OPTIONS}</TableCell>
                <TableCell className={classes.tableCell}><IconButton onClick={() => handlerOnClickAddProduct(item)}>
                    <Icon className={classes.icon}>add</Icon>
                </IconButton></TableCell>
            </TableRow>
        )
    }

    onClickSubmit = () => {
        const { handleOnSubmit, isCartEmpty, hasMissingKey } = this.props;
        if (!isCartEmpty()) {
            if (!hasMissingKey()) {
                handleOnSubmit()
            } else {
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: 'One or more products have missing keys',
                    autoClose: true
                }
                showNotification(config);
            }
        }
        else {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'The cart is empty',
                autoClose: true
            }
            showNotification(config);
        }
    }

    render() {
        const { listOfProducts, onSelectKeysChange, classes, typeSelected, optionsType, onInputValueChange, handleSubmit, Beforepage } = this.props;
        return (
            <Container>
                <Form onSubmit={handleSubmit(this.onClickSubmit)} >
                    <Select
                        onChange={onSelectKeysChange}
                        className="select"
                        value={typeSelected}
                        style={{ margin: "25px 0px" }}
                        name="productType">
                        <MenuItem value=''></MenuItem>
                        {optionsType.map((option, index) => (
                            <MenuItem key={"option" + index} value={option.LABEL}>
                                {option.LABEL}
                            </MenuItem>
                        ))}
                    </Select>
                    {typeSelected !== '' && <Paper style={{ overflow: "hidden", width: '100%', }}>
                        <Grid container justify="flex-end" style={{ background: '#fafafa', padding: '5px 0px 0px 0px' }}>
                            <Grid item xs={6} sm={3} className={classes.buscador}>
                                <SearchBar onInputValueChange={onInputValueChange} />
                            </Grid>
                        </Grid>
                        <div style={{
                            maxHeight: 440,
                            overflow: 'auto',
                            margin: '10px'
                        }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell}>TITLE</TableCell>
                                        <TableCell className={classes.tableCell}>PRICE</TableCell>
                                        <TableCell className={classes.tableCell} >OPTIONS</TableCell>
                                        <TableCell className={classes.tableCell}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listOfProducts && listOfProducts.map((item, index) =>
                                        (item.productType === typeSelected ? this.buildBox(item, index) : null))}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>}
                    {this.props.children}
                    <div>
                        <center style={{ marginTop: 10 }}>
                            <Button type="button" onClick={Beforepage}>
                                Previous
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                Next
                            </Button>
                        </center>
                    </div>
                </Form>
            </Container>
        );
    }
}

AddProductView.propTypes = {
    listOfProducts: PropTypes.array,
    onClickToAddProduct: PropTypes.func,
}
export default withStyles(styles)(reduxForm({
    form: 'pagination',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(AddProductView))
