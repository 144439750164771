const awsConfig = {
  region: 'us-east-1',
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_AKpbutJqD',//

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '3e39u5beivcos87g65c43jm8ng',//

    // OPTIONAL - USER MIGRATION
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }
}

export default awsConfig
