import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Popover, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Paper } from '@material-ui/core';

const styles = theme => ({
    title: {
        marginTop: 0,
        marginBottom: 0,
        borderBottom: "1px solid"
    },
    subTitle: {
        marginBottom: 0,
        borderBottom: "1px solid"
    },
    table: {
        minWidth: 340,
    },
    tableCell: {
        paddingRight: 4,
        paddingLeft: 5
    }
});

class ShowOrder extends Component {
    renderTable = (addtionalDatas, isSerialKeyExist) => {
        return (
            <Paper>
                <Table className={this.props.classes.table}>
                    <TableHead>
                        <TableRow>
                            {!!addtionalDatas[0].QUANTITY && <TableCell className={this.props.classes.tableCell}>Quantity</TableCell>}
                            {!!isSerialKeyExist && <TableCell className={this.props.classes.tableCell}>Rhino Serial Key</TableCell>}
                            <TableCell className={this.props.classes.tableCell}>Part Number</TableCell>
                            <TableCell className={this.props.classes.tableCell}>Product</TableCell>
                            <TableCell className={this.props.classes.tableCell}>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {addtionalDatas.map((item, index) => (
                            <TableRow key={index}>
                                {!!item.QUANTITY && <TableCell className={this.props.classes.tableCell}>{item.QUANTITY}</TableCell>}
                                {!!isSerialKeyExist && <TableCell className={this.props.classes.tableCell}>{item.SERIALKEY}</TableCell>}
                                <TableCell className={this.props.classes.tableCell}>{item.PARTNUMBER}</TableCell>
                                <TableCell className={this.props.classes.tableCell}>{item.TITLE}</TableCell>
                                <TableCell className={this.props.classes.tableCell}>{item.PRICE}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        )
    }

    checkIfRhinoSerialKeyExist = (addtionalDatas) => {
        return addtionalDatas.find(item => item.SERIALKEY !== null)
    }

    render() {
        const { open, onClose, anchorEl, classes, data, addtionalDatas } = this.props;
        let isSerialKeyExist = null
        if (!!addtionalDatas)
            isSerialKeyExist = this.checkIfRhinoSerialKeyExist(addtionalDatas);
        return (
            <div>
                {!data['PRODUCTS'] && addtionalDatas.length === 0
                    ? <CircularProgress />
                    : addtionalDatas[0] !== -1 && addtionalDatas.length !== 0
                        ? <Popover
                            id="simple-popper"
                            open={open}
                            onClose={onClose}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                        >
                            <div className={classes.content} style={{ margin: '15px' }}>
                                {this.renderTable(addtionalDatas, isSerialKeyExist)}
                                {data.NOTES !== "" &&
                                    <div>
                                        <h4 className={classes.subTitle}>Notes</h4>
                                        <div>{data.NOTES}</div>
                                    </div>}
                            </div>
                        </Popover>
                        : < Popover
                            id="simple-popper"
                            open={open}
                            onClose={onClose}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                        >
                            {(!!data.PRODUCTS || !!data.NOTES) ?
                                <div className={classes.content} style={{ margin: '15px' }}>
                                    {data.PRODUCTS && data.PRODUCTS !== "" &&
                                        <div>
                                            <h4 className={classes.title}>Products</h4>
                                            <div dangerouslySetInnerHTML={{ __html: data.PRODUCTS }} />
                                        </div>}
                                    {data.NOTES !== "" &&
                                        <div>
                                            <h4 className={classes.subTitle}>Notes</h4>
                                            <div>{data.NOTES}</div>
                                        </div>}
                                </div>
                                :
                                <div className={classes.content} style={{ margin: '15px' }}>
                                    <div>No info.</div>
                                </div>}
                        </ Popover>}
            </div>
        );
    }
}

export default withStyles(styles)(ShowOrder);