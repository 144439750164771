export const SET_VIEWMODE = "SET_VIEWMODE";
export const RESET = "RESET";

export const setViewMode = (viewMode) => {
    return function (dispatch) {
        const action = {
            type: SET_VIEWMODE,
            payload: viewMode
        }
        const actionReset = { type: RESET }
        dispatch(action)
        dispatch(actionReset)
    }
}
