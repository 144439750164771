import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, IconButton } from "@material-ui/core";
import NumberFormat from 'react-number-format'

import { saveImageForProduct, getProductType } from '../../../actions'
import AddProductView from '../../../components/PlaceNewOrder/AddProductView'
import MyCart from '../../../components/PlaceNewOrder/MyCart';

class AddProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            productSelected: [],
            columns: [],
            typeSelected: '',
            optionsType: [{}],
            listOfProducts: [],
            RowID: 0
        };
        this.renderEditable = this.renderEditable.bind(this);
        this.rhinoKey = this.rhinoKey.bind(this);


        //Initialized table
        this.makeColumns(null)
        if (this.props.productsList.length === 0) {
            this.props.getProductType(this.props.userToken.idToken.jwtToken)
        }
    }

    componentDidMount = () => {
        if (!!this.props.productsSelected) {
            this.setState({ productSelected: this.props.productsSelected })
            this.props.productsSelected.forEach(item => {
                this.makeColumns(item)
            })
        }
    }

    componentWillUnmount = () => {
        const productsWithKey = this.state.productSelected.filter((item) => item.QUANTITY !== 0 ? item : null)
        this.props.actionToSaveProducts(productsWithKey)
    }

    handlerOnClickAddProduct = (item) => {
        let exist = false;
        const productSelected = this.state.productSelected;
        productSelected.forEach((element, index) => {
            if (element.TITLE === item.TITLE && !exist && (this.props.hiddenForm || element.OPTIONS === 'none')) {
                productSelected[index].QUANTITY += 1;
                this.setState({ productSelected })
                exist = true;
            }
        });
        if (exist === false) {
            let addingProperty = Object.assign({}, item)
            addingProperty.QUANTITY = 1
            addingProperty.ROWID = this.state.RowID;
            this.setState(prevState => ({
                productSelected: [...prevState.productSelected, addingProperty]
            }))
            this.setState(prevState => {
                return { RowID: (Number(prevState.RowID) + 1) }
            })
        }
        this.makeColumns(item)
    }

    handlerOnClickRemoveProduct = (row) => {
        this.setState({
            productSelected: this.state.productSelected.filter((key) => {
                return key.ROWID !== row.ROWID
            })
        })
    }

    onSelectKeysChange = (event) => {
        this.setState({ typeSelected: event.target.value })
    }

    renderEditable(cellInfo, options) {
        if (!this.props.hiddenForm && cellInfo.OPTIONS !== 'none') {
            return (<div>1</div>)
        } else {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        cellInfo[options.accessor] = Number(e.target.innerHTML);
                    }}
                >{cellInfo[options.accessor]}</div>
            );
        }
    }

    onInputValueChange = ({ target }) => {
        this.setState({ search: target.value })
    }

    rhinoKey(cellInfo, options) {
        if (cellInfo.OPTIONS === 'rhinoProof') {
            return (
                <div
                    style={{ backgroundColor: "#fafafa", borderColor: '#000000', borderStyle: 'solid', borderWidth: '1px', borderRadius: '4px' }}
                    contentEditable
                    id={cellInfo.index}
                    suppressContentEditableWarning
                    onBlur={e => {
                        cellInfo[options.accessor] = e.target.innerHTML;
                    }}
                >
                    {cellInfo[options.accessor]}
                </div>
            )
        } else {
            return (<div>Not Apply</div>)
        }
    }

    makeColumns = (product) => {
        let arrayOfItems = []
        arrayOfItems.push(
            {
                Header: 'Product Name',
                accessor: 'TITLE'
            }
        )
        if (!this.props.hiddenForm) {
            arrayOfItems.push(
                {
                    Header: 'Key Rhino',
                    accessor: 'keyRhino',
                    Cell: this.rhinoKey
                })
        }
        arrayOfItems.push(
            {
                Header: 'Part Number',
                accessor: 'PARTNUMBER',
            },
            {
                Header: 'Price Each',
                id: 'price',
                accessor: d =>
                    <div  ><NumberFormat value={d.PRICE} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></div>
            },
            {
                Header: 'Quantity',
                accessor: "QUANTITY",
                Cell: this.renderEditable
            },
            {
                Header: 'Total Price',
                id: "total",
                accessor: d =>
                    <div  ><NumberFormat value={d.PRICE * d.QUANTITY} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></div>
            },
            {
                Header: 'Delete product',
                accessor: 'deleteProduct',
                Cell: row => {
                    return <IconButton onClick={() => this.handlerOnClickRemoveProduct(row)}><Icon color="error">close</Icon></IconButton>
                },
                style: {
                    textAlign: "center"
                }
            });
        this.setState({ columns: arrayOfItems })
    }

    onClickGoBack = () => {
        this.setState({ productSelected: [] }, function () {
            this.props.previousPage()
        });
    }

    isCartEmpty = () => {
        return this.state.productSelected.length === 0
    }

    hasMissingKey = () => {
        let found = this.state.productSelected.find(row => {
            if (row.OPTIONS === 'rhinoProof') {
                return !row.keyRhino || row.keyRhino === ''
            }
            else {
                return false
            }
        })
        return !!found
    }

    render() {
        if (!!Object.keys(this.props.ProductsReducer).length) {
            let listOfProducts = this.props.ProductsReducer.products
            if (this.props.selectStock) {
                listOfProducts = listOfProducts.filter(row => row.AVAILABLEFORSTOCK)
            }
            if (this.state.search) {
                listOfProducts = listOfProducts.filter(row => {
                    const array = Object.keys(row)
                    let isIncluded = false
                    array.forEach((columnName) => {
                        if (!isIncluded) {
                            if (typeof row[columnName] === 'string') {
                                if ((row[columnName].toLowerCase()).includes(this.state.search.toLowerCase())) {
                                    isIncluded = true
                                }
                            }
                            else if ((String(row[columnName]).toLowerCase()).includes(this.state.search.toLowerCase())) {
                                isIncluded = true
                            }
                        }
                    })
                    if (isIncluded)
                        return true
                    return false;
                })
            }

            return (
                <AddProductView
                    listOfProducts={listOfProducts}
                    handlerOnClickAddProduct={this.handlerOnClickAddProduct}
                    onSelectKeysChange={this.onSelectKeysChange}
                    typeSelected={this.state.typeSelected}
                    optionsType={this.props.productsList}
                    columns={this.state.columns}
                    onInputValueChange={this.onInputValueChange}
                    handleOnSubmit={this.props.onSubmit}
                    Beforepage={this.onClickGoBack}
                    isCartEmpty={this.isCartEmpty}
                    hasMissingKey={this.hasMissingKey}
                >
                    <MyCart columns={this.state.columns} productSelected={this.state.productSelected} />
                </AddProductView>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        ProductsReducer: state.ProductsReducer,
        userToken: state.Auth.signedInUser.signInUserSession,
        productsList: state.ProductTypeReducer.productsList
    }
}

const mapDispatchToProps = {
    getProductType,
    saveImageForProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
