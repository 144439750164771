import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, IconButton, Select, MenuItem } from "@material-ui/core";
import NumberFormat from 'react-number-format'
import { withRouter } from 'react-router-dom';
import { showNotification } from '../../../services/notification'

import SelectProductView from '../../../components/ReportSale/SelectProductView'
import TableComponent from '../../../components/TableComponent'

class SelectProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            valueOfSelectProduct: '',
            valueOfQuantity: '',
            quantityOptions: [],
            productsToAddKeys: [],
            keySelected: null,
            RowID: 0,
            keys: {},
            columns: [],
            currentProducts: [...props.products],
        }

    }


    componentDidMount = () => {

        //Initialized table
        this.makeColumns(null)
        const { keys } = this.state;
        this.props.KeysReducer.forEach((item) => {
            if (!(`${item.PRODUCTID}` in keys))
                keys[`${item.PRODUCTID}`] = {}
            keys[`${item.PRODUCTID}`][(item.KEY)] = { isAvaileble: true };
        })
        this.setState({ keys });

        if (!!this.props.productsSelected) {
            let keys = { ...this.state.keys }
            this.props.productsSelected.forEach(item => {
                this.createNewArrayOfProductsWithId(item)
                this.makeColumns(item)
                keys[item.PRODUCTID][item.key] = { isAvaileble: false }
            })
            this.setState({ productsToAddKeys: this.props.productsSelected, keys: keys })

        }
    }

    componentWillUnmount = () => {
        const productsWithKey = this.state.productsToAddKeys.filter((item) => !!item.key ? item : null)
        //if (productsWithKey.length !== 0) {
        productsWithKey.forEach((item) => {
            const keyElemnt = this.props.KeysReducer.find(element => {
                return element.KEY === item.key
            })
            item["keyId"] = keyElemnt.ID

            const RHkeyElemnt = this.props.KeysReducer.find(element => {
                return element.KEY === item.RHkey
            })
            if (RHkeyElemnt) {
                item["RHkeyId"] = RHkeyElemnt.ID
            }
        })
        this.props.actionToSaveProductsSelectData(productsWithKey)
        //}
    }

    // ------------- Values of states ------------
    onSelectValueChange = (event) => {
        if (event.target.value === '')
            this.setState({ valueOfQuantity: 0 })

        const selectedKey = event.target.value

        let remainingKeys = selectedKey.QUANTITY
        this.state.productsToAddKeys.forEach(product => {
            if (selectedKey.PRODUCTID === product.PRODUCTID)
                remainingKeys--
        })

        let options = [];
        for (let i = 1; i <= remainingKeys; i++) {
            options.push(i)
        }
        this.setState({
            valueOfSelectProduct: event.target.value,
            quantityOptions: options
        })
    }

    onInputValueChange = (event) => {
        this.setState({ valueOfQuantity: event.target.value })
    }

    makeColumns = (valueOfSelectProduct) => {
        let arrayOfItems = []
        arrayOfItems.push(
            {
                Header: 'Product',
                accessor: 'TITLE'
            },
            {
                Header: 'P/N',
                accessor: 'PARTNUMBER'
            },
            {
                Header: 'Add to Sale',
                accessor: 'key',
                Cell: (row) => {
                    return this.buildSelect(row)
                }, style: {
                    textAlign: "center"
                }
            },
            {
                Header: 'Key Rhino',
                accessor: 'RHkey',
                Cell: (row) => {
                    return this.rhinoKey(row)
                }, style: {
                    textAlign: "center"
                }
            },
            {
                Header: 'Price',
                id: 'PRICE',
                accessor: d =>
                    <div  ><NumberFormat value={d.PRICE} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></div>
            },
            {
                Header: 'Delete product',
                accessor: 'deleteProduct',
                Cell: (row) => {
                    return <IconButton onClick={() => this.handlerOnClickRemoveProduct(row)}><Icon color="error" >close</Icon></IconButton>
                },
                style: {
                    textAlign: "center"
                }
            });
        this.setState({ columns: arrayOfItems })
    }

    buildSelect(row) {
        return (
            <Select
                native
                value={row.key}
                onChange={(event) => { this.onSelectKeysChange(event, row) }}
                required={true}
                inputProps={{
                    name: `${row.id}${row.PRODUCTID}`,
                    id: `${row.id}${row.PRODUCTID}-age-native-simple`,
                }}
            >
                <MenuItem />
                {this.state.keys[`${row.PRODUCTID}`]
                    && this.loadKeys(row, row.key)}
            </Select>
        )
    }

    loadKeys = (row, selectedKey) => {
        let arrayKeys = []
        if (selectedKey) {
            arrayKeys.push(<MenuItem key={selectedKey} value={selectedKey}>
                {selectedKey}
            </MenuItem>)
        }
        for (let option in this.state.keys[`${row.PRODUCTID}`]) {
            if (this.state.keys[`${row.PRODUCTID}`][option].isAvaileble === true)
                arrayKeys.push(<MenuItem key={option} value={option}>
                    {option}
                </MenuItem>)
        }
        return arrayKeys
    }

    loadRhinoKeys = (selectedKey) => {
        let arrayKeys = []
        if (selectedKey) {
            arrayKeys.push(<MenuItem key={selectedKey} value={selectedKey}>
                {selectedKey}
            </MenuItem>)
        }
        for (let productIndex in this.props.KeysReducer) {
            let productKey = this.props.KeysReducer[productIndex]
            if (productKey.Type === 'Rhino' && this.state.keys[productKey.PRODUCTID][productKey.KEY].isAvaileble) {
                arrayKeys.push(<MenuItem key={productKey.KEY} value={productKey.KEY}>
                    {productKey.KEY}
                </MenuItem>)
            }
        }
        return arrayKeys;
    }

    rhinoKey(row) {
        if (row.OPTIONS === 'rhinoProof') {
            return (
                <div
                    style={{ backgroundColor: "#fafafa", borderColor: '#000000', borderStyle: 'solid', borderWidth: '1px', borderRadius: '4px' }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        const productsToAddKeys = [...this.state.productsToAddKeys];
                        productsToAddKeys[row.id - 1]['RHkey'] = e.target.innerHTML;
                        this.setState({ productsToAddKeys });
                    }}
                >
                    {this.state.productsToAddKeys[row.id - 1]['RHkey']}
                </div>
            )
        } else if (row.OPTIONS === 'soldWithRhino') {
            return (
                <Select
                    native
                    value={row.RHkey}
                    onChange={(event) => { this.onSelectRHKeysChange(event, row) }}
                    required={true}
                    inputProps={{
                        name: `${row.id}${row.PRODUCTID}rhino`,
                        id: `${row.id}${row.PRODUCTID}rhino-age-native-simple`,
                    }}>
                    <MenuItem />
                    {this.loadRhinoKeys(row.RHkey)}
                </Select>
            )
        }
        else {
            return (<div>Not Apply</div>)
        }
    }

    // ---------- Methods ----------

    parseProductIdFromKey = (stringKey) => {
        for (let productId in this.state.keys) {
            let key = this.state.keys[productId][stringKey];
            if (key)
                return productId;
        };
    }

    handlerOnClickAddProduct = (valueOfSelectProduct, valueOfQuantity) => {
        if (valueOfSelectProduct === '' || valueOfQuantity === '' || valueOfQuantity === 0) {
            let config = {
                icon: 'error',
                type: 'error',
                message: "You've to select a product and a quantity",
                autoClose: true
            }
            showNotification(config);
            return
        }
        const newArrayWithStateAndNewElements = this.createNewArrayOfProductsWithId(valueOfSelectProduct, valueOfQuantity)
        const selectableQuantity = this.state.quantityOptions.length;
        this.setState({
            productsToAddKeys: [...newArrayWithStateAndNewElements],
            valueOfSelectProduct: '',
            valueOfQuantity: '',
            quantityOptions: [],
        })
        this.makeColumns(valueOfSelectProduct)
        if (selectableQuantity === valueOfQuantity) {
            let newProducts = this.state.currentProducts.filter(product => product.PRODUCTID !== valueOfSelectProduct.PRODUCTID)
            this.setState({ currentProducts: newProducts })
        }
    }

    createNewArrayOfProductsWithId = (valueOfSelectProduct, valueOfQuantity = 1) => {
        let productArray = [];
        for (let i = 1; i <= valueOfQuantity; i++) {
            let addingProperty = Object.assign({}, valueOfSelectProduct)
            addingProperty.id = this.state.RowID + i
            productArray.push(addingProperty)
        }
        this.setState(prevState => {
            return { RowID: (Number(prevState.RowID) + Number(valueOfQuantity)) }
        })
        return [...this.state.productsToAddKeys, ...productArray];
    }

    onSelectKeysChange = (event, row) => {
        let keys = { ...this.state.keys }

        if (event.target.value === "") {
            keys[row.PRODUCTID][row.key].isAvaileble = true
            row.key = ''
        }
        else if (row.key !== event.target.value) {
            if (row.key) {
                keys[row.PRODUCTID][row.key].isAvaileble = true;
                delete row.key
            }

            keys[row.PRODUCTID][event.target.value].isAvaileble = !keys[row.PRODUCTID][event.target.value].isAvaileble
            row.key = event.target.value
        }
        this.setState({ keys, keySelected: event.target.value })
    }

    onSelectRHKeysChange = (event, row) => {
        let keys = { ...this.state.keys }
        let oldProductId;

        if (event.target.value === "") {
            if (row.RHkey) {
                oldProductId = this.parseProductIdFromKey(row.RHkey)
                keys[oldProductId][row.RHkey].isAvaileble = true
                row.RHkey = ''
            }
        }
        else if (row.RHkey !== event.target.value) {
            if (row.RHkey) {
                if (!oldProductId)
                    oldProductId = this.parseProductIdFromKey(row.RHkey)

                keys[oldProductId][row.RHkey].isAvaileble = true;
                delete row.RHkey
            }

            let productId = this.parseProductIdFromKey(event.target.value);
            keys[productId][event.target.value].isAvaileble = !keys[productId][event.target.value].isAvaileble;
            row.RHkey = event.target.value;
        }

        this.setState({ keys })
    }

    handlerOnClickRemoveProduct = (row) => {
        let keys = { ...this.state.keys }
        if (!!row.key) {
            keys[row.PRODUCTID][row.key].isAvaileble = true
            delete row.key;
        }

        if (!!row.RHkey) {
            let productId = this.parseProductIdFromKey(row.RHkey);
            keys[productId][row.RHkey].isAvaileble = true;
            delete row.RHkey;
        }


        this.setState({
            productsToAddKeys: this.state.productsToAddKeys.filter((key) => {
                return key.id !== row.id
            }),
            keys: keys
        })

        if (!this.state.currentProducts.find(element => element.PRODUCTID === row.PRODUCTID)) {
            delete row.id;
            this.setState({
                currentProducts: [...this.state.currentProducts, row]
            })
        }

    }

    onClickGoBack = () => {
        this.setState({ productsToAddKeys: [] }, function () {
            this.props.previousPage()
        });
    }

    isProductsToAddEmpty = () => {
        return this.state.productsToAddKeys.length === 0
    }

    hasMissingKey = () => {
        let found = this.state.productsToAddKeys.find(row => {
            if (row.OPTIONS === 'rhinoProof') {
                return !row.RHkey || row.RHkey === ''
            }
            else {
                return false
            }
        })
        return !!found
    }

    render() {
        return (
            <SelectProductView
                valueOfSelectProduct={this.state.valueOfSelectProduct}
                products={this.state.currentProducts}
                valueOfQuantity={this.state.valueOfQuantity}
                quantityOptions={this.state.quantityOptions}
                handlerOnClickAddProduct={this.handlerOnClickAddProduct}
                onInputValueChange={this.onInputValueChange}
                onSelectValueChange={this.onSelectValueChange}
                handleOnSubmit={this.props.onSubmit}
                Beforepage={this.onClickGoBack}
                isProductsToAddEmpty={this.isProductsToAddEmpty}
                hasMissingKey={this.hasMissingKey}
            >
                <TableComponent productSelected={this.state.productsToAddKeys} columns={this.state.columns} />
            </SelectProductView>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        KeysReducer: state.KeysReducer.keys,
    }
}

export default withRouter(connect(mapStateToProps)(SelectProduct));
