import { GET_CUSTOMERS, DELETE_CUSTOMER, UPDATE_CUSTOMER, ADD_CUSTOMER, RESET } from '../actions'
import { getColumnsOfTable, deleteItemFromArray } from '../services'

export default function CustomersReducer(state = {
    customers: null,
    fields: null
}, action) {
    switch (action.type) {
        case GET_CUSTOMERS:
            const columnsName = getColumnsOfTable(action.payload.data.results[0])
            let customers = action.payload.data.results
            customers.sort((a, b) => a.COMPANY.localeCompare(b.COMPANY))
            return {
                ...state,
                customers: action.payload.data.results,
                fields: columnsName
            }
        case ADD_CUSTOMER:
            return { ...state, customers: null, fields: null }
        case UPDATE_CUSTOMER:
            return { ...state, customers: null, fields: null }
        case DELETE_CUSTOMER:
            return { ...state, customers: deleteItemFromArray(state.customers, action.payload, 'ID') }
        case RESET:
            return { ...state, customers: null, fields: null }
        default:
            return state
    }
}