import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format'

import { getTopTenSales, getTopTenOrders, getDealersFromApi, updateFedexDealer, resetStateOfReportSale, resetStateOfPlaceOrder } from '../actions';
import HomeComponent from '../components/Home'
import Spinner from '../components/Spinner';

var dateFormat = require('dateformat');

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            search: '',
            fedexNumber: ''
        };

        this.getTopTenOrders();
        this.getTopTenSales();
    }

    getTopTenOrders() {
        if (!this.props.OrdersReducer.recentOrders)
            this.props.getTopTenOrders(this.props.userToken.idToken.jwtToken, this.props.viewMode === 'Dealers' ? this.props.userId : null).catch(() => this.setState({ hasError: true }))
        else
            this.makeColumnsOfOrders(this.props.OrdersReducer.fields)
    }

    getTopTenSales() {
        if (!this.props.SalesReducer.recentSales)
            this.props.getTopTenSales(this.props.userToken.idToken.jwtToken, this.props.viewMode === 'Dealers' ? this.props.userId : null).catch(() => this.setState({ hasError: true }))
        else
            this.makeColumnsOfSales(this.props.SalesReducer.fields)
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!nextProps.userToken) {
            if (!nextProps.OrdersReducer.fields) {
                nextProps.getTopTenOrders(nextProps.userToken.idToken.jwtToken, nextProps.viewMode === 'Dealers' ? nextProps.userId : null).catch(() => this.setState({ hasError: true }))
            }
            if (!nextProps.SalesReducer.fields) {
                nextProps.getTopTenSales(nextProps.userToken.idToken.jwtToken, nextProps.viewMode === 'Dealers' ? nextProps.userId : null).catch(() => this.setState({ hasError: true }))
            }
            if (!!nextProps.OrdersReducer.recentOrders)
                this.makeColumnsOfOrders(nextProps.OrdersReducer.fields)
            if (!!nextProps.SalesReducer.recentSales)
                this.makeColumnsOfSales(nextProps.SalesReducer.fields)
        }
    }

    makeColumnsOfOrders(array) {
        let arrayOfItems = []
        array.forEach((item) => {
            if (item === 'Dealer' && this.props.viewMode !== 'Dealers')
                arrayOfItems.push({ Header: item, accessor: item });
            else if (item === 'TOTAL') {
                arrayOfItems.push({
                    Header: item, id: "currency", accessor: d =>
                        <div><NumberFormat value={d.TOTAL} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></div>
                });
            }
            else if (item === 'COMPANY')
                arrayOfItems.push({
                    Header: 'Name', id: "name", accessor: d =>
                        <div>{d.COMPANY ? `${d.COMPANY}` : 'Stock Inventory'}</div>
                });
            else if (item === "Date")
                arrayOfItems.push({
                    Header: 'Date',
                    id: 'dateAccessor',
                    accessor: d => <div>{dateFormat(d.Date, "fullDate")}</div>
                });
        })
        this.setState({ columnsOfOrders: arrayOfItems })
    }

    makeColumnsOfSales(array) {
        let arrayOfItems = []
        array.forEach((item) => {
            if (item === 'Total Cost') {
                arrayOfItems.push({
                    Header: item, id: "currency", accessor: d =>
                        <div><NumberFormat value={d['Total Cost']} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></div>
                });
            }
            else if (item === 'COMPANY' && this.props.viewMode !== 'Dealers')
                arrayOfItems.push({ Header: 'Dealer', accessor: item });
            else if (item === 'FIRSTNAME')
                arrayOfItems.push({
                    Header: 'Name', id: "name", accessor: d =>
                        <div>{d.FIRSTNAME + " " + d.LASTNAME}</div>
                });
            else if (item === "Date")
                arrayOfItems.push({
                    Header: 'Date',
                    id: 'dateAccessor',
                    accessor: d => <div>{dateFormat(d.Date, "fullDate")}</div>
                });
        })
        this.setState({ columnsOfSales: arrayOfItems })
    }

    onChangeFedexNumber = (event) => {
        this.setState({ fedexNumber: event.target.value })
    }

    saveFedexSetted = () => {
        this.props.updateFedexDealer(this.props.userToken.idToken.jwtToken, this.props.dealerInfo.data.ID, { FEDEXNUMBER: this.state.fedexNumber })
    }

    render() {
        if (!!this.props.SalesReducer.recentSales && !!this.props.OrdersReducer.recentOrders) {
            let data = {
                dataOfOrders: this.props.OrdersReducer.recentOrders,
                dataOfSales: this.props.SalesReducer.recentSales
            }
            let columns = {
                columnsOfOrders: this.state.columnsOfOrders,
                columnsOfSales: this.state.columnsOfSales
            }
            return (
                <div>
                    <HomeComponent
                        data={data}
                        columns={columns}
                        hasError={this.state.hasError}
                        history={this.props.history}
                        viewMode={this.props.viewMode}
                        dealerInfo={this.props.dealerInfo}
                        saveFedexSetted={this.saveFedexSetted}
                        fedexNumber={this.state.fedexNumber}
                        onChangeFedexNumber={this.onChangeFedexNumber}
                        resetStateOfReportSale={this.props.resetStateOfReportSale}
                        resetStateOfPlaceOrder={this.props.resetStateOfPlaceOrder} />
                </div>
            );
        } else if (this.state.hasError)
            return <HomeComponent hasError={this.state.hasError} />
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        userId: state.Auth.id,
        SalesReducer: state.SalesReducer,
        OrdersReducer: state.OrdersReducer,
        viewMode: state.AppReducer.currentViewMode,
        groups: state.Auth.groups,
        dealerInfo: state.DealerInfo
    }
}

export default withRouter(connect(mapStateToProps, {
    getTopTenSales,
    getTopTenOrders,
    getDealersFromApi,
    updateFedexDealer,
    resetStateOfReportSale,
    resetStateOfPlaceOrder
})(Home));