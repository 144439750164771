import React, { Component } from 'react';
import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';


class ConfirmationModal extends Component {
    render() {
        const { title, handleCloseModal, openModal, deleteRow, deleteItem } = this.props;
        return (
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{`Confirm delete this ${title}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {deleteRow !== null &&
                            <span>Would you like to delete <span style={{ fontWeight: '500', fontStyle: 'italic' }}>
                                {title === 'Product' && deleteRow.TITLE}
                                {title === 'Key' && deleteRow.KEY}
                                {(title === 'Dealer' || title === 'Customer') && <span>{deleteRow.FIRSTNAME} {deleteRow.LASTNAME} </span>}
                                {title === 'Order' && deleteRow['PO #']}
                                {title === 'User' && deleteRow.Attributes[2].Value}
                                ?
                                </span>
                            </span>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button onClick={() => { deleteItem(deleteRow) }} style={{ color: 'red' }} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default (ConfirmationModal);