import { GET_PRODUCTS, DELETE_PRODUCT, ADD_PRODUCT, UPDATE_PRODUCT, UPDATE_PRODUCT_ENABLE, SAVE_IMAGE, RESET } from '../actions'
import { getColumnsOfTable, deleteItemFromArray, enabledProduct, addImageForProduct } from '../services'

export default function ProductsReducer(state = { products: null, fields: null }, action) {
    switch (action.type) {
        case GET_PRODUCTS:
            const columnsName = getColumnsOfTable(action.payload.data.results[0])
            return {
                ...state,
                products: action.payload.data.results,
                fields: columnsName
            }
        case UPDATE_PRODUCT:
        case ADD_PRODUCT:
            return {
                ...state,
                products: null,
                fields: null
            }
        case DELETE_PRODUCT:
            return { ...state, products: deleteItemFromArray(state.products, action.payload, 'ID') }
        case UPDATE_PRODUCT_ENABLE:
            return { ...state, products: enabledProduct(state.products, action.payload) }
        case SAVE_IMAGE:
            return { ...state, products: addImageForProduct(state.products, action.payload) }
        case RESET:
            return { ...state, products: null, fields: null }
        default:
            return state
    }
}
