import React, { Component } from 'react';
import { Field, reduxForm, Form } from 'redux-form'
import { withStyles, Paper, Button, Grid, Typography, MenuItem } from "@material-ui/core";

import { required, requiredNumber } from './validator'
import { renderTextField, renderSelectField } from './CommonInputs'
import { orderBy } from '../../services'

const styles = theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: '30px'
    },
    boxCard: {
        padding: '25px',
        width: "600px !important"
    },
    selectContainer: {
        borderBottom: '1px dashed #9f9f9f',
        paddingBottom: '20px',
        marginBottom: '15px'
    },
    textField: {
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '100%',
    },
    button: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing()
    }
});

class Key extends Component {

    render() {
        const {
            handleSubmit,
            classes,
            title,
            optionsDealers,
            optionsProduct,
            goBack,
            handleOnSubmit,
            pristine,
            submitting,
            initialValues
        } = this.props;
        return (
            <div className={classes.wrapper}>
                <Paper className={classes.boxCard}>
                    <Grid container className={classes.selectContainer} >
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                    </Grid >
                    <Form onSubmit={handleSubmit(handleOnSubmit)} initialvalues={initialValues}>
                        <Grid container>
                            <Field
                                name="DEALERID"
                                label="Dealer"
                                component={renderSelectField}
                                validate={[requiredNumber]}
                                // selectProps={{ native: true }}
                                className={classes.textField}>
                                {orderBy(optionsDealers, "COMPANY")}
                                <MenuItem></MenuItem>
                                {optionsDealers.map((option, index) => (
                                    <MenuItem key={index} value={option.DealerID}>
                                        {option.COMPANY}
                                    </MenuItem>
                                ))}
                            </Field>
                            <Field
                                name="PRODUCTID"
                                label="Product"
                                validate={[requiredNumber]}
                                className={classes.textField}
                                component={renderSelectField}
                            >
                                <MenuItem></MenuItem>
                                {orderBy(optionsProduct, "TITLE")}
                                {optionsProduct.map((option, index) => (
                                    <MenuItem key={index} value={option.ID}>
                                        {option.TITLE}
                                    </MenuItem>
                                ))}
                            </Field>
                            <Field
                                name="KEY"
                                label="Key"
                                className={classes.textField}
                                component={renderTextField}
                                type="text"
                                validate={[required]} />
                        </Grid>
                        <Grid container justifyContent="center" style={{ marginTop: '30px' }}>
                            <Button
                                variant="contained"
                                color="default"
                                size="large"
                                className={classes.button}
                                onClick={goBack}
                                disabled={submitting}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                type="submit"
                                disabled={pristine || submitting}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Form>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(reduxForm({ form: 'key' })(Key));