import React, { Component } from 'react';
import { renderTextField, renderSelectMultipleField } from './CommonInputs'
import { Field, reduxForm, Form } from 'redux-form';
import { withStyles, Paper, Button, Grid, Typography, MenuItem } from "@material-ui/core";
import { required, email } from './validator'

const styles = theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: '30px'
    },
    boxCard: {
        padding: '20px',
        boxSizing: 'border-box'
    },
    selectContainer: {
        borderBottom: '1px dashed #9f9f9f',
        paddingBottom: '20px',
        marginBottom: '15px'
    },
    textField: {
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '100%',
    },
    button: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing()
    }
});

class User extends Component {

    componentDidMount() {
        if (this.props.isModal)
            this.props.change('GROUPS', ["Dealers"])
    }

    render() {
        const {
            formData,
            handleSubmit,
            handleOnSubmit,
            classes,
            title,
            optionsGroups,
            goBack,
            pristine, submitting,
            isModal,
            type
        } = this.props;
        return (
            <div className={classes.wrapper}>
                <Paper className={classes.boxCard}>
                    <Grid container className={classes.selectContainer} >
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                    </Grid >
                    <Grid container>
                        <Form onSubmit={handleSubmit(handleOnSubmit)} >
                            <Field
                                name="USERNAME"
                                label="Username"
                                component={renderTextField}
                                value={formData.USERNAME}
                                type="text"
                                className={classes.textField}
                                validate={[required]}
                                disabled={type === 'Edit'}
                            />
                            <Field
                                name="NAME"
                                label="Name"
                                component={renderTextField}
                                type="text"
                                value={formData.NAME}
                                className={classes.textField}
                                validate={[required]}
                            />
                            <Field
                                name="EMAIL"
                                label="Email"
                                component={renderTextField}
                                type="email"
                                value={formData.EMAIL}
                                className={classes.textField}
                                validate={[required, email]}
                                disabled={type === 'Edit'}
                            />
                            {!isModal && <Field
                                name="GROUPS"
                                label="User groups"
                                component={renderSelectMultipleField}
                                className={classes.textField}
                                validate={[required]}
                                properties={{ id: 'User groups' }}
                            >
                                {optionsGroups.sort()}
                                {optionsGroups.map((option, index) => {
                                    return (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    )
                                })}
                            </Field>
                            }
                            <Grid container justifyContent="center" style={{ marginTop: '30px' }}>
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="large"
                                    className={classes.button}
                                    onClick={goBack}
                                    disabled={submitting}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    type="submit"
                                    disabled={pristine || submitting}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Form>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(reduxForm({
    form: 'user',
})(User));