import React, { Component } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { withStyles, InputBase, InputAdornment } from '@material-ui/core/';

const styles = theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
        },
    },
    searchIcon: {
        width: theme.spacing(9),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '-50px'
    },
    inputRoot: {
        width: '100%',
        borderBottom: '1px solid'
    }
});

class SearchBar extends Component {

    render() {
        const { search, onInputValueChange, classes } = this.props;
        return (
            <div className={classes.search}>
                <InputBase
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    placeholder="Search…"
                    value={search}
                    onChange={onInputValueChange}
                    classes={{ root: classes.inputRoot }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(SearchBar);