import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Customers from '../../components/Forms/Customers';
import { getCountry, getDealers } from '../../services'
import { addCustomer, updateCustomer, getCustomersFromApi } from '../../actions';
import Spinner from '../../components/Spinner';

class CustomersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                DEALERID: '',
                COMPANY: '',
                SALUTATION: '',
                FIRSTNAME: '',
                LASTNAME: '',
                JOBTITLE: '',
                ROLE: '',
                ADDRESS1: '',
                CITY_STATEPROVINCE: '',
                POSTALCODE: '',
                COUNTRYID: '',
                LOCATIONTYPE: '',
                PHONE: '',
                FAX: '',
                EMAIL: '',
                WEBSITE: ''
            },
            typeForm: 'Add',
            optionsDealer: [{}],
            optionsCountry: [{}],
        };
    }

    componentDidMount() {
        if (this.props.customers === null)
            this.props.getCustomersFromApi(this.props.userToken.idToken.jwtToken, this.props.viewMode === 'Dealers' ? this.props.userId : null)
        else if (this.props.match.params.id)
            this.getInitialState(this.props.match.params.id, this.props.customers)
        let dealerId;
        if (this.props.viewMode === 'Dealers')
            dealerId = this.props.userId;
        getDealers(this.props.userToken.idToken.jwtToken, dealerId).then((result) => {
            this.setState({ optionsDealer: result });
        });
        getCountry(this.props.userToken.idToken.jwtToken).then((result) => {
            this.setState({ optionsCountry: result });
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!this.props.match.params.id && nextProps.customers !== null)
            this.getInitialState(this.props.match.params.id, nextProps.customers)
        let dealerId;
        if (this.props.viewMode === 'Dealers')
            dealerId = this.props.userId;
        getDealers(this.props.userToken.idToken.jwtToken, dealerId).then((result) => {
            this.setState({ optionsDealer: result });
        });
        getCountry(this.props.userToken.idToken.jwtToken).then((result) => {
            this.setState({ optionsCountry: result });
        });
    }

    getInitialState = (id, customersReducer) => {
        if (customersReducer) {
            customersReducer.forEach((element) => {
                if (element.ID === Number(id)) {
                    for (var aux in this.state.formData) {
                        const { formData } = this.state;
                        formData[aux] = element[aux];
                        this.setState({ formData });
                    }
                    this.setState({ typeForm: 'Edit' });
                }
            })
        }
    };

    goBack = () => {
        this.props.history.goBack()
    }

    handleOnSubmit = (formData) => {
        if (this.props.viewMode === 'Dealers') {
            formData.DEALERID = this.state.optionsDealer[0].DealerID;
        }
        if (this.props.match.params.id) {
            let userName = this.props.viewMode === 'Dealers' ? this.props.dealerInfo.data.COMPANY : this.props.username ? this.props.username : 'Admin'
            return this.props.updateCustomer(this.props.userToken.idToken.jwtToken, this.props.match.params.id, formData, userName, this.props.history)
        } else {
            return this.props.addCustomer(this.props.userToken.idToken.jwtToken, formData, this.props.history)
        }
    }

    render() {
        if (!!this.props.customers)
            return (
                <Customers
                    formData={this.state.formData}
                    initialValues={this.state.formData}
                    title={`${this.state.typeForm} Customer`}
                    handleOnSubmit={this.handleOnSubmit}
                    optionsDealer={this.state.optionsDealer}
                    optionsCountry={this.state.optionsCountry}
                    goBack={this.goBack}
                    viewMode={this.props.viewMode}
                />
            );
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        customers: state.CustomersReducer.customers,
        viewMode: state.AppReducer.currentViewMode,
        userId: state.Auth.id,
        username: state.Auth.userName,
        dealerInfo: state.DealerInfo
    }
}
export default withRouter(connect(mapStateToProps, { addCustomer, updateCustomer, getCustomersFromApi })(CustomersForm));