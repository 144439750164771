import React, { Component } from 'react';
import { withStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import NumberFormat from 'react-number-format'

const styles = theme => ({
    content: {
        padding: "25px"
    },
    title: {
        marginTop: 0,
        marginBottom: 0,
        borderBottom: "1px solid"
    },
    subTitle: {
        marginBottom: 0,
        borderBottom: "1px solid"
    },
    root: {
        display: 'flex',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 340,
    },
    tableCell: {
        paddingRight: 4,
        paddingLeft: 5
    }
});

class TableReportViewSales extends Component {
    render() {
        const { productsSelected, totalPrice, classes } = this.props
        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}>Product Name</TableCell>
                            <TableCell className={classes.tableCell}>Key</TableCell>
                            <TableCell className={classes.tableCell}>Rhino Key</TableCell>
                            <TableCell className={classes.tableCell}>Part Number</TableCell>
                            <TableCell className={classes.tableCell}>Company</TableCell>
                            <TableCell className={classes.tableCell}>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productsSelected.map((row, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCell}>{row.TITLE}</TableCell>
                                    <TableCell className={classes.tableCell}>{row.key}</TableCell>
                                    <TableCell className={classes.tableCell}>{row.RHkey}</TableCell>
                                    <TableCell className={classes.tableCell}>{row.PARTNUMBER}</TableCell>
                                    <TableCell className={classes.tableCell}>{row.COMPANY}</TableCell>
                                    <TableCell className={classes.tableCell}><NumberFormat value={row.PRICE} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell className={classes.tableCell} colSpan={3}></TableCell>
                            <TableCell className={classes.tableCell} colSpan={2}><b>Total</b></TableCell>
                            <TableCell className={classes.tableCell} colSpan={1} style={{ fontWeight: 'bold' }} ><NumberFormat value={totalPrice} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

export default withStyles(styles)(TableReportViewSales);