import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format'
import { IconButton, Icon, Grid } from '@material-ui/core';

import Generic from '../../components/Generic';
import ConfirmationModal from '../../components/ConfirmationModal';
import { getOrdersFromApi, deleteOrder } from '../../actions';
import ShowCustomer from '../../components/Dialog/ShowCustomer';
import ShowOrder from '../../components/Dialog/ShowOrder';
import Spinner from '../../components/Spinner';
import { getProductOfOrder } from '../../services'

var dateFormat = require('dateformat');

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnsForCSV: [
                { id: 'EMAIL' },
                { id: 'COUNTRYID' },
                { id: 'PRODUCTS' }
            ],
            hasError: false,
            search: '',
            configPage: {
                title: 'Orders',
                buttonDownload: true,
                downloadName: 'Orders',
                buttonAddNew: false,
            },
            anchorEl: null,
            selectID: null,
            typeDialog: null,
            addtionalDatas: [],
            openModal: false,
            deleteRow: null
        }
    }

    componentDidMount() {
        if (!this.props.OrdersReducer.orders) {
            let id = this.props.viewMode === 'Dealers' ? this.props.userId : null;
            this.props.getOrdersFromApi(this.props.userToken.idToken.jwtToken, id)
                .catch(() => {
                    this.props.getOrdersFromApi(this.props.userToken.idToken.jwtToken, id)
                        .catch(() => this.setState({ hasError: true }))
                })
        } else {
            this.makeColumns(this.props.OrdersReducer.fields)
            this.makeData(this.props.OrdersReducer.orders)
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.OrdersReducer.fields) {
            let id = nextProps.viewMode === 'Dealers' ? nextProps.userId : null;
            nextProps.getOrdersFromApi(nextProps.userToken.idToken.jwtToken, id).catch(() => this.setState({ hasError: true }))
        }
        if (!!nextProps.OrdersReducer.orders) {
            this.makeColumns(nextProps.OrdersReducer.fields)
            this.makeData(nextProps.OrdersReducer.orders)
        }
    }

    deleteItem = (row) => {
        this.setState({ openModal: false });
        this.props.deleteOrder(this.props.userToken.idToken.jwtToken, row['PO #'])
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
            selectID: null,
            typeDialog: null,
            addtionalDatas: []
        });
    }

    handleClick = (event, row, type) => {
        if (!row['PRODUCTS']) {
            getProductOfOrder(this.props.userToken.idToken.jwtToken, row['PO #']).then((result) => {
                if (result.length === 0)
                    this.setState({ addtionalDatas: [-1] })
                else
                    this.setState({ addtionalDatas: result })
            })
        }
        this.setState({
            anchorEl: event.currentTarget,
            selectID: row['PO #'],
            typeDialog: type
        });
    };

    getDeleteButton(row) {
        if (this.props.viewMode !== 'Dealers') {
            return (<Grid>
                <IconButton onClick={() => {
                    this.handleClickOpenModal(row)
                }}>
                    <Icon color="error">close</Icon>
                </IconButton>
            </Grid>)
        }
    }

    makeColumns = (orders) => {
        let arrayOfItems = []
        const self = this
        orders.forEach((item) => {
            switch (item) {
                case 'PO #':
                    arrayOfItems.push({ Header: item, id: item, accessor: item, style: { textAlign: "center" } }); break;
                case 'Date':
                    arrayOfItems.push({
                        Header: item, id: item, accessor: d =>
                            <div>{dateFormat(d.Date, "fullDate")}</div>
                        , minWidth: 220
                    }); break;
                case 'Dealer':
                    if (this.props.viewMode !== 'Dealers')
                        arrayOfItems.push({ Header: item, id: item, accessor: item, minWidth: 220 }); break;
                case 'COMPANY':
                    arrayOfItems.push({
                        Header: 'Customer/Stock',
                        accessor: row => {
                            if (!row.COMPANY) {
                                if (row.CUSTOMERID)
                                    return (<div>-</div>);
                                else
                                    return (<div>Stock Inventory</div>)
                            }
                            const self = this
                            return (
                                <div>
                                    <div onClick={(e) => this.handleClick(e, row, 'customer')}>
                                        {row.COMPANY}<Icon style={{ padding: '4px' }}>search</Icon>
                                    </div>
                                    <ShowCustomer
                                        open={(self.state.typeDialog === 'customer') ? Boolean(self.state.selectID === row['PO #']) : false}
                                        onClose={this.handleClose}
                                        anchorEl={this.state.anchorEl}
                                        data={row} />
                                </div>
                            )
                        },
                        id: item,
                        minWidth: 220
                    });
                    break;
                case 'SHIPPINGMETHOD': arrayOfItems.push({ Header: 'Shipping Method', accessor: item, id: item, minWidth: 220 }); break;
                case 'TOTAL':
                    arrayOfItems.push({
                        Header: item, id: item, accessor: d =>
                            <div><NumberFormat value={d.TOTAL} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></div>
                    }); break;
                default: break;
            }
        })

        arrayOfItems.push({
            minWidth: 120,
            Header: 'Actions',
            Cell: (row) => {
                return (
                    <div>
                        <Grid container justifyContent="center" item xs={12}>
                            <Grid>
                                <IconButton onClick={(e) => this.handleClick(e, row, 'order')}>
                                    <Icon>more_horiz</Icon>
                                </IconButton>
                                {Boolean(self.state.selectID === row['PO #'])
                                    && <ShowOrder
                                        open={(self.state.typeDialog === 'order') ? Boolean(self.state.selectID === row['PO #']) : false}
                                        onClose={this.handleClose}
                                        anchorEl={self.state.anchorEl}
                                        data={row}
                                        addtionalDatas={this.state.addtionalDatas} />
                                }
                            </Grid>
                            {this.getDeleteButton(row)}
                        </Grid>
                    </div>
                );
            }
        })
        this.setState({ columns: arrayOfItems })
    }

    makeData(dataOfOrders) {
        this.setState({ content: dataOfOrders })
    }

    onInputValueChange = ({ target }) => {
        this.setState({ search: target.value.toLowerCase() })
    }

    handleClickOpenModal = (row) => {
        this.setState({ openModal: true, deleteRow: row });
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
    };

    render() {
        if (!!this.props.OrdersReducer.orders) {
            let data = this.props.OrdersReducer.orders
            if (this.state.search) {
                data = data.filter(row => {
                    const array = Object.keys(row)
                    let isIncluded = false
                    array.forEach((columnName) => {
                        if (!isIncluded) {
                            if (typeof row[columnName] === 'string') {
                                if ((row[columnName].toLowerCase()).includes(this.state.search.toLowerCase())) {
                                    isIncluded = true
                                }
                            }
                            else if ((String(row[columnName]).toLowerCase()).includes(this.state.search.toLowerCase())) {
                                isIncluded = true
                            }
                        }
                    })
                    if (isIncluded)
                        return true
                    return false;
                })
            }

            const columns = (this.state.columns) ? this.state.columns.concat(this.state.columnsForCSV) : this.state.columnsForCSV
            return (
                <div>
                    <ConfirmationModal title={'Order'} deleteRow={this.state.deleteRow} handleCloseModal={this.handleCloseModal} openModal={this.state.openModal} deleteItem={this.deleteItem} />
                    <Generic
                        data={data}
                        columns={columns}
                        onInputValueChange={this.onInputValueChange}
                        hasError={this.state.hasError}
                        configPage={this.state.configPage}
                        history={this.props.history} />
                </div>
            );
        } else if (this.state.hasError)
            return <Generic hasError={this.state.hasError} />
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        OrdersReducer: state.OrdersReducer,
        viewMode: state.AppReducer.currentViewMode,
        userId: state.Auth.id
    }
}

export default connect(mapStateToProps, { getOrdersFromApi, deleteOrder })(Orders);