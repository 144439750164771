import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderView from '../components/HeaderView';
import SubHeaderView from '../components/SubHeader';
import menuJson from '../resources/Data/menu.json';
import menuDealer from '../resources/Data/menuDealer.json';
import menuSales from '../resources/Data/menuSales.json';
import { withRouter } from 'react-router-dom';
import { signOutUser, setViewMode, getDealersFromApi } from '../actions';
import { showNotification } from '../services/notification';
let store = require('store/storages/sessionStorage');

class Header extends Component {

    constructor(props) {
        super(props);
        const viewMode = store.read("ViewMode")
        if (this.props.groups === undefined || this.props.groups.length === 0) {
            let config = {
                icon: 'error',
                type: 'error',
                message: "This user doesn't have any group, please contact with chris_burger@gemvision.com",
                autoClose: true,
            }
            showNotification(config)
            this.logout()
        } else if (!!viewMode) {
            this.props.setViewMode(viewMode);
        } else if (this.props.groups.indexOf('Admin') !== -1) {
            this.props.setViewMode('Admin');
            store.write('ViewMode', 'Admin')
        } else if (this.props.groups.indexOf('Sales') !== -1) {
            this.props.setViewMode('Sales');
            store.write('ViewMode', 'Sales')
        } else if (this.props.groups.indexOf('Dealers') !== -1) {
            this.props.setViewMode('Dealers');
            store.write('ViewMode', 'Dealers')
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.viewMode === 'Dealers') {
            if (nextProps.dealerInfo.data === null) {
                this.props.getDealersFromApi(nextProps.userToken.idToken.jwtToken, this.props.userId)
            }
        }
    }

    onSetViewMode = (nextViewMode) => {
        store.write('ViewMode', nextViewMode)
        this.props.history.push("/home")
        this.props.history.go()
    }

    logout = () => {
        console.log("LOGGING OUT...");
        this.props.signOutUser();
    }

    getOwnDealerData(nextViewMode) {
        if (nextViewMode === 'Dealers') {
            if (!this.props.dealerInfo || !this.props.dealerInfo.data) {
                this.props.getDealersFromApi(this.props.userToken.idToken.jwtToken, this.props.userId)
            }
        }
    }

    render() {
        return (
            <div>
                <div>
                    <HeaderView userName={this.props.userName} logout={this.logout} groups={this.props.groups} viewMode={this.props.viewMode} onSetViewMode={this.onSetViewMode} />
                    <SubHeaderView
                        menus={this.props.viewMode === 'Admin' ? menuJson.menus : this.props.viewMode === 'Dealers' ? menuDealer.menus : menuSales.menus}
                        history={this.props.history} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userToken: state.Auth.signedInUser.signInUserSession,
    userName: state.Auth.signedInUser.username,
    groups: state.Auth.groups,
    viewMode: state.AppReducer.currentViewMode,
    userId: state.Auth.id,
    dealerInfo: state.DealerInfo
})

export default withRouter(connect(mapStateToProps, { signOutUser, setViewMode, getDealersFromApi })(Header));