import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types"
import { Stepper, Step, StepLabel, Button, Grid, Paper, Icon, Container } from '@material-ui/core';

import NewOrderButtons from '../../components/NewOrderButtons';
import SelectUserForm from '../Sales/FormSteps/SelectUserForm';
import AddProduct from './FormSteps/AddProduct';
import ReviewReport from './FormSteps/ReviewReport';
import {
    getCustomersFromApi,
    getProductsFromApi,
    insertUserFromPlaceOrder,
    saveProductsSelectedFromPlaceOrder,
    submitOrderReport,
    updateCustomer,
    addCustomer,
    resetStateOfPlaceOrder,
    resetProductStateOfPlaceOrder
} from '../../actions';

class NewOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeStep: 0,
            weCanMoveOn: false,
            title: 'Order Process',
            hiddenForm: false,
            totalPrice: null,
            fedexShipping: '',
            notes: '',
            disabled: true,
            otherShipping: '',
            page: 0
        };
        this.selectStock = false
    }

    componentDidMount() {
        if (!this.props.CustomersReducer.fields)
            this.props.getCustomersFromApi(this.props.userToken.idToken.jwtToken, this.props.userId).catch(() => this.setState({ hasError: true }))
        if (!this.props.ProductsReducer.products)
            this.props.getProductsFromApi(this.props.userToken.idToken.jwtToken, true)
                .catch(() => this.props.getProductsFromApi(this.props.userToken.idToken.jwtToken, true))
                .catch(() => this.setState({ hasError: true }))
    }

    getSteps = () => {
        return ['Select User', 'Add Product', 'Review Cart'];
    }

    handleNext = () => {
        // if (this.form.isFormValid(false) || this.selectStock) {
        this.setState({
            page: this.state.page + 1,
        })
        this.props.resetProductStateOfPlaceOrder()
    }
    // };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
        this.resetPrpertiesForTheFirstStep()
    };

    resetPrpertiesForTheFirstStep = () => {
        if (this.state.activeStep === 1) {
            this.setState({ hiddenForm: false });
            this.selectStock = false
        }
    }

    handleReset = () => {
        this.setState({ activeStep: 0, page: 0, hiddenForm: false });
        this.selectStock = false;
    };

    nextPage = () => {
        this.setState({ page: this.state.page + 1 })
    }

    previousPage = () => {
        this.setState({ page: this.state.page - 1 })
    }

    handleOnSubmit = async () => {
        let user = { ...this.props.PlaceOrderReducer.userData }
        if (this.props.PlaceOrderReducer.userData) {
            if (!!user.ID && user.ID !== '0') {
                if (this.props.PlaceOrderReducer.userChanged !== false) {
                    let id = user.ID;
                    delete user.ID
                    try {
                        let userName = this.props.viewMode === 'Dealers' ? this.props.dealerInfo.data.COMPANY : this.props.username ? this.props.username : 'Admin'
                        await this.props.updateCustomer(this.props.userToken.idToken.jwtToken, this.props.PlaceOrderReducer.userData.ID, user, userName)
                    } catch (error) {
                        console.log(error)
                    }
                    user['ID'] = id
                }
            } else {
                try {
                    let res = await this.props.addCustomer(this.props.userToken.idToken.jwtToken, this.props.PlaceOrderReducer.userData)
                    user.ID = res
                } catch (error) {
                    throw new Error('Error adding the user')
                }
            }
        }

        try {
            let orderData = {
                dealer: this.props.dealerInfo.data,
                shipping: this.state.fedexShipping,
                customerID: (this.props.PlaceOrderReducer.userData) ? user.ID : null,
                customerCompany: (this.props.PlaceOrderReducer.userData) ? user.COMPANY : null,
                customerName: (this.props.PlaceOrderReducer.userData) ? `${user.FIRSTNAME} ${user.LASTNAME}` : null,
                customerAddress: (this.props.PlaceOrderReducer.userData) ? `${user.ADDRESS1},\n${user.POSTALCODE}, ${user.CITY_STATEPROVINCE}` : null,
                customerEmail: (this.props.PlaceOrderReducer.userData) ? user.EMAIL : null,
                customerPhone: (this.props.PlaceOrderReducer.userData) ? user.PHONE : null,
                customerWebsite: (this.props.PlaceOrderReducer.userData) ? user.WEBSITE : null,
                customerVAT: (this.props.PlaceOrderReducer.userData) ? user.VAT : null,
                customerEORI: (this.props.PlaceOrderReducer.userData) ? user.EORI : null,
                customerFax: (this.props.PlaceOrderReducer.userData) ? user.FAX : null,
                customerCountryId: (this.props.PlaceOrderReducer.userData) ? user.COUNTRYID : null,
                total: this.state.totalPrice,
                stock: (this.props.PlaceOrderReducer.userData) ? false : true,
                notes: this.state.notes,
                orderItems: []
            }
            for (const item in this.props.PlaceOrderReducer.productsSelected) {
                if (this.props.PlaceOrderReducer.productsSelected.hasOwnProperty(item)) {
                    const element = this.props.PlaceOrderReducer.productsSelected[item];
                    let orderItem = {
                        productId: element.ID,
                        quantity: element.QUANTITY,
                        unitPrice: element.PRICE,
                        title: element.TITLE,
                        partnumber: element.PARTNUMBER,
                        RHINOKEY: (element.keyRhino) ? element.keyRhino : '',
                        OPTIONS: (element.OPTIONS) ? element.OPTIONS : '',
                    }
                    orderData.orderItems.push(orderItem)
                }
            }

            this.props.saveProductsSelectedFromPlaceOrder([])
            return this.props.submitOrderReport(this.props.userToken.idToken.jwtToken, orderData, this.props.history)
        } catch (error) {
            console.log(error)
        }
    }

    handleClick = (type) => {
        if (type === 'stock') {
            this.setState({ hiddenForm: true });
            this.props.resetStateOfPlaceOrder()
            this.selectStock = true
            this.handleNext();
        }
    }

    calcTotalPrice = (nextProps) => {
        let totalPrice = 0
        nextProps.PlaceOrderReducer.productsSelected.map(item => totalPrice += item.PRICE * item.QUANTITY)
        totalPrice = Math.round(totalPrice * 100) / 100
        this.setState({ totalPrice })
    }

    onInputValueChange = ({ target }) => {
        if (target.name === 'fedexShipping') {
            if (target.value === 'other')
                this.setState({ [target.name]: target.value, disabled: false });
            else
                this.setState({ [target.name]: target.value, disabled: true, otherShipping: '' });
        }
        else
            this.setState({ [target.name]: target.value });
    };

    render() {
        const { page } = this.state
        const steps = this.getSteps();
        return (
            <React.Fragment>
                <Container style={{ padding: 20 }}>
                    <Paper>
                        <Grid container style={{
                            backgroundColor: '#fff',
                            textAlign: 'center',
                        }}>
                            <Grid item xs={12} sm={4}>
                                <h2>{this.state.title}</h2>
                            </Grid>
                            <Grid item xs={10} sm={7}>
                                <Stepper style={{ padding: '10px' }} activeStep={page} alternativeLabel>
                                    {steps.map(label => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>))}
                                </Stepper>
                            </Grid>
                            <Grid item xs={2} sm={1} style={{ marginTop: 15, justifyContent: 'flex-end' }} >
                                <Button
                                    color="default"
                                    size="small"
                                    onClick={this.handleReset}
                                >
                                    <Icon>clear</Icon>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
                <div>
                    {page === 0 && <Container>
                        <NewOrderButtons handleClick={this.handleClick} />
                        {this.state.hiddenForm ? null : <SelectUserForm
                            actionToSaveUserData={this.props.insertUserFromPlaceOrder}
                            userData={this.props.PlaceOrderReducer.userData} onSubmit={this.nextPage} />}
                    </Container>}
                    {page === 1 && (
                        <AddProduct
                            previousPage={this.handleReset}
                            onSubmit={this.nextPage}
                            actionToSaveProducts={this.props.saveProductsSelectedFromPlaceOrder}
                            productsSelected={this.props.PlaceOrderReducer.productsSelected}
                            hiddenForm={this.state.hiddenForm}
                            selectStock={this.selectStock}
                        />
                    )}
                    {page === 2 && (
                        <ReviewReport
                            notes={this.state.notes}
                            totalPrice={this.state.totalPrice}
                            fedexShipping={this.state.fedexShipping}
                            disabled={this.state.disabled}
                            otherShipping={this.state.otherShipping}
                            calcTotalPrice={this.calcTotalPrice}
                            onInputValueChange={this.onInputValueChange}
                            previousPage={this.previousPage}
                            handleOnSubmit={this.handleOnSubmit}
                        />
                    )}
                </div>
            </React.Fragment>)
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        CustomersReducer: state.CustomersReducer,
        ProductsReducer: state.ProductsReducer,
        PlaceOrderReducer: state.PlaceOrderReducer,
        userId: state.Auth.id,
        username: state.Auth.userName,
        dealerInfo: state.DealerInfo
    }
}
NewOrder.propTypes = {
    onSubmit: PropTypes.func.isRequired
}


export default withRouter(connect(mapStateToProps, {
    getCustomersFromApi,
    getProductsFromApi,
    insertUserFromPlaceOrder,
    saveProductsSelectedFromPlaceOrder,
    submitOrderReport,
    updateCustomer,
    addCustomer,
    resetStateOfPlaceOrder,
    resetProductStateOfPlaceOrder
})(NewOrder));