import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './containers/Login';
import NewPassword from './containers/NewPassword';
import Home from './containers/Home';
import Orders from './containers/Orders/Orders';
import Sales from './containers/Sales/Sales';
import Products from './containers/Products/Products';
import ProductsForm from './containers/Products/ProductsForm';
import Inventory from './containers/Inventory/Inventory';
import InventoryForm from './containers/Inventory/InventoryForm';
import Keys from './containers/Keys/Keys';
import KeysForm from './containers/Keys/KeysForm';
import Dealers from './containers/Dealers/Dealers';
import DealersForm from './containers/Dealers/DealersForm';
import Customers from './containers/Customers/Customers';
import CustomersForm from './containers/Customers/CustomersForm';
import ResetPassword from './containers/ResetPassword';
import ForgetPassword from './containers/ForgetPassword';
import Users from './containers/Users/Users';
import UsersForm from './containers/Users/UsersForm';
import NewOrder from './containers/Orders/NewOrder';
import ReportSale from './containers/Sales/ReportSale';
import NoGroups from './containers/NoGroups';
import ImportProducts from './containers/Products/ImportProducts';

const RouteConfigExample = () => {
    return (
        <Switch>
            <Route path="/users/form/:id" component={UsersForm} />
            <Route path="/users/form" component={UsersForm} />
            <Route path="/users" component={Users} />
            <Route path="/nogroups" component={NoGroups} />
            <Route path="/forgetpassword" component={ForgetPassword} />
            <Route path="/newpassword" component={NewPassword} />
            <Route path="/resetpassword/:email" component={ResetPassword} />
            <Route path="/resetpassword" component={ResetPassword} />
            <Route path="/home" component={Home} />
            <Route path="/orders" component={Orders} />
            <Route path="/sales" component={Sales} />
            <Route path="/products/form/:id" component={ProductsForm} />
            <Route path="/importProducts" component={ImportProducts} />
            <Route path="/products/form" component={ProductsForm} />
            <Route path="/products" component={Products} />
            <Route path="/inventory/form/:id" component={InventoryForm} />
            <Route path="/inventory/form" component={InventoryForm} />
            <Route path="/inventory" component={Inventory} />
            <Route path="/keys/form/:id" component={KeysForm} />
            <Route path="/keys/form" component={KeysForm} />
            <Route path="/keys" component={Keys} />
            <Route path="/dealers/form/:id" component={DealersForm} />
            <Route path="/dealers/form" component={DealersForm} />
            <Route path="/dealers" component={Dealers} />
            <Route path="/customers/form/:id" component={CustomersForm} />
            <Route path="/customers/form" component={CustomersForm} />
            <Route path="/customers" component={Customers} />
            <Route path="/neworder" component={NewOrder} />
            <Route path="/reportsale" component={ReportSale} />
            <Route exact path="/" component={Login} />
        </Switch>
    );
}

export default RouteConfigExample;