import React, { Component } from 'react'
import { connect } from 'react-redux'
import RouteConfigExample from './routes'
import HeaderContainer from './containers/Header'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router'
import { registerUser, signOutUser } from './actions'
import './App.css'
import { showNotification } from './services/notification'
import { Auth } from 'aws-amplify'
import Spinner from './components/Spinner'

let store = require('store/storages/sessionStorage')

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoging: true,
            isLogged: false,
            hasError: false,
            error: null,
            errorInfo: null
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, error: error, errorInfo: info })
        clearInterval(this.state.checkTokenuser)
    }

    UNSAFE_componentWillMount = async () => {
        const self = this
        try {
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: true
            })
            self.props.registerUser(user)
            self.setState({ isLogged: true, isLoging: false })
            return
        } catch (error) {
            console.error(error)
            self.setState({ isLoging: false })
        }
    }

    componentDidMount = () => {
        const self = this
        let checkTokenuser = setInterval(() => {
            if (!!self.props.signedIn) {
                this.tokenIsValid().then((result) => {
                    if (!result) {
                        store.remove('clientId')
                        let config = {
                            icon: 'error',
                            message: 'The user token expired',
                            autoClose: true,
                            type: 'error'
                        }
                        showNotification(config)
                        store.remove("dateWhenSignIn")
                        this.props.signOutUser()
                    }
                })
            }
        }, 5000)
        this.setState({ checkTokenuser: checkTokenuser })
    }

    componentWillUnmount = () => {
        clearInterval(this.state.checkTokenuser)
    }

    tokenIsValid = () => {
        return new Promise((resolve, reject) => {
            let HourOfLastSign = Number(store.read("dateWhenSignIn"))
            let oneHour = 60 * 60 * 1000
            HourOfLastSign += oneHour
            if (Date.now() > HourOfLastSign)
                resolve(false)
            else
                resolve(true)
        })
    };

    render() {
        if (this.state.hasError) {
            return (<div> There is an error with your account, please contact... </div>)
        }
        const path = this.props.location.pathname
        let notloggedAllowedPaths = path === "/forgetpassword" || path === '/newpassword' || path.indexOf('/resetpassword') > -1
        if (this.state.isLoging) {
            return <Spinner />
        } else if (!!this.props.userName || path === '/' || notloggedAllowedPaths || this.state.isLogged) {
            return (
                <div>
                    {path !== '/' && !notloggedAllowedPaths ? <HeaderContainer /> : null}
                    <RouteConfigExample />
                </div>
            )
        } else {
            return (
                <Redirect to="/" />
            )
        }
    }
}

const mapStateToProps = (state) => ({
    userName: state.Auth.userName,
    signedIn: state.Auth.signedIn
})

export default withRouter(connect(mapStateToProps, { registerUser, signOutUser })(App))