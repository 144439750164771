import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton, Icon } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import Spinner from '../../components/Spinner';
import Generic from '../../components/Generic';
import ConfirmationModal from '../../components/ConfirmationModal';
import { getKeysFromApi, deleteKey } from '../../actions';

var dateFormat = require('dateformat');

class Keys extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            search: '',
            configPage: {
                title: 'Keys',
                buttonDownload: true,
                downloadName: 'Keys',
                buttonAddNew: false,
                showSold: false,
            },
            content: [],
            columns: [],
            openModal: false,
            deleteRow: null
        };
    }

    componentDidMount() {
        if (!this.props.KeysReducer.fields || !this.props.KeysReducer.keys) {
            this.props.getKeysFromApi(this.props.userToken.idToken.jwtToken)
                .catch(() => {
                    this.props.getKeysFromApi(this.props.userToken.idToken.jwtToken)
                        .catch(() => this.setState({ hasError: true }))
                })
        } else {
            this.makeColumns(this.props.KeysReducer.fields)
            this.makeData(this.props.KeysReducer.keys)
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!nextProps.KeysReducer.fields) {
            this.makeColumns(nextProps.KeysReducer.fields)
            this.makeData(nextProps.KeysReducer.keys)
        }
    }

    editItem(id) {
        this.props.history.push(`${this.state.configPage.title.toLowerCase()}/form/${id}`);
    }

    deleteItem = (row) => {
        this.setState({ openModal: false });
        this.props.deleteKey(this.props.userToken.idToken.jwtToken, row.ID)
    }

    makeColumns(keys) {
        let arrayOfItems = []
        keys.forEach((item) => {
            switch (item) {
                case 'Dealer':
                    arrayOfItems.push({ Header: item, accessor: item, id: item, minWidth: 400 }); break;
                case 'Type':
                    arrayOfItems.push({ Header: item, accessor: item, id: item, minWidth: 150 }); break;
                case 'FIRSTNAME':
                    arrayOfItems.push({
                        Header: 'Customer', id: "customer", minWidth: 220, accessor: d =>
                            <div >{(d.FIRSTNAME ? d.FIRSTNAME : '') + " " + (d.LASTNAME ? d.LASTNAME : '')}</div>
                    });
                    break;
                case 'KEY': arrayOfItems.push({ Header: 'Key', accessor: item, id: item, minWidth: 220 }); break;
                case 'DATEADDED':
                    arrayOfItems.push({
                        Header: 'Date Added', id: item, accessor: d =>
                            <div>{dateFormat(d.DATEADDED, "fullDate")}</div>
                        , minWidth: 220
                    }); break;
                case 'Sold Date':
                    arrayOfItems.push({
                        Header: 'Date Sold', id: item, accessor: d =>
                            <div>{dateFormat(d['Sold Date'], "fullDate")}</div>
                        , minWidth: 220
                    }); break;
                case 'TITLE':
                    arrayOfItems.push({ Header: 'Product', accessor: item, id: item, minWidth: 200 })
                    break;
                default: break;
            }
        })
        arrayOfItems.push({
            minWidth: 120,
            Header: 'Actions',
            style: { textAlign: "center" },
            Cell: row => {
                return (<div>
                    <IconButton onClick={() => {
                        this.editItem(row.ID)
                    }}>
                        <Icon>edit</Icon>
                    </IconButton>
                    <IconButton onClick={() => {
                        this.handleClickOpenModal(row)
                    }}>
                        <Icon color="error">close</Icon>
                    </IconButton>
                </div>)
            }
        })
        this.setState({ columns: arrayOfItems })
    }

    makeData(dataOfKeys) {
        this.setState({ content: dataOfKeys })
    }

    onInputValueChange = ({ target }) => {
        this.setState({ search: target.value.toLowerCase() })
    }

    showSold = () => {
        if (!this.state.configPage.showSold) {
            let keyOld = [];
            this.props.KeysReducer.keys.forEach(element => {
                if (element.SOLD !== null && element.SOLD)
                    keyOld.push(element)
            });
            this.makeData(keyOld)
        }
        else
            this.makeData(this.props.KeysReducer.keys)

        this.setState({ configPage: { ...this.state.configPage, showSold: !this.state.configPage.showSold } })
    }

    handleClickOpenModal = (row) => {
        this.setState({ openModal: true, deleteRow: row });
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
    };

    render() {
        if (!!this.props.KeysReducer.fields) {
            let data = this.state.content
            if (this.state.search) {
                data = data.filter(row => {
                    const array = Object.keys(row)
                    let isIncluded = false
                    array.forEach((columnName) => {
                        if (!isIncluded) {
                            if (typeof row[columnName] === 'string') {
                                if ((row[columnName].toLowerCase()).includes(this.state.search.toLowerCase())) {
                                    isIncluded = true
                                }
                            }
                            else if ((String(row[columnName]).toLowerCase()).includes(this.state.search.toLowerCase())) {
                                isIncluded = true
                            }
                        }
                    })
                    if (isIncluded)
                        return true
                    return false;
                })
            }
            return (
                <div>
                    <ConfirmationModal title={'Key'} deleteRow={this.state.deleteRow} handleCloseModal={this.handleCloseModal} openModal={this.state.openModal} deleteItem={this.deleteItem} />
                    <Generic
                        data={data}
                        columns={this.state.columns}
                        onInputValueChange={this.onInputValueChange}
                        hasError={this.state.hasError}
                        configPage={this.state.configPage}
                        history={this.props.history}
                        showSold={this.showSold} />
                </div>
            );
        } else if (this.state.hasError)
            return <Generic configPage={this.state.configPage} hasError={this.state.hasError} />
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        KeysReducer: state.KeysReducer
    }
}

export default withRouter(connect(mapStateToProps, { getKeysFromApi, deleteKey })(Keys));