import React, { Component } from 'react';
import { Menu, Item } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

class ContextMenu extends Component {
    render() {
        return (
            <div>
                <Menu id='menu_id'>
                    <Item onClick={this.props.handleCopyValueOnAllColumn}>Copy value on all items of this column</Item>
                    {/* <Item onClick={this.props.handleInsertNewColumn}>Insert new Column</Item> */}
                    <Item onClick={this.props.handleDeleteColumn}>Delete this column</Item>
                </Menu>
            </div>
        );
    }
}

export default ContextMenu;