import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import User from '../../components/Forms/User';
import { addUser, updateUser, getUsersFromApi, getGroupsFromApi } from '../../actions'
import { finder } from '../../services'
import Spinner from '../../components/Spinner';

class UsersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                USERNAME: '',
                NAME: '',
                EMAIL: '',
                GROUPS: []
            },
            typeForm: 'Add',
            optionsGroups: [{}],
        };
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        if (this.props.UsersReducer.users === null) {
            this.props.getUsersFromApi(this.props.userToken.idToken.jwtToken, true).catch(() => this.setState({ hasError: true }))
            this.props.getGroupsFromApi(this.props.userToken.idToken.jwtToken).catch(() => this.setState({ hasError: true }))
        }
        else if (this.props.match.params.id)
            this.getInitialState(this.props.match.params.id, this.props.UsersReducer)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!this.props.match.params.id && nextProps.UsersReducer.users !== null && nextProps.UsersReducer.groups !== null)
            this.getInitialState(this.props.match.params.id, nextProps.UsersReducer)
    }

    getInitialState = (id, UsersReducer) => {
        if (!!UsersReducer.users && !this.props.isModal) {
            let user = UsersReducer.users.find((item) => item.Username === id);
            const { formData } = this.state;
            formData.USERNAME = id
            formData.NAME = finder(user.Attributes, 'name')
            formData.EMAIL = finder(user.Attributes, 'email')
            formData.GROUPS = user.groups
            this.setState({ formData, typeForm: 'Edit' });
        }
    };

    goBack() {
        if (this.props.isModal)
            this.props.closeModal()
        else
            this.props.history.goBack()
    }

    onInputValueChange = ({ target }) => {
        const { formData } = this.state;
        formData[target.name] = target.value;
        this.setState({ formData });
    };

    onInputMultiSelectChange = ({ target }) => {
        const { formData } = this.state;
        formData['GROUPS'] = target.value;
        this.setState({ formData });
    }

    onCheckboxValueChange = ({ target }) => {
        const { formData } = this.state;
        formData[target.name] = !this.state.formData[target.name]
        this.setState({ formData });
    };

    handleOnSubmit = (formData) => {
        if (!!this.props.match.params.id && !this.props.isModal) {
            return this.props.updateUser(this.props.userToken.idToken.jwtToken, formData, this.props.history)
        } else {
            let nextAction = () => this.props.history.push('/users')
            if (this.props.isModal)
                nextAction = this.props.closeModal

            return this.props.addUser(this.props.userToken.idToken.jwtToken, formData, nextAction)
        }
    }

    render() {
        if (!!this.props.UsersReducer.users && !!this.props.UsersReducer.groups)
            return (
                <div>
                    {!!this.props.UsersReducer.groups
                        && <User
                            formData={this.state.formData}
                            initialValues={this.state.formData}
                            onInputValueChange={this.onInputValueChange}
                            onCheckboxValueChange={this.onCheckboxValueChange}
                            title={`${this.state.typeForm} User`}
                            handleOnSubmit={this.handleOnSubmit}
                            optionsGroups={this.props.UsersReducer.groups}
                            goBack={this.goBack}
                            onInputMultiSelectChange={this.onInputMultiSelectChange}
                            type={this.state.typeForm}
                            isModal={this.props.isModal}
                        />}
                </div>
            )
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        UsersReducer: state.UsersReducer
    }
}
export default withRouter(connect(mapStateToProps, { addUser, updateUser, getUsersFromApi, getGroupsFromApi })(UsersForm));
