import React, { Component } from 'react';
import { Button, Grid } from '@material-ui/core';

class NewOrderButtons extends Component {
    render() {
        const { handleClick } = this.props;
        return (
            <div style={{ marginBottom: 20, marginLeft: 35 }}>
                <Grid container >
                    <Grid container item >
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            onClick={(e) => handleClick('stock')}
                        >
                            Order for Stock
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default (NewOrderButtons);