import React, { Component } from 'react'
import { withStyles, Paper, Grid, Typography, FormControlLabel, Radio, FormLabel, Button, MenuItem } from "@material-ui/core"
import { Field, reduxForm, Form, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import { renderTextField, renderSelectField, renderRadioButtonGroup } from './CommonInputs'
import { required, email, requiredNumber } from './validator'
import { orderBy } from '../../services'
import './responsive.css'

const styles = theme => ({
  boxCard: {
    padding: '20px',
    boxSizing: 'border-box'
  },
  selectContainer: {
    borderBottom: '1px dashed #9f9f9f',
    paddingBottom: '20px',
    marginBottom: '15px'
  },
  textField: {
    marginTop: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: '100%',
  },
  radioButtonContainer: {
    marginTop: '15px',
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  },
  radioButton: {
    flexDirection: 'row',
    width: 'auto',
  },
  textField_selectsmall: {
    marginTop: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: '96%',
  }, position: {
    marginTop: 10
  }
})

class SelectUserFormView extends Component {

  render() {
    const {
      classes,
      optionsCountry,
      custemersData,
      handleOnSubmit,
      handleSubmit,
    } = this.props;
    return (
      <Paper className={classes.boxCard}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid container className={classes.selectContainer} >
            <Grid container item xs={12} sm={2}>
              <Typography gutterBottom variant="h5" component="h2">
                Select a Customer Company:
                </Typography>
            </Grid>
            <Grid container item xs={12} sm={10}>
              <Field
                component={renderSelectField}
                // validate={[required]}
                className={classes.textField_selectsmall}
                name="ID"
              >
                <MenuItem key={0} value={0}>New customer</MenuItem>
                {!!custemersData
                  && custemersData.map((option, index) =>
                    (<MenuItem key={index + 1} value={option.ID}>
                      {option.COMPANY}
                    </MenuItem>))}
              </Field>
            </Grid>
          </Grid >
          <Grid container>
            <Grid container alignContent="flex-start" item xs={12} sm={4} >
              <Typography gutterBottom variant="h5" component="h2" className={classes.position}>
                Information
                </Typography>
              <Field
                name="COMPANY"
                label="Company"
                component={renderTextField}
                type="text"
                validate={[required]}
                className={classes.textField}
              />
              <Field
                name="SALUTATION"
                label="Salutation"
                component={renderTextField}
                type="text"
                className={classes.textField} />
              <Field
                name="FIRSTNAME"
                label="First Name"
                component={renderTextField}
                type="text"
                validate={[required]}
                className={classes.textField} />
              <Field
                name="LASTNAME"
                label="Last Name"
                component={renderTextField}
                type="text"
                validate={[required]}
                className={classes.textField} />
              <Field
                name="JOBTITLE"
                label="Job Title"
                component={renderTextField}
                type="text"
                className={classes.textField} />
              <div className={classes.radioButtonContainer}>
                <FormLabel component="legend">Role</FormLabel>
                <Field name="ROLE" className={classes.textField} component={renderRadioButtonGroup}>
                  <FormControlLabel value="Contracts" control={<Radio color="primary" />} label="Contracts" />
                  <FormControlLabel value="Billing" control={<Radio color="primary" />} label="Billing" />
                </Field>
              </div>
            </Grid>
            <Grid container alignContent="flex-start" xs={12} sm={4}>
              <Typography gutterBottom variant="h5" component="h2" className={classes.position}>
                Address
                </Typography>
              <Field
                name="ADDRESS1"
                label="Address"
                component={renderTextField}
                type="text"
                validate={[required]}
                className={classes.textField} />
              <Field
                name="CITY_STATEPROVINCE"
                label="City"
                component={renderTextField}
                type="text"
                validate={[required]}
                className={classes.textField} />
              <Field
                name="POSTALCODE"
                label="Postal Code"
                component={renderTextField}
                type="text"
                className={classes.textField} />
              <Field
                name="COUNTRYID"
                label="Country"
                component={renderSelectField}
                validate={[requiredNumber]}
                className={classes.textField}
              >
                {orderBy(optionsCountry, "DISPLAY")}
                <MenuItem></MenuItem>
                {optionsCountry.map((option, index) => (
                  <MenuItem key={index} value={option.ID}>
                    {option.DISPLAY}
                  </MenuItem>
                ))}
              </Field>
              <div className={classes.radioButtonContainer}>
                <FormLabel component="legend">Location Type</FormLabel>
                <Field name="LOCATIONTYPE" component={renderRadioButtonGroup}
                  className={classes.textField}>
                  <FormControlLabel value="Commercial" control={<Radio color="primary" />} label="Commercial" />
                  <FormControlLabel value="Residential" control={<Radio color="primary" />} label="Residential" />
                </Field>
              </div>
            </Grid>
            <Grid container alignContent="flex-start" item xs={12} sm={4} >
              <Typography gutterBottom variant="h5" component="h2" className={classes.position}>
                Contact
                </Typography>
              <Field
                name="PHONE"
                label="Phone"
                component={renderTextField}
                type="text"
                validate={[required]}
                className={classes.textField} />
              <Field
                name="FAX"
                label="Fax"
                component={renderTextField}
                type="text"
                className={classes.textField} />
              <Field
                name="EMAIL"
                label="Email"
                component={renderTextField}
                type="text"
                validate={[required, email]}
                className={classes.textField} />
              <Field
                name="WEBSITE"
                label="Website"
                component={renderTextField}
                type="text"
                className={classes.textField} />
            </Grid>
          </Grid>
          <center >
            <Button type="submit" variant="contained" color="primary" disabled={this.props.isReportSale && !this.props.keysLoaded}>
              Next
              </Button>
          </center>
        </Form>
      </Paper >
    );
  }
}

const getDatasFromUserSelected = (newIdCustomer, customers) => {
  return customers.find(item =>
    item.ID === Number(newIdCustomer))
}

SelectUserFormView = withStyles(styles)(reduxForm({
  form: 'pagination',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false
})(SelectUserFormView))

const selector = formValueSelector('pagination')
SelectUserFormView = connect(
  state => {
    let newIdCustomer = selector(state, 'ID')
    let initialValues = state.CustomersReducer.customers !== null && getDatasFromUserSelected(newIdCustomer, state.CustomersReducer.customers)
    return {
      initialValues
    }
  })(SelectUserFormView)

export default SelectUserFormView