import React, { Component } from 'react';
import { withStyles, Card, TextField, Button, Grid, CardMedia, CardContent, Typography, FormControlLabel, Checkbox, Link } from "@material-ui/core";
import logo from '../resources/Images/gemvision_authorized_dealer_icon.svg';

const styles = theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 'auto',
        height: '100vh'
    },
    loginCard: {
        width: 420
    },
    content: {
        padding: "2rem !important"
    },
    textField: {
        width: "100%",
        marginTop: 0,
        marginBottom: "20px"
    },
    media: {
        height: 80,
        backgroundColor: "#36363c",
        backgroundSize: "120px"
    },
    forgotPassword: {
        textDecoration: 'none',
        cursor: 'pointer',
        marginTop: '1rem',
        fontSize: 14
    }
});


class LoginComponent extends Component {

    render() {
        const {
            onInputValueChange,
            handleSubmit,
            userName,
            password,
            isRemember,
            onCheckboxValueChange,
            history,
            isButtonDisabled
        } = this.props;
        const { classes } = this.props;
        return (
            <div className={classes.wrapper}>
                <Card className={classes.loginCard}>
                    <CardMedia
                        className={classes.media}
                        image={logo}
                        title="Contemplative Reptile"
                    />
                    <CardContent className={classes.content}>
                        <Typography gutterBottom variant="h5" component="h2">
                            Login
                        </Typography>
                        <form className="commentForm" onSubmit={e => {
                            e.preventDefault();
                            handleSubmit({ userName, password })
                        }}>
                            <TextField
                                id="standard-username-input"
                                name="userName"
                                label="User name"
                                value={userName}
                                className={classes.textField}
                                type="text"
                                autoComplete="current-password"
                                margin="normal"
                                onChange={onInputValueChange}
                            />
                            <TextField
                                id="standard-password-input"
                                label="Password"
                                name="password"
                                value={password}
                                className={classes.textField}
                                type="password"
                                autoComplete="current-password"
                                margin="normal"
                                onChange={onInputValueChange}
                            />
                            <FormControlLabel control={
                                <Checkbox
                                    name="isRemember"
                                    checked={isRemember}
                                    onChange={onCheckboxValueChange}
                                    color="primary" />
                            } label="Remember me" />
                            <Grid container >
                                <Grid container item xs={12} justifyContent="center">
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        type="submit"
                                        style={{ padding: '12px 22px' }}
                                        disabled={isButtonDisabled}
                                    >
                                        Log In
                                </Button>
                                </Grid>
                                <Grid container item xs={12} justifyContent="center">
                                    <Link onClick={() => history.push("/forgetpassword")} className={classes.forgotPassword}>Forgot username or password?</Link>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </div >
        );
    }
}

export default withStyles(styles)(LoginComponent);