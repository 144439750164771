import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Form, } from 'redux-form'
import { Grid, Paper, TextField, Typography, Button, Container } from '@material-ui/core';

import ReviewReportView from '../../../components/Common/ReviewReportView'
import TableReportViewSales from '../../../components/ReportSale/TableReportViewSales'

class ReviewReport extends Component {

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.props.calcTotalPrice(nextProps)
    }

    render() {
        const { notes, totalPrice, onInputValueChange, handleSubmit, handleOnSubmit, previousPage, submitting } = this.props
        return (
            <Container>
                <Form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container>
                        <Grid item container xs={12}>
                            {(!!this.props.ReportSaleReducer.userData && !!this.props.ReportSaleReducer.productsSelected)
                                && <div style={{ width: '100%' }}>
                                    <ReviewReportView userData={this.props.ReportSaleReducer.userData} showNoteToSaleReport={true} />
                                    <TableReportViewSales productsSelected={this.props.ReportSaleReducer.productsSelected} totalPrice={totalPrice} />
                                </div>}
                        </Grid>
                        <Grid item container xs={12} style={{ paddingTop: 20 }}>
                            <Paper style={{ padding: '10px', width: '100%' }}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Notes
                                </Typography>
                                <TextField
                                    id="outlined-textarea"
                                    name="notes"
                                    multiline
                                    rows="10"
                                    value={notes}
                                    onChange={onInputValueChange}
                                    margin="normal"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    disabled={submitting}
                                />
                            </Paper>
                        </Grid>
                    </Grid >
                    <div>
                        <center style={{ marginTop: 10 }}>
                            <Button type="button" onClick={previousPage} disabled={submitting}>
                                Previous
                            </Button>
                            <Button type="submit" disabled={submitting} variant="contained" color="primary">
                                Submit
                            </Button>
                        </center>
                    </div>
                </Form>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        ReportSaleReducer: state.ReportSaleReducer
    }
}

export default withRouter(connect(mapStateToProps)(reduxForm({
    form: 'SaleReviewReportForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
})(ReviewReport)))