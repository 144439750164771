import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SelectUserFormView from '../../../components/Forms/SelectUserFormView';
import { getCountry } from '../../../services'
import { addCustomer, updateCustomer } from '../../../actions';

class SelectUserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userChanged: '',
            typeForm: 'Add',
            optionsCountry: [{}],
        }
    }

    componentDidMount() {
        getCountry(this.props.userToken.idToken.jwtToken).then((result) => {
            this.setState({ optionsCountry: result });
        });
        this.setState({ userChanged: false });
    }

    componentDidUpdate = () => {
        if (!!this.props.form.values) {
            this.sendUserData();
        }
    }

    handleOnSubmit = (data, _, p) => {
        this.sendUserData(!p.pristine);
        this.props.onSubmit();
    }


    sendUserData = (commitChanges) => {
        if (commitChanges === undefined) {
            commitChanges = this.state.userChanged
        }
        this.props.actionToSaveUserData(this.props.form.values, commitChanges, this.props.DealerInfo.data.DealerID)
    }

    render() {
        return (
            <SelectUserFormView
                optionsCountry={this.state.optionsCountry}
                custemersData={this.props.CustomersReducer.customers}
                keysLoaded={!!this.props.KeysReducer}
                handleOnSubmit={this.handleOnSubmit}
                isReportSale={this.props.isReportSale}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        KeysReducer: state.KeysReducer.keys,
        userToken: state.Auth.signedInUser.signInUserSession,
        CustomersReducer: state.CustomersReducer,
        DealerInfo: state.DealerInfo,
        form: state.form.pagination
    }
}

export default withRouter(connect(mapStateToProps, { addCustomer, updateCustomer })(SelectUserForm));