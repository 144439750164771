import React from 'react'
import PropTypes from "prop-types"
import { makeStyles, Container, Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid } from '@material-ui/core'
import NumberFormat from 'react-number-format'
var dateFormat = require('dateformat');

const useStyles = makeStyles({
    tableWrapper: {
        overflow: 'auto',
    }, tablecell: {
        minWidth: 120,
        align: 'right',
        padding: '5px'
    }
});

const HomeComponent = (props) => {
    const classes = useStyles();
    const { data, columns, hasError, viewMode } = props;
    if (hasError)
        return <h1>Something went wrong.</h1>;
    return (
        <React.Fragment>
            <Container>
                <Grid container >
                    <Grid item sm={12} xs={12}>
                        <h3>Recent Orders</h3>
                        <Paper className={classes.tableWrapper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        {columns.dataOfOrders && columns.columnsOfOrders.map((row, index) => (
                                            <TableCell className={classes.tablecell} key={index}>{row.Header}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.dataOfOrders && data.dataOfOrders.map((row, index) => {
                                        return <TableRow key={index}>
                                            <TableCell className={classes.tablecell}>{dateFormat(row.Date, "fullDate")}</TableCell>
                                            <TableCell className={classes.tablecell}>{row.COMPANY}</TableCell>
                                            <TableCell className={classes.tablecell}><NumberFormat value={row.TOTAL} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></TableCell>
                                            {viewMode === 'Admin' ? <TableCell className={classes.tablecell}>{row.Dealer}</TableCell> : null}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <h3>Recent Sales</h3>
                <Grid container >
                    <Grid item sm={12} xs={12}>
                        <Paper className={classes.tableWrapper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        {columns.columnsOfSales && columns.columnsOfSales.map((row, index) => (
                                            <TableCell className={classes.tablecell} key={index}>{row.Header}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.dataOfSales && data.dataOfSales.map((row, index) => {
                                        return <TableRow key={index}>
                                            <TableCell className={classes.tablecell}>{dateFormat(row.Date, "fullDate")}</TableCell>
                                            <TableCell className={classes.tablecell}>{`${row.FIRSTNAME} ${row.LASTNAME}`}</TableCell>
                                            <TableCell className={classes.tablecell}><NumberFormat value={row['Total Cost']} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></TableCell>
                                            {viewMode === 'Admin' ? <TableCell className={classes.tablecell} >{row.COMPANY}</TableCell> : null}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

HomeComponent.propTypes = {
    onInputValueChange: PropTypes.func
};
export default HomeComponent