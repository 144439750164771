import {
    USER_DATA_FROM_PLACE_ORDER,
    PRODUCTS_SELECTED_FROM_PLACE_ORDER,
    RESET_STATE_FROM_PLACE_ORDER,
    RESET_PRODUCT_STATE_FROM_PLACE_ORDER,
    SUBMIT_PLACE_ORDER_ERROR,
} from '../actions'

export default function PlaceOrderReducer(state = { userData: null, productsSelected: null, userChanged: null }, action) {
    switch (action.type) {
        case USER_DATA_FROM_PLACE_ORDER:
            return { ...state, userData: action.payload.userData, userChanged: action.payload.userChanged }
        case PRODUCTS_SELECTED_FROM_PLACE_ORDER:
            return { ...state, productsSelected: action.payload }
        case SUBMIT_PLACE_ORDER_ERROR:
            return { ...state, error: action.payload }
        case RESET_STATE_FROM_PLACE_ORDER:
            return { ...state, userData: null, productsSelected: null, userChanged: null }
        case RESET_PRODUCT_STATE_FROM_PLACE_ORDER:
            return { ...state, productsSelected: null }
        default: return state
    }
}