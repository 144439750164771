import axios from 'axios'
import { RESET } from '../actions'
import { BASE_URL } from '../settings.js'
import { showNotification } from '../services/notification'

export const USER_DATA_FROM_PLACE_ORDER = "USER_DATA_FROM_PLACE_ORDER";
export const PRODUCTS_SELECTED_FROM_PLACE_ORDER = "PRODUCTS_SELECTED_FROM_PLACE_ORDER";
export const RESET_STATE_FROM_PLACE_ORDER = "RESET_STATE_FROM_PLACE_ORDER";
export const RESET_PRODUCT_STATE_FROM_PLACE_ORDER = 'RESET_PRODUCT_STATE_FROM_PLACE_ORDER'
export const SUBMIT_PLACE_ORDER = 'SUBMIT_PLACE_ORDER'
export const SUBMIT_PLACE_ORDER_ERROR = 'SUBMIT_PLACE_ORDER_ERROR'

let url = `${BASE_URL}/placeorder`

export const insertUserFromPlaceOrder = (userData, userChanged, DEALERID) => {
  return dispatch => {
    if (!userData['DEALERID']) {
      userData['DEALERID'] = DEALERID
    }
    dispatch({
      type: USER_DATA_FROM_PLACE_ORDER,
      payload: { userData, userChanged }
    })
  }
}

export const saveProductsSelectedFromPlaceOrder = (products) => {
  return dispatch => {
    dispatch({
      type: PRODUCTS_SELECTED_FROM_PLACE_ORDER,
      payload: products
    })
  }
}

export const submitOrderReport = (token, orderData, history) => {
  return async (dispatch) => {
    var header = { 'Authorization': token }
    await axios.post(url, orderData, { headers: header })
      .then((response) => {
        let config = {
          icon: 'check',
          type: 'success',
          message: 'The Order has been created successfully',
          autoClose: true
        }
        showNotification(config);
        history.push('/orders')
        dispatch({
          type: RESET
        })
      })
      .catch((error) => {
        let config = {
          icon: 'error',
          type: 'error',
          message: 'An error has occurred with the Order process. Please, try it again',
          autoClose: true
        }
        showNotification(config);
        history.push('/home')
        dispatch({
          type: SUBMIT_PLACE_ORDER_ERROR,
          payload: error
        })
      })
  }
}

export const resetStateOfPlaceOrder = () => {
  return dispatch => {
    dispatch({
      type: RESET_STATE_FROM_PLACE_ORDER
    })
  }
}

export const resetProductStateOfPlaceOrder = () => {
  return dispatch => {
    dispatch({
      type: RESET_PRODUCT_STATE_FROM_PLACE_ORDER
    })
  }
}