import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { CSVLink } from "react-csv";
import { withStyles } from '@material-ui/core/styles';
import iconExcel from '../resources/Images/icons8-microsoft-excel.svg';
import { getCountry } from '../services'

const styles = theme => ({
    button: {
        textDecoration: 'none',
        display: 'contents'
    },
    icon: {
        width: 20,
        marginLeft: 4
    }
});

class ButtonDownload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            countries: []
        }
        getCountry('').then((result) => {
            this.setState({ countries: result })
        })
    }
    editDataToDownload = (data, columns) => {
        let objectWithParamsThanWeNeed = {}
        let newArray = []

        if (columns)
            columns.forEach((colum) => objectWithParamsThanWeNeed[colum.id] = colum.id)

        data.forEach((item, index) => {
            for (let element in objectWithParamsThanWeNeed) {
                if (element === "Name") {
                    if (item.contactData) {
                        const contact = item.contactData.find(el => el.isMain === 1);
                        newArray[index] = { ...newArray[index], 'NAME': (`${contact['FIRSTNAME']} ${contact['LASTNAME']}`) }
                    } else
                        newArray[index] = { ...newArray[index], 'NAME': (`${item['FIRSTNAME']} ${item['LASTNAME']}`) }
                }
                else if (element === "AvailableOn")
                    newArray[index] = {
                        ...newArray[index],
                        'USAVAILABLE': item['USAVAILABLE'],
                        'UKAVAILABLE': item['UKAVAILABLE'],
                        'AUAVAILABLE': item['AUAVAILABLE'],
                        'ELSEAVAILABLE': item['ELSEAVAILABLE'],
                        'AVAILABLEFORSTOCK': item['AVAILABLEFORSTOCK'],
                        'ISKEY': item['ISKEY'],
                        'VERSION': item['VERSION']
                    }
                else if (element === 'DEALERCOMPANY')
                    newArray[index] = { ...newArray[index], 'DEALER': item['COMPANY'] }
                else if (element === 'COMPANY')
                    newArray[index] = { ...newArray[index], 'CUSTOMER': item['COMPANY'] }
                else if (element === 'PRODUCTS') {
                    if (item['OS_ITEM']) {
                        let osItems = ''
                        for (let os of item['OS_ITEM']) {
                            if (os['QUANTITY']) {
                                osItems += `(${os['QUANTITY']}) ${os['TITLE']} | `
                            } else {
                                osItems += `${os['TITLE']} | `
                            }
                        }
                        newArray[index] = { ...newArray[index], 'PRODUCTS': osItems }
                    } else {
                        newArray[index] = { ...newArray[index], 'PRODUCTS': item['PRODUCTS'] }
                    }
                }
                else if (element === 'COUNTRYID') {
                    let countryDisplay = this.state.countries.find((country) => country.ID === item['COUNTRYID'])
                    newArray[index] = { ...newArray[index], 'COUNTRY': (countryDisplay) ? countryDisplay.DISPLAY : item['COUNTRYID'] }
                }
                else if (item.hasOwnProperty(element)) {
                    if (item[element] === null)
                        newArray[index] = { ...newArray[index], [element.toUpperCase()]: 0 }
                    newArray[index] = { ...newArray[index], [element.toUpperCase()]: item[element] }
                }
            }
        })
        return newArray
    }

    render() {
        const { classes, data, columns, fileName } = this.props;
        const fileWithSameDataOfTable = this.editDataToDownload(data, columns)
        return (
            <div>
                <CSVLink data={fileWithSameDataOfTable} className={classes.button} filename={`${fileName}.csv`} separator={";"}>
                    <Button
                        variant="contained"
                        color="default"
                        size="small">
                        Download
                        <img src={iconExcel} className={classes.icon} size="tidy" alt="icon export" />
                    </Button>
                </CSVLink>
            </div>
        );
    }
}

export default withStyles(styles)(ButtonDownload);