import React, { Component } from 'react';
import { connect } from 'react-redux';

import Generic from '../../components/Generic';
import { getInventoriesFromApi, getKeysFromApi } from '../../actions';
import Spinner from '../../components/Spinner';

var dateFormat = require('dateformat')

class Inventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            search: '',
            configPage: {
                title: 'Inventory',
                buttonDownload: true,
                downloadName: 'Inventory',
                buttonAddNew: false,
            }
        }
    }

    componentDidMount() {
        this.setState({ configPage: { ...this.state.configPage, buttonAddNew: this.props.viewMode !== 'Dealers' } });
        if (!this.props.InventoriesReducer.fields) {
            this.props.getInventoriesFromApi(this.props.userToken.idToken.jwtToken, this.props.viewMode === 'Dealers' ? this.props.userId : null)
                .catch(() => {
                    this.props.getInventoriesFromApi(this.props.userToken.idToken.jwtToken, this.props.viewMode === 'Dealers' ? this.props.userId : null)
                        .catch(() => this.setState({ hasError: true }))
                })
        } else {
            this.makeColumns(this.props.InventoriesReducer.fields)
            this.makeData(this.props.InventoriesReducer.inventories)
        }
        if (!this.props.KeysReducer.fields || !this.props.KeysReducer.keys) {
            this.props.getKeysFromApi(this.props.userToken.idToken.jwtToken)
                .catch(() => {
                    this.props.getKeysFromApi(this.props.userToken.idToken.jwtToken)
                        .catch(() => this.setState({ hasError: true }))
                })
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ buttonAddNew: nextProps.viewMode !== 'Dealers' });
        if (!nextProps.InventoriesReducer.fields) {
            nextProps.getInventoriesFromApi(nextProps.userToken.idToken.jwtToken, nextProps.viewMode === 'Dealers' ? nextProps.userId : null).catch(() => this.setState({ hasError: true }))
        }
        if (!!nextProps.InventoriesReducer.fields) {
            this.makeColumns(nextProps.InventoriesReducer.fields)
            this.makeData(nextProps.InventoriesReducer.inventories)
        }
    }

    editItem(id) {
        this.props.history.push(`${this.state.configPage.title.toLowerCase()}/form/${id}`);
    }

    makeColumns(inventories) {
        let arrayOfItems = []
        inventories.forEach((item) => {
            switch (item) {
                case 'TITLE': arrayOfItems.push({ Header: 'Product', accessor: item, id: item, minWidth: 220 }); break;
                case 'QUANTITY': arrayOfItems.push({ Header: 'Quantity', accessor: item, id: item, }); break;
                case 'COMPANY':
                    if (this.props.viewMode !== 'Dealers')
                        arrayOfItems.push({ Header: 'Dealer', accessor: item, id: 'DEALERCOMPANY', minWidth: 220 });
                    break;
                case 'DATEADDED':
                    arrayOfItems.push({
                        Header: 'Date Added', id: item, accessor: d =>
                            <div>{dateFormat(d.DATEADDED, "fullDate")}</div>
                        , minWidth: 220
                    });
                    break;
                case 'LABEL': arrayOfItems.push({ Header: 'Product type', accessor: item, id: item, minWidth: 220 }); break;
                default: break;
            }
        })
        this.setState({ columns: arrayOfItems })
    }

    makeData(dataOfInventories) {
        this.setState({ content: dataOfInventories })
    }

    onInputValueChange = ({ target }) => {
        this.setState({ search: target.value.toLowerCase() })
    }

    render() {
        if (!!this.props.InventoriesReducer.fields && !!this.props.KeysReducer.fields) {
            let data = this.props.InventoriesReducer.inventories
            if (this.state.search) {
                data = data.filter(row => {
                    const array = Object.keys(row)
                    let isIncluded = false
                    array.forEach((columnName) => {
                        if (!isIncluded) {
                            if (typeof row[columnName] === 'string') {
                                if ((row[columnName].toLowerCase()).includes(this.state.search.toLowerCase())) {
                                    isIncluded = true
                                }
                            }
                            else if ((String(row[columnName]).toLowerCase()).includes(this.state.search.toLowerCase())) {
                                isIncluded = true
                            }
                        }
                    })
                    if (isIncluded)
                        return true
                    return false;
                })
            }
            return (
                <div>
                    <Generic
                        data={data}
                        columns={this.state.columns}
                        onInputValueChange={this.onInputValueChange}
                        hasError={this.state.hasError}
                        configPage={this.state.configPage}
                        history={this.props.history} />
                </div>
            );
        } else if (this.state.hasError)
            return <Generic hasError={this.state.hasError} />
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        InventoriesReducer: state.InventoriesReducer,
        viewMode: state.AppReducer.currentViewMode,
        userId: state.Auth.id,
        KeysReducer: state.KeysReducer
    }
}

export default connect(mapStateToProps, { getInventoriesFromApi, getKeysFromApi })(Inventory); 