
export const required = value => !value || !value.length ? 'This field is required' : undefined;

export const requiredNumber = value => !value || value.isNaN ? 'A number is required' : undefined;

export const email = value =>
    value && !/^\s*\w*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*\w*$/i.test(value) ?
        'Invalid email' : undefined;

export const phoneNumber = value =>
    value && !/^(0|[1-9][0-9]{8})$/i.test(value)
        ? 'Invalid phone number, must be 9 digits'
        : undefined

export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined

export const minLength = min => value =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined

export const validationHandler = (value, itemList) => {
    let contador = 0
    if (itemList.addKey) {
        itemList.addKey.forEach(element => {
            if (value === element) {
                contador++
            }
        })
    } else {
        itemList.keySetted.forEach(element => {
            if (value === element) {
                contador++
            }
        })
    }
    if (contador > 1) {
        return "there are repeated keys"
    } else {
        return undefined
    }
}