import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Products from '../../components/Forms/Product';
import { addProduct, updateProduct, getProductsFromApi, getProductType } from '../../actions';
import Spinner from '../../components/Spinner';
import { showNotification } from '../../services/notification';

class ProductsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                TITLE: '',
                PARTNUMBER: '',
                PRICE: 0,
                PRODUCTTYPE: '',
                VERSION: '',
                ISKEY: false,
                AVAILABLEFORSTOCK: false,
                USAVAILABLE: false,
                UKAVAILABLE: false,
                AUAVAILABLE: false,
                ELSEAVAILABLE: false,
                OPTIONS: 'none'
            },
            typeForm: 'Add',
            optionsProductType: [{}],
            image: '',
        };
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        if (this.props.products === null) {
            this.props.getProductsFromApi(this.props.userToken.idToken.jwtToken)
                .catch(() => this.props.getProductsFromApi(this.props.userToken.idToken.jwtToken)
                    .catch(() => this.setState({ hasError: true })))
        } else if (this.props.match.params.id) {
            this.getInitialState(this.props.match.params.id, this.props.products)
        }
        if (this.props.productsList.length === 0) {
            this.props.getProductType(this.props.userToken.idToken.jwtToken)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!this.props.match.params.id && nextProps.products !== null)
            this.getInitialState(this.props.match.params.id, nextProps.products)
    }

    getInitialState = (id, productsReducer) => {
        if (productsReducer) {
            productsReducer.forEach((element) => {
                if (element.ID === Number(id)) {
                    const { formData } = this.state;
                    for (var aux in this.state.formData) {
                        formData[aux] = element[aux];
                    }
                    if (element.IMAGEPATH !== null) {
                        let s3Bucket = process.env.REACT_APP_current_stage === 'prod' ? process.env.REACT_APP_s3_bucket_prod : process.env.REACT_APP_s3_bucket_dev
                        this.setState({
                            formData,
                            typeForm: 'Edit',
                            image: `https://s3.amazonaws.com/${s3Bucket}${element.IMAGEPATH}`
                        });
                    }
                }
            })
        }
    };

    goBack() {
        this.props.history.goBack();
    }

    handleOnSubmit = (formData) => {
        formData = {
            ...formData,
            USAVAILABLE: (formData.USAVAILABLE) ? 1 : 0,
            UKAVAILABLE: (formData.UKAVAILABLE) ? 1 : 0,
            AUAVAILABLE: (formData.AUAVAILABLE) ? 1 : 0,
            ELSEAVAILABLE: (formData.ELSEAVAILABLE) ? 1 : 0,
            ISKEY: (formData.ISKEY) ? 1 : 0,
            AVAILABLEFORSTOCK: (formData.AVAILABLEFORSTOCK) ? 1 : 0,
        }

        if (this.props.match.params.id) {
            return this.props.updateProduct(this.props.userToken.idToken.jwtToken, this.props.match.params.id, formData, this.props.history)
        } else {
            return this.props.addProduct(this.props.userToken.idToken.jwtToken, formData, this.props.history)
        }
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length > 0) {
            const myFileItemReader = new FileReader()
            let formData = { ...this.state.formData }
            let file = URL.createObjectURL(acceptedFiles[0])
            myFileItemReader.addEventListener("load", () => {
                formData.IMAGE = myFileItemReader.result
            }, false)
            myFileItemReader.readAsDataURL(acceptedFiles[0])
            formData.NAMEIMAGE = acceptedFiles[0].name
            this.setState({ image: file, formData: formData })
            return true;
        } else {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'Unsupported file type',
                autoClose: true,
            }
            showNotification(config);
            return false;
        }
    }

    render() {
        if (!!this.props.products)
            return (
                <Products
                    formData={this.state.formData}
                    initialValues={this.state.formData}
                    title={`${this.state.typeForm} Product`}
                    handleOnSubmit={this.handleOnSubmit}
                    optionsProductType={this.props.productsList}
                    goBack={this.goBack}
                    onDrop={this.onDrop}
                    image={this.state.image}
                />
            );
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        products: state.ProductsReducer.products,
        productsList: state.ProductTypeReducer.productsList
    }
}

export default withRouter(connect(mapStateToProps, { addProduct, updateProduct, getProductsFromApi, getProductType })(ProductsForm));