import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from '../actions';
import ResetPasswordComponent from '../components/ResetPassword';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router-dom';

class ResetPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                email: '',
                validationCode: '',
                password: '',
                repeatPassword: ''
            },
            byURL: false,
            isButtonDisabled: false,
        }

        if (this.props.match.params.email)
            this.state = { ...this.state, byURL: true, formData: { ...this.state.formData, email: this.props.match.params.email } }

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.formData.password) {
                return false;
            }
            return true;
        });
    }

    onInputValueChange = ({ target }) => {
        const { formData } = this.state;
        formData[target.name] = target.value;
        this.setState({ formData });
    };

    handleSubmit = (formData) => {
        this.setState({ isButtonDisabled: true })
        this.props.resetPassword(this.state.formData.email, formData.password, formData.validationCode)
            .then(() => { this.props.history.push('/') })
            .catch(() => { this.setState({ isButtonDisabled: false }) })
    }

    render() {
        return (
            <ResetPasswordComponent
                history={this.props.history}
                formData={this.state.formData}
                byURL={this.state.byURL}
                onInputValueChange={this.onInputValueChange}
                handleSubmit={this.handleSubmit}
                isButtonDisabled={this.state.isButtonDisabled}
            />
        );
    }
}

export default withRouter(connect(null, { resetPassword })(ResetPassword));