import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Dealers from '../../components/Forms/Dealers'
import { getUser, getCountry, foundIdOfProductType } from '../../services'
import { addDealer, updateDealer, getDealersFromApi, getProductType } from '../../actions'
import Spinner from '../../components/Spinner'

class DealersForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                COGNITO_ID: '',
                COMPANY: '',
                MANAGERCODE: '',
                ROLE: '',
                CountriesName: [],
                ProductListName: [],
                LOCATIONTYPE: '',
                FEDEXNUMBER: '',
                hasMultiCountriesWithDifferentsAddresses: false,
                contactData: [],
                isMain: ''
            },
            CountriesID: [],
            typeForm: 'Add',
            optionsUser: [{}],
            optionsCountry: [{}],
            indexMainData: 0
        }
    }

    componentDidMount() {
        if (this.props.productsList.length === 0) {
            this.props.getProductType(this.props.userToken.idToken.jwtToken)
        }
        if (this.props.dealers === null) {
            this.props.getDealersFromApi(this.props.userToken.idToken.jwtToken).then(() => {
                if (this.props.match.params.id) {
                    this.getInitialState(this.props.match.params.id, this.props.dealers)
                }
            })
        }
        else {
            if (this.props.match.params.id) {
                this.getInitialState(this.props.match.params.id, this.props.dealers)
            }
        }

        getUser(this.props.userToken.idToken.jwtToken).then((result) => {
            this.setState({ optionsUser: result });
        });
        getCountry(this.props.userToken.idToken.jwtToken).then((result) => {
            this.setState({ optionsCountry: result });
        });
    }

    getInitialState = (id, dealersReducer) => {
        if (dealersReducer) {
            dealersReducer.forEach((element) => {
                if (element.DealerID === Number(id)) {
                    for (var aux in this.state.formData) {
                        const { formData } = this.state
                        switch (aux) {
                            case 'ProductListName':
                                if (element['ProductList'])
                                    formData['ProductListName'] = element['ProductList'].split(',')
                                break
                            case 'CountriesName':
                                formData['CountriesName'] = element.contactData.map(data => data.CountryName)
                                break
                            case 'hasMultiCountriesWithDifferentsAddresses':
                                let indexMainData = element.contactData.findIndex(item => !!item.isMain)
                                formData['hasMultiCountriesWithDifferentsAddresses'] = element.contactData.find(item =>
                                    item.ContactID !== element.contactData[indexMainData].ContactID)
                                    ? true
                                    : false
                                this.setState({ indexMainData })
                                break
                            case 'contactData':
                                formData['contactData'] = element.contactData.map(item => {
                                    return {
                                        ContactID: item.ContactID,
                                        SALUTATION: item.SALUTATION,
                                        FIRSTNAME: item.FIRSTNAME,
                                        LASTNAME: item.LASTNAME,
                                        JOBTITLE: item.JOBTITLE,
                                        ADDRESS1: item.ADDRESS1,
                                        CITY_STATEPROVINCE: item.CITY_STATEPROVINCE,
                                        STATE: item.STATE,
                                        POSTALCODE: item.POSTALCODE,
                                        PHONE: item.PHONE,
                                        FAX: item.FAX,
                                        EMAIL: item.EMAIL,
                                        WEBSITE: item.WEBSITE,
                                        isMain: item.isMain,
                                        CountryID: item.CountryID,
                                        CountryName: item.CountryName
                                    }
                                })
                                break
                            default:
                                formData[aux] = element[aux]
                                break
                        }
                        this.setState({ formData })
                    }

                    this.setState({ typeForm: 'Edit' });
                }
            })
        }
    }

    onChangeMainSelection = (event) => {
        let { indexMainData, formData } = this.state
        formData.contactData = formData.contactData.map((item, index) => {
            item.isMain = item.CountryName === event.target.value ? 1 : 0
            if (!!item.isMain) indexMainData = index
            return item
        })
        this.setState({ indexMainData, formData })
    }

    onChangeCountries = (newValue, previousValue) => {
        let { formData } = this.state
        formData.CountriesName = newValue
        let startIndex = newValue.length
        let endIndex = 0
        if (newValue.length < previousValue.length) {
            if (previousValue.length > 1) {
                startIndex = previousValue.findIndex(item =>
                    !newValue.includes(item))
                endIndex = 1
                formData.contactData.splice(startIndex, endIndex)
            }
            else {
                formData.contactData[0].CountryName = undefined
            }
        } else if (newValue.length > previousValue.length) {
            if (newValue.length === 1 && formData.contactData.length === 1) {
                formData.contactData[0].CountryName = newValue[0]
            } else {
                let mainContact = formData.contactData[this.state.indexMainData]
                if (mainContact) {
                    formData.contactData.splice(startIndex, endIndex, {
                        ContactID: undefined,
                        SALUTATION: mainContact.SALUTATION,
                        FIRSTNAME: mainContact.FIRSTNAME,
                        LASTNAME: mainContact.LASTNAME,
                        JOBTITLE: mainContact.JOBTITLE,
                        ADDRESS1: mainContact.ADDRESS1,
                        CITY_STATEPROVINCE: mainContact.CITY_STATEPROVINCE,
                        STATE: mainContact.STATE,
                        POSTALCODE: mainContact.POSTALCODE,
                        PHONE: mainContact.PHONE,
                        FAX: mainContact.FAX,
                        EMAIL: mainContact.EMAIL,
                        WEBSITE: mainContact.WEBSITE,
                        isMain: 0,
                        CountryID: undefined,
                        CountryName: newValue[newValue.length - 1]
                    })
                } else {
                    const { realData } = this.props
                    if (realData.values.contactData.length > 0) {
                        formData.contactData = realData.values.contactData
                    }
                    else {
                        formData.contactData[0] = {
                            ContactID: undefined,
                            SALUTATION: undefined,
                            FIRSTNAME: undefined,
                            LASTNAME: undefined,
                            JOBTITLE: undefined,
                            ADDRESS1: undefined,
                            CITY_STATEPROVINCE: undefined,
                            STATE: undefined,
                            POSTALCODE: undefined,
                            PHONE: undefined,
                            FAX: undefined,
                            EMAIL: undefined,
                            WEBSITE: undefined,
                            isMain: 1,
                            CountryID: undefined,
                            CountryName: newValue[newValue.length - 1]
                        }
                    }
                }
            }
        }
        this.setState({ formData: formData })
    }

    goBack = () => {
        this.props.history.goBack()
    }

    handleOnSubmit = (formData) => {
        const { indexMainData } = this.state
        formData.ProductList = foundIdOfProductType(this.props.productsList, formData.ProductListName)

        if (!formData.hasMultiCountriesWithDifferentsAddresses) {
            formData.contactData = formData.contactData.filter((contact, index) => index === indexMainData)
        }
        const contactFields = ["SALUTATION", "FIRSTNAME", "LASTNAME", "JOBTITLE", "ADDRESS1", "CITY_STATEPROVINCE", "STATE", "POSTALCODE", "PHONE", "FAX", "EMAIL", "WEBSITE"]
        formData.contactData.forEach((contact, index) => {
            contactFields.forEach(key => {
                if (contact[key] === undefined) {
                    contact[key] = ""
                }
            })
            contact.isMain = index === indexMainData ? 1 : 0
            contact.CountryID = this.state.optionsCountry.find(country => {
                return country.DISPLAY === formData.CountriesName[index]
            }).ID

        }, formData)

        if (formData.COGNITO_ID === 'userNone') {
            formData.COGNITO_ID = null
        }

        if (!formData.hasMultiCountriesWithDifferentsAddresses) {
            let allCountriesID = formData.CountriesName.map((countryName, index) => {
                return this.state.optionsCountry.find(countryOption => {
                    return countryOption.DISPLAY === countryName
                }).ID
            })

            formData.contactData[0].CountryID = allCountriesID
        }

        if (this.props.match.params.id)
            return this.props.updateDealer(this.props.userToken.idToken.jwtToken, this.props.match.params.id, formData, this.props.history)
        else {
            return this.props.addDealer(this.props.userToken.idToken.jwtToken, formData, this.props.history)
        }
    }

    updateUserOptions = () => {
        getUser(this.props.userToken.idToken.jwtToken).then((result) => {
            this.setState({ optionsUser: result });
        });
    }

    render() {
        if (!!this.props.dealers)
            return (
                <Dealers
                    initialValues={this.state.formData}
                    title={`${this.state.typeForm} Dealer`}
                    handleOnSubmit={this.handleOnSubmit}
                    optionsUser={this.state.optionsUser}
                    optionsCountry={this.state.optionsCountry}
                    goBack={this.goBack}
                    productsList={this.props.productsList}
                    updateUserOptions={this.updateUserOptions}
                    indexMainData={this.state.indexMainData}
                    onChangeMainSelection={this.onChangeMainSelection}
                    onChangeCountries={this.onChangeCountries}
                />
            )
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        dealers: state.DealersReducer.dealers,
        productsList: state.ProductTypeReducer.productsList,
        realData: state.form.dealers
    }
}
export default withRouter(connect(mapStateToProps, { addDealer, updateDealer, getDealersFromApi, getProductType })(DealersForm))