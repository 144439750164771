import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signInUser, inputHasChanged } from '../actions';
import { AuthKeys } from '../actions/types';
import LoginComponent from '../components/Login'

let store = require('store/storages/sessionStorage');

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userName: '',
            password: '',
            isRemember: false,
            isButtonDisabled: false,
        }

        if (store.read('userName')) {
            this.state = {
                ...this.state,
                userName: store.read('userName'),
                isRemember: true
            }
        }
    }

    onInputValueChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };

    onCheckboxValueChange = () => {
        this.setState({ isRemember: !this.state.isRemember });
    };

    saveUserNameOnStore = () => {
        if (this.state.isRemember) {
            if (this.state.userName !== store.read('userName'))
                store.write('userName', this.state.userName)
        }
        else
            store.remove('userName')
    }

    handleSubmit = ({ userName, password }) => {
        this.setState({ isButtonDisabled: true })
        this.props.inputHasChanged(AuthKeys.SIGN_IN_EMAIL_CHANGED, ['required', 'email'], userName);
        this.props.inputHasChanged(AuthKeys.SIGN_IN_PASSWORD_CHANGED, ['required', 'password'], password);
        this.props.signInUser(userName, password)
            .catch(() => { this.setState({ isButtonDisabled: false }) })
        this.saveUserNameOnStore();
    }

    render() {
        return (
            <LoginComponent
                userName={this.state.userName}
                password={this.state.password}
                isRemember={this.state.isRemember}
                onInputValueChange={this.onInputValueChange}
                onCheckboxValueChange={this.onCheckboxValueChange}
                handleSubmit={this.handleSubmit}
                history={this.props.history}
                isButtonDisabled={this.state.isButtonDisabled}
            />
        );
    }
}


export default connect(null, { inputHasChanged, signInUser })(Login);