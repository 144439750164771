import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Router } from 'react-router-dom'
import history from './history'
import { Provider } from 'react-redux'
import Store from './store'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MuiThemeProvider, createTheme  } from '@material-ui/core/styles'
import { cyan } from "@material-ui/core/colors"
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

const theme = createTheme ({
    palette: {
        typography: {
            useNextVariants: true,
        },
        primary: {
            main: cyan[500],
            contrastText: '#fff',
        },
        secondary: {
            main: '#11cb5f'
        }
    },
    overrides: {
        MuiTab: {
            root: {
                '@media (min-width: 767px)': {
                    minWidth: 1, padding: 8
                }, '@media (min-width: 900px)': {
                    minWidth: 1, padding: '6px 12px'
                }, '@media (min-width: 1000px)': {
                    minWidth: 1, padding: '6px 20px'
                }, '@media (min-width: 1200px)': {
                    minWidth: 1, padding: '6px 30px'
                }, '@media (min-width: 1400px)': {
                    minWidth: 140
                }, '@media (min-width: 1600px)': {
                    minWidth: 160
                }, '@media (min-width: 2000px)': {
                    minWidth: 190
                },
                '@media (min-width: 2500px)': {
                    minWidth: 250
                },
                '@media (min-width: 3000px)': {
                    minWidth: 300
                },
                '@media (min-width: 3500px)': {
                    minWidth: 350
                }, '@media (min-width: 4000px)': {
                    minWidth: 400
                }
            }
        }
    }
})


const rootElement = document.getElementById('root')

ReactDOM.render(
    <Provider store={Store}>
        <Router history={history}>
            <MuiThemeProvider theme={theme}>
                <App />
                <ToastContainer />
            </MuiThemeProvider>
        </Router>
    </Provider>
    , rootElement)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
