import axios from 'axios'
import { RESET } from '../actions'
import { BASE_URL } from '../settings.js'
import { showNotification } from '../services/notification'

export const RESET_STATE_FROM_REPORT_SALE = "RESET_STATE_FROM_REPORT_SALE";
export const USER_DATA_FROM_REPORT_SALE = 'USER_DATA_FROM_REPORT_SALE';
export const PRODUCTS_SELECTED_FROM_REPORT_SALE = 'PRODUCTS_SELECTED_FROM_REPORT_SALE';
export const SUBMIT_SALE_REPORT = 'SUBMIT_SALE_REPORT';
export const SUBMIT_SALE_REPORT_ERROR = 'SUBMIT_SALE_REPOR_ERROR';

let url = `${BASE_URL}/reportsale`

export const insertUserFromReportSale = (userData, userChanged, DEALERID) => {
    return dispatch => {
        if (!userData['DEALERID'])
            userData['DEALERID'] = DEALERID
        dispatch({
            type: USER_DATA_FROM_REPORT_SALE,
            payload: { userData, userChanged }
        })
    }
}

export const saveProductsSelectedFromReportSale = (products) => {
    return dispatch => {
        dispatch({
            type: PRODUCTS_SELECTED_FROM_REPORT_SALE,
            payload: products
        })
    }
}

export const submitSaleReport = (token, saleData, history) => {
    return async (dispatch) => {
        var header = { 'Authorization': token }
        await axios.post(url, saleData, { headers: header })
            .then((response) => {
                let config = {
                    icon: 'check',
                    type: 'success',
                    message: 'The Sale has been created successfully',
                    autoClose: true
                }
                showNotification(config);
                history.push('/sales')
                dispatch({
                    type: SUBMIT_SALE_REPORT
                })
                dispatch({
                    type: RESET
                })
            })
            .catch((error) => {
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: 'An error has occurred with the Report Sale. Please, try it again',
                    autoClose: true
                }
                showNotification(config);
                history.push('/home')
                dispatch({
                    type: SUBMIT_SALE_REPORT_ERROR,
                    payload: error
                })
            })
    }
}


export const resetStateOfReportSale = () => {
    return dispatch => {
        dispatch({
            type: RESET_STATE_FROM_REPORT_SALE
        })
    }
}