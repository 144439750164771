import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, AppBar, Toolbar, Typography, Grid, Button, IconButton } from '@material-ui/core';
import logo from '../resources/Images/gemvision_authorized_dealer_icon.svg';
import ExitToApp from '@material-ui/icons/ExitToApp';

const styles = {
    media: {
        backgroundColor: "#36363c"
    },
    logo: {
        width: "100px"
    },
    button: {
        color: '#adacb4',
    },
    dealerViewButton: {
        background: '#53535a',
        color: 'white',
        height: 32,
        marginRight: 8,
        fontSize: 12
    }
};

class HeaderView extends Component {

    generateViewModeButton(groups, viewMode, onSetViewMode, classes) {
        if (groups) {
            if (groups.indexOf('Admin') > -1 && groups.indexOf('Dealers') > -1) {
                let text = viewMode === 'Dealers' ? 'Admin view' : 'Dealer view';
                let nextViewMode = viewMode === 'Dealers' ? 'Admin' : 'Dealers';
                return (<Button className={classes.dealerViewButton} onClick={() => { onSetViewMode(nextViewMode) }}>{text}</Button>)
            }
        }
    }

    render() {
        const { userName, classes, groups, viewMode, onSetViewMode, logout } = this.props;
        return (
            <div>
                <AppBar position="static" className={classes.media}>
                    <Toolbar style={{ paddingTop: 8, paddingBottom: 8}}>
                        <Grid container  >
                            <Grid container item xs={4} justifyContent="flex-start">
                                <a href="/home" style={{ display: 'flex' }}>
                                    <img src={logo} className={classes.logo} alt="gemvision authorized dealer" />
                                </a>
                            </Grid>
                            <Grid container item xs={8} justifyContent="flex-end" style={{ display: 'flex', alignItems: 'center' }}>
                                {this.generateViewModeButton(groups, viewMode, onSetViewMode, classes)}
                                <Typography color="inherit" style={{ margin: 'auto 4px' }} className={classes.button} align="right">
                                    {userName}
                                </Typography>
                                <IconButton key="logout" className={classes.button} onClick={logout}>
                                    <ExitToApp fontSize='small' />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div >
        );
    }
}

HeaderView.propTypes = {
    userName: PropTypes.string,
};

export default withStyles(styles)(HeaderView);