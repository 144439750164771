import axios from 'axios'

import { BASE_URL } from '../settings.js'
import { showNotification } from '../services/notification'

export const GET_ORDERS = "GET_ORDERS";
export const GET_TOP_TEN_ORDERS = "GET_TOP_TEN_ORDERS";
export const DELETE_ORDER = "DELETE_ORDER";

let url = `${BASE_URL}/orders`

export const getOrdersFromApi = (token, id) => {
    return async (dispatch) => {
        const urlMod = id ? `${url}/${id}` : url;
        const request = await axios.get(urlMod, {
            headers: {
                'Authorization': token,
            }
        })
        dispatch({
            type: GET_ORDERS,
            payload: request
        })
    }
}

export const getTopTenOrders = (token, id) => {
    return async (dispatch) => {
        const modUrl = id ? `${url}/${id}` : `${url}`;
        const request = await axios.get(modUrl, {
            headers: {
                'Authorization': token
            },
            params: {
                top: 10
            },
        })
        dispatch({
            type: GET_TOP_TEN_ORDERS,
            payload: request
        })
    }
}

export const deleteOrder = (token, IdOrder) => {
    return async (dispatch) => {
        await axios.delete(`${url}/${IdOrder}`, {
            headers: {
                'Authorization': token,
            }
        }).then(() => {
            dispatch({
                type: DELETE_ORDER,
                payload: IdOrder
            })
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The Customer has been created successfully',
                autoClose: true
            }
            showNotification(config);
        })
    }
}