import { GET_SALES, GET_TOP_TEN_SALES, DELETE_SALE, RESET } from '../actions'
import { getColumnsOfTable } from '../services'

const findItem = (element, id) => {
    return element['Sale #'] === id
}

const deleteItemFromArray = (ItemsArray, id) => {
    const idToDelete = ItemsArray.findIndex((element) => findItem(element, id))
    delete ItemsArray[idToDelete]
    ItemsArray.splice(idToDelete, 1)
    return ItemsArray
}

export default function SalesReducer(state = { sales: null, recentSales: null }, action) {
    switch (action.type) {
        case GET_SALES:
            let result = action.payload.data.results
            let sales = []

            for (let item of result) {
                if (!item['PRODUCTS']) {
                    let index = sales.findIndex((element) => {
                        return element['Sale #'] === item['Sale #']
                    })

                    if (index === -1) {
                        item['OS_ITEM'] = []
                        item['OS_ITEM'].push({ 'TITLE': item['TITLE'] })
                        sales.push(item)
                    } else {
                        sales[index]['OS_ITEM'].push({ 'TITLE': item['TITLE'] })
                    }
                } else {
                    sales.push(item)
                }
            }

            if (!state.fields) {
                const columnsName = getColumnsOfTable(action.payload.data.results[0])
                return {
                    ...state,
                    sales,
                    fields: columnsName
                }
            }
            return { ...state, sales }
        case GET_TOP_TEN_SALES:
            if (!state.fields) {
                const columnsName = getColumnsOfTable(action.payload.data.results[0])
                return {
                    ...state,
                    recentSales: action.payload.data.results,
                    fields: columnsName
                }
            }
            return { ...state, recentSales: action.payload.data.results }
        case DELETE_SALE:
            return { ...state, sales: deleteItemFromArray(state.sales, action.payload, 'ID') }
        case RESET:
            return { ...state, fields: null, recentSales: null, sales: null }
        default:
            return state
    }
}
