import axios from 'axios'
import { BASE_URL } from '../settings.js'
import { showNotification } from '../services/notification'

export const GET_USERS = "GET_USERS"
export const DELETE_USER = "DELETE_USER"
export const UPDATE_USER = "UPDATE_USER"
export const GET_GROUPS = "GET_GROUPS"
export const ADD_USER = "ADD_USER"
export const ACTIVE_USER = "ACTIVE_USER"

let url = `${BASE_URL}/users`

export const getUsersFromApi = (token, addGroupInfo) => {
    return async (dispatch) => {
        let params = { addGroupInfo: addGroupInfo }
        await axios.get(url, {
            headers: {
                'Authorization': token
            },
            params: params
        }).then((results) => {
            dispatch({
                type: GET_USERS,
                payload: results.data.Users
            })
        }).catch((error) => {
            console.error(error)
            throw Error(error)
        })
    }
}

export const getGroupsFromApi = (token) => {
    return async (dispatch) => {
        let params = { getGroups: true }
        await axios.get(url, {
            headers: {
                'Authorization': token
            },
            params: params
        }).then((results) => {
            dispatch({
                type: GET_GROUPS,
                payload: results.data
            })
        }).catch((error) => {
            console.error(error)
            throw Error(error)
        })
    }
}

export const addUser = (token, itemsToUpdate, nextAction) => {
    return async (dispatch) => {
        await axios.post(`${url}`, itemsToUpdate, {
            headers: {
                'Authorization': token,
            }
        }).then((result) => {
            if (result.data && result.data.code === 'UsernameExistsException') {
                let error = new Error()
                error.message = 'User already exists.'
                throw error
            }
            else if (!result.data) {
                throw new Error()
            }
            dispatch({
                type: ADD_USER,
                payload: result
            })
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The User has been created successfully',
                autoClose: true
            }
            showNotification(config)
            nextAction(result.data.User.Attributes[0].Value)
        }).catch((error) => {
            let errorMessage = 'An error has occurred. Please, try it again'
            if (error.message) errorMessage = error.message

            let config = {
                icon: 'error',
                type: 'error',
                message: errorMessage,
                autoClose: true
            }
            showNotification(config)
        })
    }
}

export const updateUser = (token, itemsToUpdate, history = null) => {
    return async (dispatch) => {
        await axios.put(`${url}`, itemsToUpdate, {
            headers: {
                'Authorization': token,
            }
        }).then(() => {
            if (!!history)
                history.push('/users')
            dispatch({
                type: UPDATE_USER,
                payload: itemsToUpdate
            })
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The User has been edited successfully',
                autoClose: true
            }
            showNotification(config)
        }).catch((error) => {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'An error has occurred. Please, try it again',
                autoClose: true
            }
            showNotification(config)
        })
    }
}

export const resetUserPassword = (token, IdUser, history = null) => {
    return () => {
        return new Promise((resolve, reject) => {
            axios.put(`${url}`, IdUser, {
                headers: {
                    'Authorization': token,
                },
                params: {
                    resetUserPassword: true
                }
            }).then(() => {
                if (!!history)
                    history.push('/users')

                let config = {
                    icon: 'check',
                    type: 'success',
                    message: "The User's password has been reset successfully",
                    autoClose: true
                }
                showNotification(config)
                resolve()
            }).catch((err) => {
                let errorMessage = 'An error has occurred. Please, try it again'
                if (err && err.request) {
                    let error = JSON.parse(err.request.response)

                    if (error.code) {
                        errorMessage = error.message
                    }
                }

                let config = {
                    icon: 'error',
                    type: 'error',
                    message: errorMessage,
                    autoClose: true
                }
                showNotification(config)
                reject()
            })
        })
    }
}

export const deleteUser = (token, IdUser) => {
    return async (dispatch) => {
        await axios.delete(`${url}`, {
            headers: {
                'Authorization': token,
            },
            params: { USERNAME: IdUser }
        }).then(() => {
            dispatch({
                type: DELETE_USER,
                payload: IdUser
            })
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The User has been deleted successfully',
                autoClose: true
            }
            showNotification(config)
        }).catch((error) => {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'An error has occurred. Please, try it again',
                autoClose: true
            }
            showNotification(config)
        })
    }
}

export const activeUser = (token, itemsToUpdate, history = null) => {
    return async (dispatch) => {
        await axios.put(`${url}`, itemsToUpdate, {
            headers: {
                'Authorization': token,
            }
        }).then(() => {
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The User has been changed successfully',
                autoClose: true
            }
            showNotification(config)
            if (!!history)
                history.push('/users')
            dispatch({
                type: ACTIVE_USER,
                payload: itemsToUpdate
            })
        }).catch((error) => {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'An error has occurred. Please, try it again',
                autoClose: true
            }
            showNotification(config)
        })
    }
}