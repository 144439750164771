import axios from 'axios';
import { BASE_URL } from '../settings.js'

export const GET_SALES = "GET_SALES";
export const GET_TOP_TEN_SALES = "GET_TOP_TEN_SALES";
export const DELETE_SALE = "DELETE_SALE";

let url = `${BASE_URL}/sales`

export const getSalesFromApi = (token, id) => {
    return async (dispatch) => {
        const urlMod = id ? `${url}/${id}` : url;
        const request = await axios.get(urlMod, {
            headers: {
                'Authorization': token,
            }
        })
        dispatch({
            type: GET_SALES,
            payload: request
        })
    }
}

export const getTopTenSales = (token, id) => {
    return async (dispatch) => {
        const modUrl = id ? `${url}/${id}` : `${url}`;
        const request = await axios.get(modUrl, {
            headers: {
                'Authorization': token,
            },
            params: {
                top: 10
            },
        })
        dispatch({
            type: GET_TOP_TEN_SALES,
            payload: request
        })
    }
}

export const deleteSale = (token, IdSale) => {
    return async (dispatch) => {
        await axios.delete(`${url}/${IdSale}`, {
            headers: {
                'Authorization': token,
            }
        }).then(() => {
            dispatch({
                type: DELETE_SALE,
                payload: IdSale
            })
        })
    }
}