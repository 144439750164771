import axios from 'axios'
import { RESET } from '../actions'
import { BASE_URL } from '../settings.js'
import { showNotification } from '../services/notification';

export const GET_INVENTORIES = "GET_INVENTORIES"
export const DELETE_INVENTORY = "DELETE_INVENTORY"
export const UPDATE_INVENTORY = "UPDATE_INVENTORY"
export const ADD_INVENTORY = "ADD_INVENTORY"
export const INVENTORY_ACTION_ERROR = "INVENTORY_ACTION_ERROR"
export const RESET_INVENTORY = "RESET_INVENTORY"

const url = `${BASE_URL}/inventory`

export const getInventoriesFromApi = (token, dealerId) => {
    return async (dispatch) => {
        let params = {}
        if (dealerId) params = { dealerId: dealerId }
        const request = await axios.get(url, {
            headers: {
                'Authorization': token,
            },
            params: params
        })
        request.data.results.forEach((element, index) => {
            request.data.results[index].ID = index;
        });
        dispatch({
            type: GET_INVENTORIES,
            payload: request
        })
    }
}

export const addInventory = (token, data, productType, history) => {
    return async (dispatch) => {
        await axios.post(url, { inventoryData: data, productType: productType }, {
            headers: {
                'Authorization': token,
            }
        }).then(() => {
            dispatch({ type: ADD_INVENTORY })
            dispatch({ type: RESET })
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The Inventory has been created successfully',
                autoClose: true
            }
            showNotification(config);
            history.push('/inventory');
        }).catch((error) => {
            dispatch({ type: INVENTORY_ACTION_ERROR })

            let config = {
                icon: 'error',
                type: 'error',
                message: 'An error has occurred. Please, try it again',
                autoClose: true
            }
            let response = JSON.parse(error.request.response)
            if (response.errorCode === "ER_DUP_ENTRY") {
                config.message = 'Error: One of the keys already exists.'
            }
            showNotification(config);
        });
    }
}

export const resetInventory = () => {
    return dispatch => {
        dispatch({
            type: RESET_INVENTORY
        })
    }
}