import React, { Component } from 'react'
import { withStyles, Paper, Button, Grid, Typography, Icon, TextField, MenuItem } from "@material-ui/core"
import { Field, reduxForm, Form, FieldArray } from 'redux-form'

import { renderSelectField, renderTextField } from './CommonInputs'
import { required, validationHandler, requiredNumber } from './validator'
import { orderBy } from '../../services'

const styles = theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: '30px'
    },
    boxCard: {
        padding: '25px',
        width: "600px !important"
    },
    selectContainer: {
        borderBottom: '1px dashed #9f9f9f',
        paddingBottom: '20px',
        marginBottom: '15px'
    },
    selectValidator: {
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '100%'
    },
    textField: {
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '50px'
    },
    textFieldKey: {
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '80%',
    },
    button: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing()
    }
})

class Inventory extends Component {

    renderRow = (data) => {
        return (
            <div style={{ width: '100%' }}>
                <TextField
                    label="Quantity"
                    className={this.props.classes.textField}
                    margin="normal"
                    type="number"
                    value={data.fields.length}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <Button
                    color="default"
                    size="small"
                    style={{ marginTop: '20px' }}
                    onClick={() => data.fields.push()}
                >
                    <Icon>add</Icon>
                </Button>
                {data.fields.map((item, index) => {
                    return (
                        <div key={index} style={{ width: '100%' }}>
                            <Field
                                style={{ width: '90%' }}
                                name={`${item}.KEY`}
                                type="text"
                                component={renderTextField}
                                label={`Key ${index + 1}`}
                                validate={[required, validationHandler]}
                            />
                            <Button
                                title="Remove Hobby"
                                color="default"
                                size="small"
                                style={{ marginTop: '25px', padding: '0', minWidth: '30px' }}
                                type="button" onClick={() => data.fields.remove(index)}>
                                <Icon color="error">clear</Icon>
                            </Button>
                        </div>
                    )
                })}
                {data.meta.error && <li className="error">{data.meta.error}</li>}
            </div>
        )
    }

    render() {
        const {
            handleSubmit,
            classes,
            title,
            optionsDealers,
            optionsProduct,
            goBack,
            handleOnSubmit,
            pristine,
            submitting,
        } = this.props;
        return (
            <div className={classes.wrapper}>
                <Paper className={classes.boxCard}>
                    <Grid container className={classes.selectContainer} >
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                    </Grid >
                    <Form onSubmit={handleSubmit(handleOnSubmit)}>
                        <Grid container >
                            <Field
                                name="DEALERID"
                                label="Dealer"
                                component={renderSelectField}
                                className={classes.selectValidator}
                                validate={[requiredNumber]}
                            >
                                {orderBy(optionsDealers, "COMPANY")}
                                <MenuItem value=""></MenuItem>
                                {optionsDealers.map((option, index) => (
                                    <MenuItem key={index} value={option.DealerID}>
                                        {option.COMPANY}
                                    </MenuItem>
                                ))}
                            </Field>
                            <Field
                                name="PRODUCTID"
                                label="Product"
                                validate={[requiredNumber]}
                                className={classes.selectValidator}
                                component={renderSelectField}
                            >
                                {orderBy(optionsProduct, "TITLE")}
                                <MenuItem></MenuItem>
                                {optionsProduct.map((option, index) => (
                                    <MenuItem key={index} value={option.ID}>
                                        {option.TITLE}
                                    </MenuItem>
                                ))}
                            </Field>
                            <FieldArray name="keySetted" component={this.renderRow} />
                        </Grid>
                        <Grid container justifyContent="center" style={{ marginTop: '30px' }}>
                            <Button
                                type="button"
                                variant="contained"
                                color="default"
                                size="large"
                                className={classes.button}
                                disabled={submitting}
                                onClick={goBack}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                type="submit"
                                disabled={pristine || submitting}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Form>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(reduxForm({ form: 'inventory' })(Inventory))