import React, { Component } from 'react';
import { connect } from 'react-redux';
import ForguetPasswordComponent from '../components/ForgetPassword';
import { forgotPassword } from '../actions';

class ForgetPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            isButtonDisabled: false
        }
    }

    onInputValueChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };

    handleSubmit = ({ email }) => {
        this.setState({ isButtonDisabled: true })
        this.props.forgotPassword(email)
            .then(() => {
                this.props.history.push(`/resetpassword/${email}`);
            })
            .catch(() => {
                this.setState({ isButtonDisabled: false })
            })
    }

    render() {
        return (
            <div>
                <ForguetPasswordComponent
                    history={this.props.history}
                    email={this.state.email}
                    onInputValueChange={this.onInputValueChange}
                    handleSubmit={this.handleSubmit}
                    isButtonDisabled={this.state.isButtonDisabled}
                />
            </div>
        );
    }
}


export default connect(null, { forgotPassword })(ForgetPassword);