import {
    USER_DATA_FROM_REPORT_SALE,
    PRODUCTS_SELECTED_FROM_REPORT_SALE,
    SUBMIT_SALE_REPORT_ERROR,
    SUBMIT_SALE_REPORT,
    RESET_STATE_FROM_REPORT_SALE,
    CUSTOMER_ERROR
} from '../actions'

export default function ReportSaleReducer(state = { userData: null, productsSelected: null, userChanged: null, error: null }, action) {
    switch (action.type) {
        case USER_DATA_FROM_REPORT_SALE:
            return { ...state, userData: action.payload.userData, userChanged: action.payload.userChanged }
        case PRODUCTS_SELECTED_FROM_REPORT_SALE:
            return { ...state, productsSelected: action.payload }
        case SUBMIT_SALE_REPORT_ERROR:
        case CUSTOMER_ERROR:
            return { ...state, error: action.payload }
        case SUBMIT_SALE_REPORT:
        case RESET_STATE_FROM_REPORT_SALE:
            return { ...state, userData: null, productsSelected: null, userChanged: null, error: null }
        default: return state
    }
}