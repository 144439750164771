import { } from './types';

export const inputHasChanged = (actionType: String, validationRules: [String], value, confirmValue = null) => {
	const returnInputState = (dispatch, value, valid, validLabel) => {
		dispatch({
			type: actionType,
			payload: {
				value,
				valid,
				validLabel,
			}
		});
	}

	return (dispatch) => {
		if (validationRules !== undefined || validationRules !== null) {
			if (validationRules.length > 0) {
				let valid = true;
				let validLabel = '';
				for (const rule of validationRules) {
					switch (rule) {
						case 'required':
							break;
						case 'email':
							break;
						case 'password':
							break;
						default:
							console.log("VALIDATION RULE WITHOUT HANDLER", rule)
					}
				}
				returnInputState(dispatch, value, valid, validLabel);
			} else { returnInputState(dispatch, value, true, ''); }
		} else { returnInputState(dispatch, value, true, ''); }
	};
};