import axios from 'axios'
import { BASE_URL } from '../settings.js'
export const findItem = (element, id, key) => {
    return element[key] === id
}

export const findUser = (element, id) => {
    return element.Username === id
}

export const PreventNavigation = (currrentUrl, nextUrl) => {
    if (currrentUrl !== nextUrl) {
        return true;
    }
    return false
}

export const getInventoryRow = async (token, dealerId, productId) => {
    let params = {}
    if (dealerId) {
        params['dealerId'] = dealerId
    }
    if (productId) {
        params['productId'] = productId
    }
    try {
        const request = await axios.get(`${BASE_URL}/keys`, {
            headers: {
                'Authorization': token
            },
            params: params
        })
        return request.data.results
    } catch (error) {
        return null
    }
}

export const getProductOfOrder = (token, orderId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/orders`,
            {
                headers: {
                    'Authorization': token,
                }, params: { orderId: orderId }
            }).then((result) => {
                resolve(result.data.results)
            }).catch((err) => {
                reject(err)
            });
    });
}

export const getProductOfSale = (token, saleId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/sales`,
            {
                headers: {
                    'Authorization': token,
                }, params: { saleId: saleId }
            }).then((result) => {
                resolve(result.data.results)
            }).catch((err) => {
                reject(err)
            });
    });
}

export const getDealers = (token, dealerId) => {
    return new Promise((resolve, reject) => {
        let params = { onlyActive: true }
        let modUrl = dealerId ? `${BASE_URL}/dealers/${dealerId}` : `${BASE_URL}/dealers`;
        axios.get(modUrl,
            {
                headers: {
                    'Authorization': token,
                },
                params: params
            }).then((result) => {
                resolve(result.data)
            }).catch((err) => {
                reject(err)
            });
    });
}

export const getProduct = (token, activeProduct = false) => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/products`,
            {
                headers: {
                    'Authorization': token,
                },
                params: {
                    activeProduct: `${activeProduct}`
                }
            }).then((result) => {
                resolve(result.data.results)
            }).catch((err) => {
                reject(err)
            });
    });
}

export const getUser = (token) => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/users`,
            {
                headers: {
                    'Authorization': token,
                }
            }).then((result) => {
                result.data.Users.sort((a, b) => a.Username.localeCompare(b.Username))
                resolve(result.data.Users)
            }).catch((err) => {
                reject(err)
            });
    });
}

export const getCountry = (token) => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/country`,
            {}).then((result) => {
                resolve(result.data.results)
            }).catch((err) => {
                reject(err)
            });
    });
}

export const getColumnsOfTable = (fields) => {
    let indexOfLastName = ''
    let columns = []
    if (!!fields) {
        columns = Object.keys(fields)
        if (!!fields.contactData) columns = columns.concat(Object.keys(fields.contactData[0]))
    }
    columns.forEach((item, index) => {
        if (item === 'LASTNAME')
            indexOfLastName = index
    })
    columns.splice(indexOfLastName + 1, 0, 'Customer')
    return columns
}

export const updateItemFromArray = (ItemsArray, item) => {
    const index = ItemsArray.findIndex((element) => item.ID === element.ID)
    ItemsArray[index] = { ...ItemsArray[index], item };
    return ItemsArray
}

export const enabledProduct = (ItemsArray, item) => {
    const id = ItemsArray.findIndex((element) => item.id === element.ID)
    ItemsArray[id][item.name] = item.value;
    return ItemsArray
}

export const deleteItemFromArray = (ItemsArray, id, key) => {
    const idToDelete = ItemsArray.findIndex((element) => findItem(element, id, key))
    delete ItemsArray[idToDelete]
    ItemsArray.splice(idToDelete, 1)
    return ItemsArray
}

export const activeUser = (ItemsArray, user) => {
    const id = ItemsArray.findIndex((element) => user.USERNAME === element.Username)
    ItemsArray[id]['Enabled'] = user.Enabled;
    return ItemsArray
}

export const updateUserFromArray = (ItemsArray, user) => {
    const index = ItemsArray.findIndex((element) => user.USERNAME === element.Username)
    ItemsArray[index]['Attributes'][1]['Value'] = user.NAME
    ItemsArray[index]['groups'] = user.GROUPS
    return ItemsArray
}

export const deleteUserFromArray = (ItemsArray, id) => {
    const idToDelete = ItemsArray.findIndex((element) => findUser(element, id))
    delete ItemsArray[idToDelete]
    ItemsArray.splice(idToDelete, 1)
    return ItemsArray
}

export const addImageForProduct = (ItemsArray, item) => {
    const id = ItemsArray.findIndex((element) => element.ID === item.ID)
    ItemsArray[id][item.image] = item.value;
    return ItemsArray
}

export const finder = (item, itemName) => {
    for (const key in item) {
        if (item.hasOwnProperty(key)) {
            const element = item[key];
            if (element.Name === itemName)
                return element.Value;
        }
    }
    return '';
}

export const foundIdOfCountries = (countries, namesOfCountries) => {
    let idOfCountry = []
    countries.forEach(country => {
        namesOfCountries.forEach(name => {
            if (country.DISPLAY === name)
                idOfCountry.push(country.ID)
        })
    })
    return idOfCountry
}

export const orderBy = (options, value) => {
    options.sort((a, b) => (a[value].toUpperCase() > b[value].toUpperCase()) ? 1 : ((b[value].toUpperCase() > a[value].toUpperCase()) ? -1 : 0));
}

export const foundIdOfProductType = (allProductsType, nameOfProducts) => {
    let idOfProducts = []
    allProductsType.forEach(product => {
        nameOfProducts.forEach(name => {
            if (product.LABEL === name)
                idOfProducts.push(product.ID)
        })
    })
    return idOfProducts
}