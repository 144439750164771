import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import CSVReader from "react-csv-reader";
import { withStyles, MenuItem, Select, Button, FormHelperText, Grid, InputBase, TextField } from '@material-ui/core';
import { MenuProvider } from 'react-contexify';

import { insertCSV, getProductType } from '../../actions'
import { TITLE, PARTNUMBER, PRICE, PRODUCTTYPE, ISKEY, AVAILABLEFORSTOCK, USAVAILABLE, UKAVAILABLE, AUAVAILABLE, ELSEAVAILABLE, ENABLED, OPTIONS, VERSION, ProductsHeadersArray, RequiredHeadersArray } from '../../constants'
import ContextMenu from '../../components/ContextMenu';
import { showNotification } from '../../services/notification';
import TableToImportCSV from './TableToImportCSV'
import 'react-contexify/dist/ReactContexify.min.css';

const styles = theme => ({
    wrapper: {
        backgroundColor: '#fff',
    }
});

class ImportProducts extends Component {
    state = {
        columns: null,
        data: null,
        optionsRequired: ['none', 'rhinoProof', 'soldWithRhino'],
        availability: ['false', 'true'],
        optionsHeaders: ProductsHeadersArray,
    }

    componentDidMount() {
        if (this.props.productsList.length === 0) {
            this.props.getProductType(this.props.userToken.idToken.jwtToken)
        }
    }

    checkIfThereAreRequiredColumns = (datas) => {
        let isThere = null
        ProductsHeadersArray.forEach(itemRequired => {
            isThere = datas[0].findIndex(column => column.toUpperCase() === itemRequired)
            if (isThere === -1) {
                datas[0].push(itemRequired)
                datas = datas.map((item, index) => {
                    if (index !== 0)
                        item.push('')
                    return item
                })
            } else {
                datas[0][isThere] = datas[0][isThere].toUpperCase()
                datas = datas.map((item, index) => {
                    if (index === 0)
                        return item
                    if (datas[0][isThere] === PRODUCTTYPE) {
                        let value = this.props.productsList.find(element => element.LABEL === datas[index][isThere])
                        item[isThere] = value !== undefined ? value.ID : ''
                    }
                    return item
                })
            }
        })
        return datas
    }

    handleOnChangeInput = (event, cellInfo, options) => {
        let { data } = this.state
        cellInfo[options.accessor] = event.target.value
        this.setState({ data })
    }

    doInsertIntoBBDD = () => {
        let canWeDoInsert = true
        let { data } = this.state
        RequiredHeadersArray.forEach(item => {
            if (!data[0].hasOwnProperty(item)) {
                canWeDoInsert = false
            }
        })
        data.forEach(item => {
            RequiredHeadersArray.forEach(element => {
                if (item[element] === "")
                    canWeDoInsert = false
            })
        })
        let config = {
            icon: 'warning',
            type: 'warning',
            message: `You need some values, than they are required. They are ${RequiredHeadersArray.map(item => ` ${item}`)}`,
            autoClose: true,
        }
        if (canWeDoInsert) {
            this.props.insertCSV(this.props.userToken.idToken.jwtToken, data, this.props.history)
        } else {
            showNotification(config)
        }
    }

    handleOnChangeInputOfHeader = (event, cellInfo, ds, s) => {
        let { data, columns, } = this.state
        let newHeaderForTable = this.switchToCreateHeaders(event.target.value)
        columns = this.checkIfThatColumnAlreadyExist(newHeaderForTable.accessor, columns)
        let indexToDelete = columns.findIndex(item => item.accessor === cellInfo)
        columns.splice(indexToDelete, 1, newHeaderForTable)
        data = data.map(item => {
            let oldValue = null
            switch (event.target.value) {
                case PRODUCTTYPE:
                    oldValue = this.props.productsList.find(element => element.LABEL === item[cellInfo])
                    item[event.target.value] = oldValue !== undefined ? oldValue.ID : null
                    delete item[cellInfo]
                    break
                default:
                    item[event.target.value] = item[cellInfo]
                    delete item[cellInfo]
                    break
            }
            return item
        })
        this.setState({ data, columns })
    }

    handleData = (datas) => {
        let arrayOfHeaders = []
        let arrayOfContent = []
        datas = this.checkIfThereAreRequiredColumns(datas)
        datas[0].forEach((item, index) => {
            arrayOfHeaders.push(this.switchToCreateHeaders(item, index))
        })
        datas.forEach((row, i) => {
            if (i === 0 || row.filter(item => item !== '').length === 0)
                return
            let rowFill = {}
            row.forEach((column, index) => {
                if (arrayOfHeaders[index] !== undefined)
                    rowFill[arrayOfHeaders[index].accessor] = column
            })
            arrayOfContent.push(rowFill)
        })
        this.setState({ columns: arrayOfHeaders, data: arrayOfContent })
    }

    checkIfThatColumnAlreadyExist = (column, columns) => {
        columns = columns.filter(item => item.accessor !== column)
        return columns
    }

    handleCopyValueOnAllColumn = (event) => {
        let { data } = this.state
        data.forEach(item => {
            item[event.props.column] = event.props.value
        })
        this.setState({ data })
    }

    handleInsertNewColumn = (event) => {
        let { data, columns } = this.state
        columns.push(this.switchToCreateHeaders('newColumn'))
        data.forEach(item => {
            item['newColumn'] = ''
        })
        this.setState({ data, columns })
        this.forceUpdate()
    }

    handleDeleteColumn = (event) => {
        let { data, columns } = this.state
        data.forEach(item => {
            delete item[event.props.column]
        })
        columns = columns.filter(element => (element.accessor !== event.props.column))
        this.setState({ data, columns })
    }

    renderSelectForHeader = (cellInfo, row) => {
        const { optionsHeaders } = this.state
        let index = optionsHeaders.indexOf(cellInfo.accessor)
        return (
            <Fragment>
                <Select
                    value={optionsHeaders[index] !== undefined ? optionsHeaders[index] : -1}
                    onChange={(event) => this.handleOnChangeInputOfHeader(event, cellInfo.accessor)}
                    style={{ width: '100%' }}
                >
                    {index === -1 &&
                        <MenuItem value={-1} disabled>
                            {cellInfo.accessor}
                        </MenuItem>}
                    {optionsHeaders.map((item, index) => {
                        return <MenuItem value={item} key={index}>{item}</MenuItem>
                    })}
                </Select>
                {index === -1 && <FormHelperText style={{ color: 'red', fontWeight: 'bold' }}>Error</FormHelperText>}
            </Fragment >
        )
    }

    renderSelect_optionsRequired = (cellInfo, options) => {
        let valueForTheBBDD = cellInfo[options.accessor]
        // let valueForTheBBDD = this.state.data[indice][cellInfo] === '0'
        // ? 'false'
        // : this.state.data[indice][cellInfo] === '1'
        //     ? 'true'
        //     : this.state.data[indice][cellInfo]
        return (
            <MenuProvider id="menu_id" data={{ column: cellInfo, value: valueForTheBBDD }}>
                <Select
                    value={valueForTheBBDD}
                    onChange={(event) => this.handleOnChangeInput(event, cellInfo, options)}
                    style={{ width: '100%' }}
                >
                    {this.state.optionsRequired.map((item, index) => {
                        return <MenuItem value={item} key={index}>{item}</MenuItem>
                    })}
                </Select>
            </MenuProvider>
        )
    }

    renderSelect_availability = (cellInfo, options) => {
        let valueForTheBBDD = cellInfo[options.accessor] === '0'
            ? 'false'
            : cellInfo[options.accessor] === '1'
                ? 'true'
                : cellInfo[options.accessor]
        return (
            <MenuProvider id="menu_id" data={{ column: options.accessor, value: valueForTheBBDD }}>
                <Select
                    value={valueForTheBBDD}
                    onChange={(event) => this.handleOnChangeInput(event, cellInfo, options)}
                    style={{ width: '100%' }}
                >
                    {this.state.availability.map((item, index) => {
                        return <MenuItem value={item} key={index}>{item}</MenuItem>
                    })}
                </Select>
            </MenuProvider>
        )
    }

    renderSelectRequired_optionsProductType = (cellInfo, options) => {
        let value = this.props.productsList.find(item => item.ID === cellInfo[options.accessor])
        return (
            <MenuProvider id="menu_id" data={{ column: options.accessor, value: cellInfo[options.accessor] }}>
                <Select
                    value={value ? value.ID : null}
                    onChange={(event) => this.handleOnChangeInput(event, cellInfo, options)}
                    style={{ width: '100%' }}
                >
                    {this.props.productsList.map(item => {
                        return <MenuItem key={item.ID} value={item.ID}>{item.LABEL}</MenuItem>
                    })}
                </Select>
            </MenuProvider>
        )
    }

    renderInputEditable = (cellInfo, options) => {
        return <MenuProvider id="menu_id" data={{ column: options.accessor, value: cellInfo[options.accessor] }}>
            <TextField style={{ width: '100%' }} onChange={(event) => this.handleOnChangeInput(event, cellInfo, options)} value={cellInfo[options.accessor]} />
        </MenuProvider>
    }

    renderInputNumber = (cellInfo, options) => {
        return <MenuProvider id="menu_id" data={{ column: options.accessor, value: cellInfo[options.accessor] }}>
            <InputBase type={'number'} style={{ width: '100%', color: 'black' }} disabled onChange={(event) => {
                if (event.target.value.includes('-'))
                    return this.handleOnChangeInput(event, cellInfo, options)
            }} value={cellInfo[options.accessor]} />
        </MenuProvider>
    }

    renderInput = (cellInfo, options) => {
        return (<MenuProvider id="menu_id" data={{ column: options.accessor, value: cellInfo[options.accessor] }}>
            <InputBase style={{ width: '100%', color: 'black' }} disabled onChange={(event) => this.handleOnChangeInput(event, cellInfo, options)} value={cellInfo[options.accessor]} />
        </MenuProvider>)
    }

    switchToCreateHeaders = (item, index) => {
        switch (item) {
            case TITLE:
            case PARTNUMBER:
                return ({
                    Header: this.renderSelectForHeader,
                    accessor: item,
                    Cell: this.renderInput,
                    disableSorting: true,
                    minWidth: 200
                })
            case VERSION:
                return ({
                    Header: this.renderSelectForHeader,
                    accessor: item,
                    Cell: this.renderInputEditable,
                    disableSorting: true,
                    minWidth: 200
                })
            case PRICE:
                return ({
                    Header: this.renderSelectForHeader,
                    accessor: item,
                    Cell: this.renderInputNumber,
                    disableSorting: true,
                    minWidth: 100
                })
            case OPTIONS:
                return ({
                    Header: this.renderSelectForHeader,
                    accessor: item,
                    Cell: this.renderSelect_optionsRequired,
                    disableSorting: true,
                    minWidth: 200
                })
            case PRODUCTTYPE:
                return ({
                    Header: this.renderSelectForHeader,
                    accessor: item,
                    Cell: this.renderSelectRequired_optionsProductType,
                    disableSorting: true,
                    minWidth: 200
                })
            case ISKEY:
            case AVAILABLEFORSTOCK:
            case USAVAILABLE:
            case UKAVAILABLE:
            case AUAVAILABLE:
            case ELSEAVAILABLE:
            case ENABLED:
                return ({
                    Header: this.renderSelectForHeader,
                    accessor: item,
                    Cell: this.renderSelect_availability,
                    disableSorting: true,
                    minWidth: 150
                })
            default:
                return ({
                    Header: this.renderSelectForHeader,
                    accessor: item,
                    Cell: this.renderInput,
                    disableSorting: true,
                    minWidth: 200
                })
        }
    }

    render() {
        const { classes } = this.props
        const { columns, data } = this.state
        return (
            <div style={{ padding: '15px 25px 25px 25px' }}>
                <Grid container>
                    <Grid container item xs={6}>
                        <CSVReader
                            cssClass="react-csv-input"
                            onFileLoaded={this.handleData} />
                    </Grid>
                    <Grid container item xs={6} justify="flex-end" style={{ marginBottom: '3px' }}>
                        {(data && columns) && <Button variant="contained" onClick={() => this.doInsertIntoBBDD()} >Insert</Button>}
                    </Grid>
                </Grid>
                {(data && columns) &&
                    <Fragment>
                        <TableToImportCSV
                            sortable={false}
                            data={data}
                            columns={columns}
                            className={classes.wrapper}
                            defaultPageSize={25}
                            pageSizeOptions={[25, 50, 100, 150]} />
                    </Fragment>}
                <ContextMenu handleCopyValueOnAllColumn={this.handleCopyValueOnAllColumn} handleInsertNewColumn={this.handleInsertNewColumn} handleDeleteColumn={this.handleDeleteColumn} />
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        productsList: state.ProductTypeReducer.productsList
    }
}

export default withStyles(styles)(connect(mapStateToProps, { insertCSV, getProductType })(ImportProducts));