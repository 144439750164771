import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton, Icon, Switch, Tooltip } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Generic from '../../components/Generic';
import ConfirmationModal from '../../components/ConfirmationModal';
import { updateDealer, getUsersFromApi, deleteUser, getGroupsFromApi, updateUser, activeUser, resetUserPassword } from '../../actions';
import { finder } from '../../services';
import Spinner from '../../components/Spinner';

var dateFormat = require('dateformat');

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            search: '',
            configPage: {
                title: 'Users',
                buttonDownload: false,
                buttonAddNew: true
            },
            openModal: false,
            deleteRow: null,
            areResetPasswordButtonsDisabled: false
        };
    }

    componentDidMount() {
        if (!this.props.UsersReducer.groups) {
            this.props.getGroupsFromApi(this.props.userToken.idToken.jwtToken)
                .catch(() => {
                    this.props.getGroupsFromApi(this.props.userToken.idToken.jwtToken)
                        .catch(() => this.setState({ hasError: true }))
                })
        }
        this.props.getUsersFromApi(this.props.userToken.idToken.jwtToken, true)
            .then(() => { })
            .catch(() => {
                this.props.getUsersFromApi(this.props.userToken.idToken.jwtToken, true)
                    .catch(() => this.setState({ hasError: true }))
            })
        this.makeColumns()
        this.makeData(this.props.UsersReducer.users)

    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!nextProps.UsersReducer.users) {
            this.makeColumns()
            this.makeData(nextProps.UsersReducer.users)
        }
    }

    deleteItem = (row) => {
        this.setState({ openModal: false });
        this.props.deleteUser(this.props.userToken.idToken.jwtToken, row.Username)
    }

    enableToggle(row) {
        this.props.activeUser(this.props.userToken.idToken.jwtToken, { USERNAME: row.Username, Enabled: !row.Enabled })
    }

    editItem(id) {
        this.props.history.push(`${this.state.configPage.title.toLowerCase()}/form/${id}`)
    }

    resetPassword(username) {
        this.setState({ areResetPasswordButtonsDisabled: true })
        this.props.resetUserPassword(this.props.userToken.idToken.jwtToken, { USERNAME: username }, this.props.history)
            .then(() => { this.setState({ areResetPasswordButtonsDisabled: false }) })
            .catch(() => { this.setState({ areResetPasswordButtonsDisabled: false }) })
    }

    makeColumns() {
        let arrayOfItems = []
        arrayOfItems.push({
            Header: 'Name',
            id: 'nameAccessor',
            accessor: d => finder(d.Attributes, 'name')
        })
        arrayOfItems.push({
            Header: 'User name',
            id: 'userNameAccessor',
            accessor: 'Username'
        })
        arrayOfItems.push({
            Header: 'Email',
            id: 'emailAccessor',
            accessor: d => finder(d.Attributes, 'email')
        })
        arrayOfItems.push({
            Header: 'Groups',
            id: 'groupAccessor',
            accessor: d => d.groups.join(', ')
        })
        arrayOfItems.push({
            Header: 'Creation date',
            id: 'cDateAccessor',
            width: 220,
            accessor: d => <div> {dateFormat(d.UserCreateDate, "fullDate")}</div>
        })
        arrayOfItems.push({
            Header: 'Last update',
            id: 'cLastUpdateAccessor',
            width: 220,
            accessor: d => <div >{dateFormat(d.UserLastModifiedDate, "fullDate")}</div>
        })
        arrayOfItems.push({
            Header: 'Actions',
            id: 'enabledRow',
            minWidth: 80,
            style: { textAlign: "center" },
            Cell: (row) => {
                return (
                    <div>
                        <IconButton onClick={() => {
                            this.editItem(row.Username)
                        }}>
                            <Icon>edit</Icon>
                        </IconButton>
                        <Switch
                            checked={row.Enabled}
                            onClick={() => { this.enableToggle(row) }}
                        />
                        <Tooltip title="Reset User Password">
                            <IconButton onClick={() => {
                                this.resetPassword(row.Username);
                            }}
                                disabled={this.state.areResetPasswordButtonsDisabled}>
                                <RotateLeftIcon />
                            </IconButton>
                        </Tooltip>
                        <IconButton disabled={row.Enabled} onClick={() => {
                            this.handleClickOpenModal(row)
                        }}>
                            <Icon color={row.Enabled ? 'disabled' : 'error'}>close</Icon>
                        </IconButton>
                    </div>)
            }
        })
        this.setState({ columns: arrayOfItems })
    }

    makeData(dataOfUsers) {
        this.setState({ content: dataOfUsers })
    }

    onInputValueChange = ({ target }) => {
        this.setState({ search: target.value })
    }

    handleClickOpenModal = (row) => {
        this.setState({ openModal: true, deleteRow: row });
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
    };

    render() {
        if (!!this.props.UsersReducer.users) {
            let data = this.props.UsersReducer.users
            if (this.state.search) {
                data = data.filter(row => {
                    const array = Object.keys(row)
                    let isIncluded = false
                    array.forEach((columnName) => {
                        if (!isIncluded) {
                            if (columnName === 'Attributes') {
                                row[columnName].forEach(attribute => {
                                    if (attribute.Name === 'name' || attribute.Name === 'email') {
                                        if ((attribute.Value.toLowerCase()).includes(this.state.search.toLowerCase())) {
                                            isIncluded = true
                                        }
                                    }
                                })
                            }
                            else {
                                if (typeof row[columnName] === 'string') {
                                    if ((row[columnName].toLowerCase()).includes(this.state.search.toLowerCase())) {
                                        isIncluded = true
                                    }
                                }
                                else if ((String(row[columnName]).toLowerCase()).includes(this.state.search.toLowerCase())) {
                                    isIncluded = true
                                }
                            }
                        }
                    })
                    if (isIncluded)
                        return true
                    return false;
                })
            }
            return (
                <div>
                    <ConfirmationModal title={'User'} deleteRow={this.state.deleteRow} handleCloseModal={this.handleCloseModal} openModal={this.state.openModal} deleteItem={this.deleteItem} />
                    <Generic
                        data={data}
                        columns={this.state.columns}
                        onInputValueChange={this.onInputValueChange}
                        hasError={this.state.hasError}
                        configPage={this.state.configPage}
                        history={this.props.history} />
                </div>
            );
        } else if (this.state.hasError)
            return <Generic hasError={this.state.hasError} />
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        UsersReducer: state.UsersReducer,
    }
}

export default connect(mapStateToProps, { getUsersFromApi, updateDealer, getGroupsFromApi, deleteUser, updateUser, activeUser, resetUserPassword })(Users);