import React, { Component } from 'react';
import { Icon, Button } from '@material-ui/core';


class ButtonAddNew extends Component {
    render() {
        const { title, history } = this.props;
        return (
            <div style={{ marginRight: 10 }}>
                <Button
                    variant="contained"
                    color="default"
                    size="small"
                    onClick={e => {
                        history.push(`${title.toLowerCase()}/form`);
                    }}>
                    <Icon style={{ fontSize: 20, marginRight: 4 }}>add</Icon>
                    Add {title}
                </Button>
            </div>
        );
    }
}

export default ButtonAddNew;