import axios from 'axios'
import { BASE_URL } from '../settings.js'
import { showNotification } from '../services/notification';

export const GET_KEYS = "GET_KEYS"
export const ADD_KEY = "ADD_KEY"
export const ADD_KEY_ERROR = "ADD_KEY_ERROR"
export const DELETE_KEY = "DELETE_KEY"
export const UPDATE_KEY = "UPDATE_KEY"
export const RESET_KEYS = "RESET_KEYS"

const url = `${BASE_URL}/keys`

export const getKeysFromApi = (token, dealerId, keySold) => {
    return async (dispatch) => {
        let params = {}
        if (dealerId) {
            params['dealerId'] = dealerId
        }
        if (keySold) {
            params['keySold'] = 0
        }
        const request = await axios.get(url, {
            headers: {
                'Authorization': token
            },
            params: params
        })
        dispatch({
            type: GET_KEYS,
            payload: request
        })
    }
}

export const addKey = (token, data, history = null) => {
    return async (dispatch) => {
        var header = { 'Authorization': token }
        await axios.post(url, data, { headers: header })
            .then((response) => {
                dispatch({ type: ADD_KEY })
                let config = {
                    icon: 'check',
                    type: 'success',
                    message: 'The Key has been created successfully',
                    autoClose: true
                }
                showNotification(config);
                if (!!history) {
                    history.push('/keys')
                }
            })
            .catch((error) => {
                dispatch({ type: ADD_KEY_ERROR })
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: 'An error has occurred. Please, try it again',
                    autoClose: true
                }
                showNotification(config)
                if (!!history) {
                    history.push('/home')
                }
            })
    }
}

export const deleteKey = (token, IdKey) => {
    return async (dispatch) => {
        await axios.delete(`${url}/${IdKey}`, {
            headers: {
                'Authorization': token,
            }
        }).then(() => {
            dispatch({
                type: DELETE_KEY,
                payload: IdKey
            })
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The Key has been deleted successfully',
                autoClose: true
            }
            showNotification(config);
        }).catch((error) => {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'An error has occurred. Please, try it again',
                autoClose: true
            }
            showNotification(config);
        })
    }
}

export const updateKey = (token, id, data, history = null) => {
    return async (dispatch) => {
        var header = { 'Authorization': token }
        await axios.put(`${url}/${id}`, data, { headers: header })
            .then((response) => {
                if (!!history) {
                    dispatch({ type: UPDATE_KEY })
                    let config = {
                        icon: 'check',
                        type: 'success',
                        message: 'The Key has been edited successfully',
                        autoClose: true
                    }
                    showNotification(config);
                    history.push('/keys')
                }
            })
            .catch((error) => {
                let errorMessage = 'An error has occurred. Please, try it again'
                let response = JSON.parse(error.request.response)
                if (response.code === "ER_DUP_ENTRY")
                    errorMessage = 'Error: Key already exists.'

                dispatch({ type: ADD_KEY_ERROR })
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: errorMessage,
                    autoClose: true
                }
                showNotification(config);
            })
    }
}

export const resetKeys = () => {
    return dispatch => {
        dispatch({
            type: RESET_KEYS
        })
    }
}