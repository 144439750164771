import { GET_PRODUTS_TYPES } from '../actions'

const initialState = {
  productsList: []
}

export default function ProductTypeReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PRODUTS_TYPES:
      return { ...state, productsList: payload }
    default:
      return state
  }
}
