import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Inventory from '../../components/Forms/Inventory';
import { getDealers, getProduct } from '../../services'
import { addInventory, updateKey, addKey, resetInventory, resetKeys, getInventoriesFromApi, getKeysFromApi } from '../../actions'
import Spinner from '../../components/Spinner';
import { showNotification } from '../../services/notification';

class InventoryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                DEALERID: '',
                PRODUCTID: '',
                QUANTITY: 0
            },
            typeForm: 'Add',
            optionsProduct: [{}],
            optionsDealers: [{}],
            keySetted: []
        }
    }

    componentDidMount() {
        if (this.props.inventories === null) {
            this.props.getInventoriesFromApi(this.props.userToken.idToken.jwtToken, this.props.viewMode === 'Dealers' ? this.props.userId : null)
            this.props.getKeysFromApi(this.props.userToken.idToken.jwtToken)
        }
        getProduct(this.props.userToken.idToken.jwtToken, true)
            .then((result) => this.setState({ optionsProduct: result }))
            .catch(() => getProduct(this.props.userToken.idToken.jwtToken, true).then((result) => this.setState({ optionsProduct: result })));
        getDealers(this.props.userToken.idToken.jwtToken)
            .then((result) => this.setState({ optionsDealers: result }))
            .catch(() => getDealers(this.props.userToken.idToken.jwtToken).then((result) => this.setState({ optionsDealers: result })));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        getProduct(this.props.userToken.idToken.jwtToken, true).then((result) => {
            this.setState({ optionsProduct: result });
        })
        getDealers(this.props.userToken.idToken.jwtToken).then((result) => {
            this.setState({ optionsDealers: result });
        })
    }


    goBack = () => {
        this.props.history.goBack()
    }

    resetKeys = (target) => {
        if (target.name === "QUANTITY") {
            let newArrayKeys = this.state.keySetted.slice(0, target.value)
            this.setState({ keySetted: newArrayKeys })
        }
    }

    getProducType = (PRODUCTID) => this.state.optionsProduct.find((item) => item.ID === Number(PRODUCTID))

    handleOnSubmit = (data) => {
        let product = this.getProducType(data.PRODUCTID)
        let repeated = false;
        for (let allIndex = 0; allIndex < data.keySetted.length && !repeated; allIndex++) {
            for (let currentIndex = allIndex + 1; currentIndex < data.keySetted.length; currentIndex++) {
                if (data.keySetted[allIndex].KEY === data.keySetted[currentIndex].KEY) {
                    repeated = true
                    break;
                }
            }
        }
        if (repeated) {
            let config = {
                icon: 'error',
                type: 'error',
                message: "You introduced a repeated key.",
                autoClose: true
            }
            showNotification(config)
            return
        }

        if (!this.props.match.params.id) {
            return this.props.addInventory(this.props.userToken.idToken.jwtToken, data, product.PRODUCTTYPE, this.props.history)
        }
    }

    onDeleteRow = (index) => {
        let { formData, keySetted } = this.state
        formData.QUANTITY -= 1
        keySetted.splice(index, 1)
        this.setState({ formData, keySetted })
    }

    render() {
        if (!!this.props.inventories && !!this.props.KeysReducer.keys)
            return (
                <Inventory
                    keySetted={this.state.keySetted}
                    initialValues={this.state.formData}
                    title={`${this.state.typeForm} Inventory`}
                    handleOnSubmit={this.handleOnSubmit}
                    optionsProduct={this.state.optionsProduct}
                    optionsDealers={this.state.optionsDealers}
                    goBack={this.goBack}
                    onDeleteRow={this.onDeleteRow}
                />
            )
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        inventories: state.InventoriesReducer.inventories,
        KeysReducer: state.KeysReducer,
        viewMode: state.AppReducer.currentViewMode,
        userId: state.Auth.id,
    }
}

export default withRouter(connect(mapStateToProps, {
    addInventory,
    updateKey,
    addKey,
    resetInventory,
    resetKeys,
    getInventoriesFromApi,
    getKeysFromApi
})(InventoryForm));