import { GET_INVENTORIES, DELETE_INVENTORY, ADD_INVENTORY, UPDATE_INVENTORY, RESET, RESET_INVENTORY } from '../actions'
import { getColumnsOfTable, deleteItemFromArray } from '../services'

export default function InventoriesReducer(state = { inventories: null, fields: null }, action) {
    switch (action.type) {
        case GET_INVENTORIES:
            const columnsName = getColumnsOfTable(action.payload.data.results[0])
            return {
                ...state,
                inventories: action.payload.data.results,
                fields: columnsName
            }
        case DELETE_INVENTORY:
            return { ...state, inventories: deleteItemFromArray(state.inventories, action.payload, 'ID') }
        case UPDATE_INVENTORY:
        case ADD_INVENTORY:
            return { ...state, fields: null, inventories: null }
        case RESET_INVENTORY:
        case RESET:
            return { ...state, fields: null, inventories: null }
        default:
            return state
    }
}