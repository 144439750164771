import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton, Icon } from '@material-ui/core';

import { getCustomersFromApi, deleteCustomer } from '../../actions';
import Generic from '../../components/Generic';
import ConfirmationModal from '../../components/ConfirmationModal';
import ShowCustomer from '../../components/Dialog/ShowCustomer';
import Spinner from '../../components/Spinner';

class Customers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            search: '',
            isActive: false,
            configPage: {
                title: 'Customers',
                buttonDownload: true,
                downloadName: 'Customers',
                buttonAddNew: true,
            },
            anchorEl: null,
            selectID: null,
            openModal: false,
            deleteRow: null
        };
    }

    componentDidMount() {
        if (this.props.CustomersReducer.fields === null) {
            this.props.getCustomersFromApi(this.props.userToken.idToken.jwtToken, this.props.viewMode === 'Dealers' ? this.props.userId : null)
                .catch(() => {
                    this.props.getCustomersFromApi(this.props.userToken.idToken.jwtToken, this.props.viewMode === 'Dealers' ? this.props.userId : null)
                        .catch(() => this.setState({ hasError: true }))
                })
        } else {
            this.makeColumns(this.props.CustomersReducer.fields)
            this.makeData(this.props.CustomersReducer.customers)
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!nextProps.CustomersReducer.fields) {
            this.makeColumns(nextProps.CustomersReducer.fields)
            this.makeData(nextProps.CustomersReducer.customers)
        }
    }

    editItem(id) {
        this.props.history.push(`${this.state.configPage.title.toLowerCase()}/form/${id}`);
    }

    deleteItem = (row) => {
        this.setState({ openModal: false });
        this.props.deleteCustomer(this.props.userToken.idToken.jwtToken, row.ID)
    }

    handleClose = () => {
        this.setState({ anchorEl: null });

        this.setState({
            selectID: Boolean(this.state.anchorEl),
        });
    }

    handleClick = (event, ID) => {
        this.setState({
            anchorEl: event.currentTarget,
        });

        this.setState({
            selectID: ID,
        });
    };

    getDeleteButton(row) {
        if (this.props.viewMode !== 'Dealers') {
            return (
                <IconButton onClick={() => {
                    this.handleClickOpenModal(row)
                }}>
                    <Icon color="error">close</Icon>
                </IconButton>
            )
        }
    }

    makeColumns(customers) {
        let arrayOfItems = [];
        customers.forEach((item) => {
            switch (item) {
                case 'EMAIL':
                    arrayOfItems.push({ Header: 'Email', accessor: item, id: item, minWidth: 220 });
                    break;
                case 'PHONE':
                    arrayOfItems.push({ Header: 'Phone', accessor: item, id: item, minWidth: 220 });
                    break;
                case 'Dealer':
                    if (this.props.viewMode !== 'Dealers')
                        arrayOfItems.push({ Header: item, accessor: item, id: item, minWidth: 220 });
                    break;
                case 'Country':
                    arrayOfItems.push({ Header: item, accessor: item, id: item, minWidth: 220 });
                    break;
                case 'COMPANY':
                    arrayOfItems.push({
                        Header: 'Company',
                        accessor: (row) => {
                            return (
                                <div>
                                    <div onClick={(e) => this.handleClick(e, row.ID)}>
                                        {row.COMPANY}<Icon style={{ padding: '4px' }}>search</Icon>
                                    </div>
                                    <ShowCustomer
                                        open={Boolean(this.state.selectID === row.ID)}
                                        onClose={this.handleClose}
                                        anchorEl={this.state.anchorEl}
                                        data={row} />
                                </div>
                            )
                        },
                        id: item,
                        minWidth: 220
                    });
                    break;
                case 'FIRSTNAME':
                    arrayOfItems.push({
                        Header: 'Name', id: "Name", minWidth: 220, accessor: d =>
                            <div> {d.FIRSTNAME + " " + d.LASTNAME}</div>
                    }); break;
                default: break;
            }
        })
        arrayOfItems.push({
            minWidth: 120,
            Header: 'Actions',
            style: { textAlign: "center" },
            Cell: row => {
                return (<div>
                    <IconButton color='primary' href={`mailto:${row.EMAIL}`} >
                        <Icon>mail_outline</Icon>
                    </IconButton>
                    <IconButton onClick={() => {
                        this.editItem(row.ID)
                    }}>
                        <Icon>edit</Icon>
                    </IconButton>
                    {this.getDeleteButton(row)}
                </div>
                )
            }
        })
        this.setState({ columns: arrayOfItems })
    }

    makeData(dataOfCustomers) {
        this.setState({ content: dataOfCustomers })
    }

    onInputValueChange = ({ target }) => {
        this.setState({ search: target.value.toLowerCase() })
    }

    handleClickOpenModal = (row) => {
        this.setState({ openModal: true, deleteRow: row });
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
    };

    render() {
        if (!!this.props.CustomersReducer.fields) {
            let data = this.props.CustomersReducer.customers
            if (this.state.search) {
                data = data.filter(row => {
                    const array = Object.keys(row)
                    let isIncluded = false
                    array.forEach((columnName) => {
                        if (!isIncluded) {
                            if (typeof row[columnName] === 'string') {
                                if ((row[columnName].toLowerCase()).includes(this.state.search.toLowerCase())) {
                                    isIncluded = true
                                }
                            }
                            else if ((String(row[columnName]).toLowerCase()).includes(this.state.search.toLowerCase())) {
                                isIncluded = true
                            }
                        }
                    })
                    if (isIncluded)
                        return true
                    return false;
                })
            }
            return (
                <div>
                    <ConfirmationModal title={'Customer'} deleteRow={this.state.deleteRow} handleCloseModal={this.handleCloseModal} openModal={this.state.openModal} deleteItem={this.deleteItem} />
                    <Generic
                        data={data}
                        columns={this.state.columns}
                        onInputValueChange={this.onInputValueChange}
                        hasError={this.state.hasError}
                        configPage={this.state.configPage}
                        history={this.props.history} />
                </div>
            );
        } else if (this.state.hasError)
            return <Generic hasError={this.state.hasError} />
        else
            return <Spinner />
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        CustomersReducer: state.CustomersReducer,
        viewMode: state.AppReducer.currentViewMode,
        userId: state.Auth.id
    }
}

export default connect(mapStateToProps, { getCustomersFromApi, deleteCustomer })(Customers);