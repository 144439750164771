import React from 'react'
import PropTypes from "prop-types"

import { FormControlLabel, Checkbox, Button, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, TablePagination, } from '@material-ui/core'
import ButtonDownload from './ButtonDownload'
import SearchBar from './ToolBars/SearchBar'
import ButtonAddNew from './ButtonAddNew'
import './responsive.css'

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {
        maxHeight: 590,
        overflow: 'auto',
    }, tablecell: {
        align: 'right',
        padding: '4px 8px'
    }
})

const Generic = (props) => {
    const classes = useStyles()
    const { onInputValueChange, data, columns, configPage, history, showSold, hasError } = props
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    if (hasError) {        
        return <h1>Something went wrong.</h1>
    }
    return (
        <div style={{ padding: '1rem' }}>
            <div className={classes.root}>
                <h1>{configPage.title}</h1>
                <Grid container>
                    <Grid container item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center'}}>
                        {configPage.buttonAddNew ? <ButtonAddNew title={configPage.title} history={history} /> : null}
                        {configPage.buttonDownload ? <ButtonDownload data={data} columns={columns} fileName={configPage.downloadName} /> : null}
                        {configPage.buttonImportCSV && <div style={{ marginLeft: 10 }}><Button onClick={() => { history.push('/importProducts') }} variant="contained" color="default" size="small" >Import CSV</Button></div>}
                        {history.location.pathname === '/keys' &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{ marginLeft: 20 }}
                                        name="showSold"
                                        checked={configPage.showSold}
                                        onChange={showSold}
                                        color="primary"
                                    />
                                } label="Show Sold" />}
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                        <SearchBar search={data.search} onInputValueChange={onInputValueChange} />
                    </Grid>
                </Grid>
                <Paper className={classes.root} style={{ marginTop: '1rem' }}>
                    <div className={classes.tableWrapper}>
                        {props.children}
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns && columns.map((row, index) => {
                                        if (row.Header !== "Actions") {
                                            return <TableCell className={classes.tablecell} key={index}>{row.Header}</TableCell>
                                        }
                                        return <TableCell className={classes.tablecell} key={index} />
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : data).map((row, index) => {
                                        let cells = []
                                        for (const item in columns) {
                                            if (columns.hasOwnProperty(item)) {
                                                const column = columns[item]
                                                let value
                                                if (column.hasOwnProperty('accessor')) {
                                                    if (typeof column.accessor === 'function') {
                                                        value = column.accessor(row)
                                                    } else {
                                                        value = row[column.accessor]
                                                    }
                                                } else if (column.hasOwnProperty('Cell')) {
                                                    if (typeof column.Cell === 'function') {
                                                        value = column.Cell(row)
                                                    }
                                                } else {
                                                    value = row['id']
                                                }
                                                cells.push(<TableCell className={classes.tablecell} key={item}>{value}</TableCell>)
                                            }
                                        }
                                        return (<TableRow key={index}>
                                            {cells}
                                        </TableRow>)
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        style={{ marginLeft: -15 }}
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{ 'aria-label': 'previous page' }}
                        nextIconButtonProps={{ 'aria-label': 'next page' }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div >
        </div>
    )
}

Generic.propTypes = {
    onInputValueChange: PropTypes.func
};
export default Generic
