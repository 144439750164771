import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Form, } from 'redux-form'
import { withRouter } from 'react-router-dom';
import { Grid, FormControlLabel, RadioGroup, Radio, Paper, TextField, Typography, Button, Container } from '@material-ui/core';

import ReviewReportView from '../../../components/Common/ReviewReportView'
import TableReportViewOrders from '../../../components/PlaceNewOrder/TableReportViewOrders'
import FeDex_Logo from '../../../resources/Images/logo-fedex.jpg';

class ReviewReport extends Component {

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.props.calcTotalPrice(nextProps)
    }

    render() {
        const { notes, totalPrice, fedexShipping, handleSubmit, disabled, otherShipping, onInputValueChange, handleOnSubmit, previousPage, submitting } = this.props
        return (
            <Container>
                <Form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container>
                        <Grid item xs={12}>
                            {!!this.props.PlaceOrderReducer.userData &&
                                <div>
                                    <ReviewReportView userData={this.props.DealerInfo.data} title={'Shipping to'} />
                                    <ReviewReportView userData={this.props.PlaceOrderReducer.userData} title={'End user'} />
                                </div>}
                            {!!this.props.PlaceOrderReducer.productsSelected &&
                                <TableReportViewOrders productsSelected={this.props.PlaceOrderReducer.productsSelected} totalPrice={totalPrice} />}
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 20 }}>
                            <Paper style={{ padding: '10px' }}>
                                <Grid container>
                                    <Grid item xs={5} style={{ paddingTop: 20 }}>
                                        <img src={FeDex_Logo} style={{ width: '20%' }} alt="Logo FeDex" />
                                        <RadioGroup
                                            name="fedexShipping"
                                            value={fedexShipping}
                                            onChange={onInputValueChange}
                                        >
                                            <FormControlLabel value="FedEx Ground" control={<Radio color="primary" style={{ padding: '3px 12px 0 12px' }} />} label="FedEx Ground" />
                                            <FormControlLabel value="FedEx Two Day" control={<Radio color="primary" style={{ padding: '3px 12px 0 12px' }} />} label="FedEx Two Day" />
                                            <FormControlLabel value="FedEx Overnight" control={<Radio color="primary" style={{ padding: '3px 12px 0 12px' }} />} label="FedEx Overnight" />
                                            <FormControlLabel value="FedEx International Priority" control={<Radio style={{ padding: '3px 12px 0 12px' }} color="primary" />} label="FedEx International Priority" />
                                            <FormControlLabel value="FedEx International Economy" control={<Radio style={{ padding: '3px 12px 0 12px' }} color="primary" />} label="FedEx International Economy" />
                                            <FormControlLabel value="other" control={<Radio color="primary" style={{ padding: '3px 12px 0 12px' }} />} label="Other" />
                                        </RadioGroup>
                                        <TextField
                                            id="outlined-textarea"
                                            multiline
                                            name="otherShipping"
                                            disabled={disabled}
                                            placeholder="Enter freight forwarder name"
                                            value={otherShipping}
                                            onChange={onInputValueChange}
                                            margin="normal"
                                            variant="outlined" />
                                    </Grid>
                                    <Grid item xs={7} style={{ paddingTop: 20 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Notes
                                        </Typography>
                                        <TextField
                                            style={{ width: "90%" }}
                                            id="outlined-textarea"
                                            name="notes"
                                            rows="3"
                                            multiline
                                            value={notes}
                                            onChange={onInputValueChange}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={submitting} />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <div>
                        <center style={{ marginTop: 10 }}>
                            <Button type="button" onClick={previousPage} disabled={submitting}>
                                Previous
                            </Button>
                            <Button type="submit" disabled={submitting} variant="contained"
                                color="primary">
                                Submit
                            </Button>
                        </center>
                    </div>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        PlaceOrderReducer: state.PlaceOrderReducer,
        DealerInfo: state.DealerInfo
    }
}

export default withRouter(connect(mapStateToProps)(reduxForm({
    form: 'OrderReviewReportForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
})(ReviewReport)))