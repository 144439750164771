import React, { Fragment } from 'react'
import { TextField, Select, FormControl, FormControlLabel, RadioGroup, Input, Chip, Checkbox, InputLabel } from '@material-ui/core'
import './responsive.css';

export const renderTextField = ({
    label,
    input,
    meta,
    ...custom
}) => {
    return (
        <TextField
            style={{
                width: '100%',
                textTransform: 'capitalize'
            }}
            label={label}
            placeholder={label}
            error={meta.touched && meta.invalid}
            helperText={meta.touched && meta.error}
            validators={['required']}
            errormessages={['this field is required']}
            {...input}
            {...custom}
        />
    )
}

export const renderCheckbox = ({
    label,
    input,
    meta,
    ...custom
}) => {
    return (
        <div>
            <FormControlLabel style={{ marginLeft: 1 }}
                control={
                    <Checkbox
                        color={"primary"}
                        checked={input.value ? true : false}
                        onChange={input.onChange}
                    />}
                label={label}
            />
        </div>
    )
}

export const renderSelectField = (props) => {
    const { input, label, meta: { touched, error }, children, ...custom } = props
    let err = touched && error
    return (
        <Fragment>
            <FormControl error={err} style={{
                width: '100%',
                margin: '8px 15px 0px 0px',
                textTransform: 'capitalize',
            }}>
                <InputLabel className="style" id="demo-mutiple-chip-label">{label}</InputLabel>
                <Select
                    {...input}
                    {...custom}>
                    {children}
                </Select>
            </FormControl>
        </Fragment>
    )
}

export const renderSelectMultipleField = (props) => {
    const { input, label, meta: { touched, error }, children, properties: { id }, ...custom } = props
    return (
        <FormControl error={touched && error} style={{
            width: '100%',
            marginRight: 15,
            textTransform: 'capitalize'
        }}>
            <InputLabel className="style" id="demo-mutiple-chip-label">{label}</InputLabel>
            <Select
                {...input}
                multiple
                label={label}
                input={<Input id={id} />}
                // error={touched && invalid}
                // helperText={touched && error}
                // validators={['required']}
                // errorMessages={['this field is required']}
                {...custom}
                renderValue={selected => {
                    return selected.map(values => <Chip key={values} label={values} />)
                }}
            >
                {children}
            </Select>
        </FormControl>
    )
}

export const renderRadioButtonGroup = (props) => {
    const { input, children, rest } = props
    return (
        <FormControl style={{ display: 'inline-block' }}>
            <RadioGroup {...input} {...rest}>
                {children}
            </RadioGroup>
        </FormControl>
    )
}
