import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Popover, Icon } from '@material-ui/core';
var dateFormat = require('dateformat');

const styles = theme => ({
    content: {
        padding: "25px"
    },
    title: {
        marginTop: 0,
        borderBottom: "1px solid"
    },
    subTitle: {
        marginBottom: 0
    },
    bold: {
        fontWeight: "bolder"
    }
});

class ShowCustomer extends Component {
    render() {
        const { open, onClose, anchorEl, classes, data } = this.props;
        return (
            <div>
                <Popover
                    id="simple-popper"
                    open={open}
                    onClose={onClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.content}>
                        <h2 className={classes.title}>{data.COMPANY}</h2>
                        <div><a href={(data.WEBSITE && data.WEBSITE.includes('http')) ? data.WEBSITE : `//${data.WEBSITE}`} target="_blank" rel="noopener noreferrer">{(data.WEBSITE === 'none') ? '' : data.WEBSITE}</a></div>
                        <div><a href={`mailto:${data.EMAIL}`}><Icon style={{ margin: '5px 5px -3px 0', fontSize: '17px' }}>email</Icon>{data.EMAIL}</a></div>
                        <h4 className={classes.subTitle}>{`${data.SALUTATION} ${data.FIRSTNAME} ${data.LASTNAME}`}</h4>
                        <div>{data.JOBTITLE}</div>
                        <div>{data.ROLE}</div>
                        <h4 className={classes.subTitle}>Address</h4>
                        <div>{data.ADDRESS1}</div>
                        <div>{data.CITY_STATEPROVINCE}</div>
                        <div>{data.POSTALCODE}</div>
                        <div>{data.COUNTRY}</div>
                        <div>{data.LOCATIONTYPE}</div>
                        <br />
                        <div><span className={classes.bold}>Phone: </span>{data.PHONE}</div>
                        <div><span className={classes.bold}>Date Created: </span><span >{dateFormat(data.DATEADDED, "fullDate")}</span></div>
                    </div>
                </Popover>
            </div>
        );
    }
}

export default withStyles(styles)(ShowCustomer);