import React, { Component } from 'react';
import { connect } from 'react-redux';
import Generic from '../../components/Generic';
import ConfirmationModal from '../../components/ConfirmationModal';
import { getDealersFromApi, deleteDealer } from '../../actions/Dealers';
import { IconButton, Icon } from '@material-ui/core';
import Spinner from '../../components/Spinner';

class Dealers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            search: '',
            configPage: {
                title: 'Dealers',
                buttonDownload: true,
                downloadName: 'Dealers',
                buttonAddNew: true,
            },
            openModal: false,
            deleteRow: null
        };
    }

    componentDidMount() {
        if (!this.props.DealersReducer.fields) {
            this.props.getDealersFromApi(this.props.userToken.idToken.jwtToken)
                .catch(() => {
                    this.props.getDealersFromApi(this.props.userToken.idToken.jwtToken)
                        .catch(() => this.setState({ hasError: true }))
                })
        } else {
            this.makeColumns(this.props.DealersReducer.fields)
            this.makeData(this.props.DealersReducer.dealers)
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!nextProps.DealersReducer.fields) {
            this.makeColumns(nextProps.DealersReducer.fields)
            this.makeData(nextProps.DealersReducer.dealers)
        }
    }

    editItem(id) {
        this.props.history.push(`${this.state.configPage.title.toLowerCase()}/form/${id}`);
    }

    deleteItem = (row) => {
        this.setState({ openModal: false });
        this.props.deleteDealer(this.props.userToken.idToken.jwtToken, row.DealerID)
    }

    makeColumns(dealers) {
        let arrayOfItems = []
        dealers.forEach((item) => {
            switch (item) {
                case 'PHONE': arrayOfItems.push({
                    Header: 'Phone', accessor: item =>
                        item.contactData.find(data => !!data.isMain).PHONE
                    , id: item, minWidth: 220
                }); break;
                case 'WEBSITE': arrayOfItems.push({
                    Header: 'Website', accessor: item =>
                        item.contactData.find(data => !!data.isMain).WEBSITE
                    , id: item, minWidth: 220
                }); break;
                case 'COMPANY': arrayOfItems.push({ Header: 'Company', accessor: item, id: item, minWidth: 300 }); break;
                case 'CountryName': arrayOfItems.push({
                    Header: 'Country', accessor: d =>
                        (!!d.contactData[0]['CountryName'])
                            ? [d.contactData.map(item => item.CountryName)].join(', ')
                            : null
                    , id: item, minWidth: 220
                }); break;
                case 'COGNITO_ID':
                    arrayOfItems.unshift({
                        Header: 'User', id: item,
                        Cell: row => !!row.COGNITO_ID
                            ? <Icon value={true} color="secondary">check</Icon>
                            : <Icon value={false} color="error">clear</Icon>
                        , style: { textAlign: "center" }
                    });
                    break;
                case 'FIRSTNAME':
                    arrayOfItems.push({
                        Header: 'Name', id: "Name", accessor: item => {
                            const { FIRSTNAME, LASTNAME } = item.contactData.find(data => !!data.isMain)
                            return <div>{FIRSTNAME + " " + LASTNAME}</div >
                        }, minWidth: 220
                    }); break;
                default: break;
            }
        })
        arrayOfItems.push({
            minWidth: 180,
            Header: 'Actions',
            style: { textAlign: "center" },
            Cell: row => {
                return (<div>
                    <IconButton color='primary' href={`mailto:${row.contactData[0].EMAIL}`}>
                        <Icon>mail_outline</Icon>
                    </IconButton>
                    <IconButton onClick={() => {
                        this.editItem(row.DealerID)
                    }}>
                        <Icon>edit</Icon>
                    </IconButton>
                    <IconButton onClick={() => {
                        this.handleClickOpenModal(row)
                    }}>
                        <Icon color="error">close</Icon>
                    </IconButton>
                </div>
                )
            }
        })
        this.setState({ columns: arrayOfItems })
    }

    makeData(dataOfDealers) {
        this.setState({ content: dataOfDealers })
    }

    onInputValueChange = ({ target }) => {
        this.setState({ search: target.value.toLowerCase() })
    }

    handleClickOpenModal = (row) => {
        this.setState({ openModal: true, deleteRow: row });
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
    };

    render() {
        if (!!this.props.DealersReducer.fields) {
            let data = this.props.DealersReducer.dealers
            if (this.state.search) {
                data = data.filter(row => {
                    const array = Object.keys(row)
                    let isIncluded = false
                    array.forEach((columnName) => {
                        if (!isIncluded) {
                            if (typeof row[columnName] === 'string') {
                                if ((row[columnName].toLowerCase()).includes(this.state.search.toLowerCase())) {
                                    isIncluded = true
                                }
                            }
                            else if ((String(row[columnName]).toLowerCase()).includes(this.state.search.toLowerCase())) {
                                isIncluded = true
                            }
                        }
                    })
                    if (isIncluded)
                        return true
                    return false;
                })
            }
            return (
                <div>
                    <ConfirmationModal title={'Dealer'} deleteRow={this.state.deleteRow} handleCloseModal={this.handleCloseModal} openModal={this.state.openModal} deleteItem={this.deleteItem} />
                    <Generic
                        data={data}
                        columns={this.state.columns}
                        onInputValueChange={this.onInputValueChange}
                        hasError={this.state.hasError}
                        configPage={this.state.configPage}
                        history={this.props.history} />
                </div>
            );
        } else if (this.state.hasError)
            return <Generic hasError={this.state.hasError} />
        else
            return <Spinner />

    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        DealersReducer: state.DealersReducer
    }
}

export default connect(mapStateToProps, { getDealersFromApi, deleteDealer })(Dealers); 