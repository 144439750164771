import { RESET, SET_DEALER_INFO, UPDATE_FEDEX_DEALERS } from '../actions'

export default function DealerInfoReducer(state = { data: null }, action) {

    switch (action.type) {
        case SET_DEALER_INFO:
            return { ...state, data: action.payload.data[0] }
        case RESET:
            return { ...state, data: null }
        case UPDATE_FEDEX_DEALERS:
            return { ...state, data: { ...state.data, FEDEXNUMBER: action.payload.FEDEXNUMBER } }
        default:
            return state
    }
}