import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, MenuItem, Menu } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import './responsive.css';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    header: {
        backgroundColor: "#36363c"
    },
    background: {
        textDecoration: "none", color: '#adacb4',
        '&$tabSelected': {
            color: '#00bcd4',
        }
    }
}));

export default function MenuAppBar(props) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleMenu = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const renderMenuItems = () => {
        let arrayMenus = []
        props.menus.forEach((item, index) => {
            arrayMenus.push(<MenuItem onClick={handleClose} key={index}><a href={item.link} className={classes.background}>{item.title.toUpperCase()}</a></MenuItem>)
        })
        return arrayMenus
    }

    return (
        <div className={classes.root} style={{ width: "100%" }} >
            <AppBar position="static" className={classes.header}>
                <Toolbar variant="dense"  >
                    <IconButton edge="start" className={classes.background}
                        aria-label="menu"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit">
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        {renderMenuItems()}
                    </Menu>
                </Toolbar>
            </AppBar>
        </div>
    );
}