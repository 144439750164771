import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Stepper, Step, StepLabel, Button, Grid, Paper, Icon, Container } from '@material-ui/core';

import {
    submitSaleReport,
    getCustomersFromApi,
    getKeysFromApi,
    insertUserFromReportSale,
    getInventoriesFromApi,
    saveProductsSelectedFromReportSale,
    updateCustomer,
    addCustomer
} from '../../actions';
import SelectUserForm from './FormSteps/SelectUserForm';
import SelectProduct from './FormSteps/SelectProduct';
import ReviewReport from './FormSteps/ReviewReport';

class ReportSale extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: 'Report a Sale',
            totalPrice: null,
            notes: '',
            isButtonDisabled: false,
            page: 0
        };
        this.goBack = this.goBack.bind(this);
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
    }

    componentDidMount() {
        if (!this.props.CustomersReducer.fields) {
            this.props.getCustomersFromApi(this.props.userToken.idToken.jwtToken, this.props.userId).catch(() => {
                this.props.getCustomersFromApi(this.props.userToken.idToken.jwtToken, this.props.userId).catch(() =>
                    this.setState({ hasError: true }))
            })
        }
        if (!this.props.KeysReducer.keys) {
            this.props.getKeysFromApi(this.props.userToken.idToken.jwtToken, this.props.userId, 1)
                .catch(() => {
                    this.props.getKeysFromApi(this.props.userToken.idToken.jwtToken, this.props.userId, 1)
                        .catch(() => this.setState({ hasError: true }))
                })
        }
        if (!this.props.InventoriesReducer.fields)
            this.props.getInventoriesFromApi(this.props.userToken.idToken.jwtToken, this.props.userId).catch(() => this.setState({ hasError: true }))
    }

    getSteps() {
        return ['Select User', 'Select Product', 'Review Report'];
    }

    handleOnSubmit = async () => {
        let user = { ...this.props.ReportSaleReducer.userData }
        if (this.props.ReportSaleReducer.userData) {
            if (!!user.ID && user.ID !== '0') {
                if (this.props.ReportSaleReducer.userChanged !== false) {
                    let id = user.ID;
                    delete user.ID
                    try {
                        let userName = this.props.viewMode === 'Dealers' ? this.props.dealerInfo.data.COMPANY : this.props.username ? this.props.username : 'Admin'
                        await this.props.updateCustomer(this.props.userToken.idToken.jwtToken, this.props.ReportSaleReducer.userData.ID, user, userName)
                    } catch (error) {
                        console.log(error)
                    }
                    user['ID'] = id
                }
            } else {
                try {
                    let res = await this.props.addCustomer(this.props.userToken.idToken.jwtToken, this.props.ReportSaleReducer.userData)
                    user.ID = res
                } catch (error) {
                    throw new Error('Error adding the user')
                }
            }
        }
        try {
            let saleData = {
                dealer: this.props.dealerInfo.data,
                customer: user,
                total: this.state.totalPrice,
                notes: this.state.notes,
                saleItems: []
            }
            for (const key in this.props.ReportSaleReducer.productsSelected) {
                if (this.props.ReportSaleReducer.productsSelected.hasOwnProperty(key)) {
                    const element = this.props.ReportSaleReducer.productsSelected[key];
                    let saleItem = {
                        PRODUCTID: element.PRODUCTID,
                        KEYID: element.keyId,
                        SERIAL: element.key,
                        PRICE: element.PRICE,
                        RHINOKEY: (element.RHkey) ? element.RHkey : '',
                        RHINOKEYID: (element.RHkeyId) ? element.RHkeyId : '',
                        OPTIONS: element.OPTIONS,
                        TITLE: element.TITLE,
                        PARTNUMBER: element.PARTNUMBER
                    }
                    saleData.saleItems.push(saleItem)
                }
            }

            this.props.saveProductsSelectedFromReportSale([])
            return this.props.submitSaleReport(this.props.userToken.idToken.jwtToken, saleData, this.props.history)
        } catch (error) {
            console.log(error)
        }
    }

    nextPage() {
        this.setState({ page: this.state.page + 1 })
    }

    previousPage() {
        this.setState({ page: this.state.page - 1 })
    }

    calcTotalPrice = (nextProps) => {
        let totalPrice = 0
        if (nextProps.ReportSaleReducer.productsSelected) {
            nextProps.ReportSaleReducer.productsSelected.map(item => totalPrice += item.PRICE)
        }
        totalPrice = Math.round(totalPrice * 100) / 100
        this.setState({ totalPrice })
    }

    onInputValueChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };

    goBack() {
        this.props.history.goBack();
    }

    render() {
        const { page } = this.state
        const steps = this.getSteps();
        return (
            <React.Fragment>
                <Container style={{ padding: 20 }}>
                    <Paper>
                        <Grid container style={{
                            backgroundColor: '#fff',
                            textAlign: 'center',
                        }}>
                            <Grid item xs={12} sm={4}>
                                <h2>{this.state.title}</h2>
                            </Grid>
                            <Grid item xs={10} sm={7}>
                                <Stepper style={{ padding: '10px' }} activeStep={page} alternativeLabel>
                                    {steps.map(label => {
                                        return (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Grid>
                            <Grid item xs={2} sm={1} style={{
                                marginTop: 15,
                                justifyContent: 'flex-end'
                            }} >
                                <Button
                                    color="default"
                                    size="small"
                                    onClick={this.goBack}
                                >
                                    <Icon>clear</Icon>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
                <div>
                    {page === 0 && <Container>
                        <SelectUserForm onSubmit={this.nextPage}
                            actionToSaveUserData={this.props.insertUserFromReportSale}
                            userData={this.props.ReportSaleReducer.userData}
                            isReportSale />
                    </Container>}
                    {page === 1 && (
                        <SelectProduct
                            products={this.props.InventoriesReducer.inventories}
                            actionToSaveProductsSelectData={this.props.saveProductsSelectedFromReportSale}
                            productsSelected={this.props.ReportSaleReducer.productsSelected}
                            previousPage={this.previousPage}
                            onSubmit={this.nextPage}
                        />
                    )}
                    {page === 2 && (
                        <ReviewReport
                            products={this.props.InventoriesReducer.inventories}
                            notes={this.state.notes}
                            totalPrice={this.state.totalPrice}
                            calcTotalPrice={this.calcTotalPrice}
                            onInputValueChange={this.onInputValueChange}
                            previousPage={this.previousPage}
                            handleOnSubmit={this.handleOnSubmit}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.Auth.signedInUser.signInUserSession,
        CustomersReducer: state.CustomersReducer,
        KeysReducer: state.KeysReducer,
        InventoriesReducer: state.InventoriesReducer,
        ReportSaleReducer: state.ReportSaleReducer,
        userId: state.Auth.id,
        username: state.Auth.userName,
        dealerInfo: state.DealerInfo
    }
}

export default withRouter(connect(mapStateToProps, {
    getInventoriesFromApi,
    getCustomersFromApi,
    getKeysFromApi,
    insertUserFromReportSale,
    saveProductsSelectedFromReportSale,
    submitSaleReport,
    updateCustomer,
    addCustomer
})(ReportSale));