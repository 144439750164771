import axios from 'axios'
import { BASE_URL } from '../settings.js'
import { showNotification } from '../services/notification'

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR'

const url = `${BASE_URL}/customers`

export const getCustomersFromApi = (token, id) => {
    return async (dispatch) => {
        let header = {
            headers: {
                'Authorization': token,
            }
        }
        if (id) {
            header['params'] = { dealerId: id }
        }
        const request = await axios.get(url, header)
        dispatch({
            type: GET_CUSTOMERS,
            payload: request
        })
    }
}

export const deleteCustomer = (token, IdCustomer) => {
    return async (dispatch) => {
        await axios.delete(`${url}/${IdCustomer}`, {
            headers: {
                'Authorization': token,
            }
        }).then(() => {
            dispatch({
                type: DELETE_CUSTOMER,
                payload: IdCustomer
            })
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The Customer has been deleted successfully',
                autoClose: true
            }
            showNotification(config);
        }).catch((error) => {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'An error has occurred. Please, try it again',
                autoClose: true
            }
            showNotification(config);
        })
    }
}

export const addCustomer = (token, data, history = null) => {
    return async (dispatch) => {
        return new Promise((resolve) => {
            axios.post(url,
                data, { headers: { 'Authorization': token } })
                .then((result) => {
                    dispatch({ type: ADD_CUSTOMER })
                    let config = {
                        icon: 'check',
                        type: 'success',
                        message: 'The Customer has been created successfully',
                        autoClose: true
                    }
                    showNotification(config);
                    if (!!history) {
                        history.push('/customers');
                    } else {
                        resolve(result.data.results.insertId)
                    }
                })
                .catch((error) => {
                    let config = {
                        icon: 'error',
                        type: 'error',
                        message: 'An error has occurred. Please, try it again',
                        autoClose: true
                    }
                    showNotification(config);
                    if (!!history) {
                        history.push('/home');
                    } else {
                        dispatch({ type: CUSTOMER_ERROR, payload: error })
                        throw new Error('error updating customer')
                    }

                })
        })
    }
}

export const updateCustomer = (token, id, data, UserName = 'not defined', history = null) => {
    return async (dispatch) => {
        data['changedBy'] = UserName
        data.ID = Number(id)
        await axios.put(`${url}/${id}`,
            data, { headers: { 'Authorization': token } })
            .then(() => {
                dispatch({
                    type: UPDATE_CUSTOMER,
                    payload: data
                })
                let config = {
                    icon: 'check',
                    type: 'success',
                    message: 'The Customer has been edited successfully',
                    autoClose: true
                }
                showNotification(config);
                if (!!history) {
                    history.push('/customers');
                }
            })
            .catch((error) => {
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: 'An error has occurred. Please, try it again',
                    autoClose: true
                }
                showNotification(config);
                if (!!history) {
                    history.push('/home')
                } else {
                    dispatch({ type: CUSTOMER_ERROR, payload: error })
                    throw new Error('error updating customer')
                }
            })
    }
}