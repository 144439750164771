import React, { Component } from 'react';
import { withStyles, Select, MenuItem, Paper, Icon, IconButton, Grid, Container, Button } from "@material-ui/core";
import { reduxForm, Form } from 'redux-form'

import { showNotification } from '../../services/notification'

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    select: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    textfield: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    iconbutton: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
});

class SelectProductView extends Component {

    onClickSubmit = () => {
        const { handleOnSubmit, isProductsToAddEmpty, hasMissingKey } = this.props;
        if (!isProductsToAddEmpty()) {
            if (!hasMissingKey()) {
                handleOnSubmit()
            } else {
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: 'One or more products have missing keys',
                    autoClose: true
                }
                showNotification(config);
            }
        }
        else {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'No products selected',
                autoClose: true
            }
            showNotification(config);
        }
    }

    render() {
        const { classes } = this.props;
        const { products, valueOfQuantity, handleSubmit, onSelectValueChange, onInputValueChange, valueOfSelectProduct, handlerOnClickAddProduct, quantityOptions, Beforepage } = this.props
        return (
            <Container style={{ marginTop: 10 }}>
                <Form onSubmit={handleSubmit(this.onClickSubmit)} >
                    <Paper>
                        <Grid container style={{ padding: 20, alignItems: 'center' }}>
                            <Grid container item justify='flex-start' xs={4}>
                                <Grid container item xs={12} style={{ marginBottom: 5 }}>
                                    <p style={{ marginRight: 20 }}>Select Product: </p>
                                    <Select
                                        className={classes.select}
                                        value={valueOfSelectProduct}
                                        // selectprops={{ native: true }}
                                        onChange={onSelectValueChange}>
                                        {products ? products.map((item, index) => {
                                            return (<MenuItem key={index} value={item}>{item.TITLE}</MenuItem>)
                                        }) : null}
                                    </Select>
                                </Grid>
                                <Grid container item xs={12} >
                                    <p style={{ marginRight: 20 }}>Quantity Avaible: ({(valueOfSelectProduct) ? quantityOptions.length : null})</p>
                                    <Select
                                        className={classes.select}
                                        value={valueOfQuantity}
                                        // selectprops={{ native: true }}
                                        onChange={onInputValueChange}
                                    >
                                        {quantityOptions && quantityOptions.map((item, index) =>
                                            <MenuItem key={index} value={item}>{item}</MenuItem>)}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container item justify='flex-start'>
                                <IconButton onClick={(e) => handlerOnClickAddProduct(valueOfSelectProduct, valueOfQuantity)} className={classes.iconbutton}>
                                    <Icon>add</Icon>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                    {this.props.children}
                    <div>
                        <center style={{ marginTop: 10 }}>
                            <Button type="button" onClick={Beforepage}>
                                Previous
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                Next
                            </Button>
                        </center>
                    </div>
                </Form>
            </Container>
        );
    }
}

export default withStyles(styles)(reduxForm({
    form: 'pagination', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(SelectProductView))







