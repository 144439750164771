import React, { Component } from 'react'
import { Field, reduxForm, Form } from 'redux-form'
import { withStyles, Paper, Button, Grid, Typography, FormControlLabel, FormLabel, Radio, MenuItem } from "@material-ui/core"

import { orderBy } from '../../services'
import { renderTextField, renderSelectField, renderCheckbox, renderRadioButtonGroup } from './CommonInputs'
import { required, requiredNumber } from './validator'

const styles = theme => ({
    wrapper: {
        padding: '20px',
        boxSizing: 'border-box'
    },
    boxCard: {
        padding: '20px',
        boxSizing: 'border-box'
    },
    selectContainer: {
        borderBottom: '1px dashed #9f9f9f',
        paddingBottom: '20px',
        marginBottom: '15px'
    },
    textField: {
        margin: theme.spacing(),
        width: '100%',
    },
    radioButtonContainer: {
        marginTop: '15px',
        margin: theme.spacing(),
    },
    radioButton: {
        flexDirection: 'row',
        width: 'auto',
    },
    button: {
        marginTop: 15,
        margin: theme.spacing(),
    },
    buttonUpload: {
        width: '100%',
        marginTop: 15
    }, position: {
        marginRight: 25
    },
    label: {
        marginTop: theme.spacing(),
    }
})

class Product extends Component {

    render() {
        const {
            handleSubmit,
            classes,
            title,
            optionsProductType,
            goBack,
            handleOnSubmit,
            pristine,
            submitting,
            initialValues,
        } = this.props
        return (
            <div className={classes.wrapper}>
                <Paper className={classes.boxCard}>
                    <Grid container className={classes.selectContainer} >
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                    </Grid >

                    <Form onSubmit={handleSubmit(handleOnSubmit)} initialvalues={initialValues} >
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={12} style={{ padding: '0 20px' }} >
                                <Field
                                    name="TITLE"
                                    label="Title"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text"
                                    validate={[required]} />
                                <Field
                                    name="PARTNUMBER"
                                    label="Part Number"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text"
                                    validate={[required]} />
                                <Field
                                    name="PRICE"
                                    label="Price"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="number" />
                                <Field
                                    name="PRODUCTTYPE"
                                    label="Product Type"
                                    className={classes.textField}
                                    // selectprops={{ native: true }}
                                    component={renderSelectField}
                                    validate={[requiredNumber]}>
                                    {orderBy(optionsProductType, "LABEL")}
                                    <MenuItem></MenuItem>
                                    {optionsProductType.map((option, index) => (
                                        <MenuItem key={index} value={option.ID}>
                                            {option.LABEL}
                                        </MenuItem>
                                    ))}
                                </Field>
                                <Field
                                    name="VERSION"
                                    label="Version"
                                    className={classes.textField}
                                    component={renderTextField}
                                    type="text" />
                                <FormControlLabel control={
                                    <Field name="ISKEY" component={renderCheckbox} label="This product is an additional key." type="checkbox" color="primary" />
                                } />
                                <FormControlLabel control={
                                    <Field name="AVAILABLEFORSTOCK" component={renderCheckbox} label="This product can be ordered for stock inventory." type="checkbox" color="primary" />
                                } />
                                <FormLabel component="legend" className={classes.label}>This product required</FormLabel>
                                <Field name="OPTIONS" className={classes.radioButton} component={renderRadioButtonGroup}>
                                    <FormControlLabel value="none" control={<Radio color="primary" />} label="None" />
                                    <FormControlLabel value="rhinoProof" control={<Radio color="primary" />} label="Rhino Proof" />
                                    <FormControlLabel value="soldWithRhino" control={<Radio color="primary" />} label="Sold with Rhino" />
                                </Field>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Country Availability
                                </Typography>
                                <FormControlLabel control={
                                    <Field name="USAVAILABLE" component={renderCheckbox} label="US" type="checkbox" color="primary" />
                                } />
                                <FormControlLabel control={
                                    <Field name="UKAVAILABLE" component={renderCheckbox} label="UK" type="checkbox" color="primary" />
                                } />
                                <FormControlLabel control={
                                    <Field name="AUAVAILABLE" component={renderCheckbox} label="Australia" type="checkbox" color="primary" />
                                } />
                                <FormControlLabel control={
                                    <Field name="ELSEAVAILABLE" component={renderCheckbox} label="Everywhere Else" type="checkbox" color="primary" />
                                } />
                            </Grid>
                            <Grid container >
                                <Grid container item xs={12} justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="large"
                                        className={classes.button}
                                        onClick={goBack}
                                        disabled={submitting}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.button}
                                        type="submit"
                                        disabled={pristine || submitting}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </Paper >
            </div>
        )
    }
}

export default withStyles(styles)(reduxForm({ form: 'product' })(Product))