import axios from 'axios'
import { BASE_URL } from '../settings.js'
import { showNotification } from '../services/notification'

export const GET_DEALERS = "GET_DEALERS";
export const ADD_DEALERS = "ADD_DEALERS";
export const UPDATE_DEALERS = "UPDATE_DEALERS";
export const UPDATE_FEDEX_DEALERS = "UPDATE_FEDEX_DEALERS";
export const DEALER_ACTION_ERROR = "DEALER_ACTION_ERROR";
export const DELETE_DEALER = "DELETE_DEALER";
export const SET_DEALER_INFO = "SET_DEALER_INFO";

const dealerURL = `${BASE_URL}/dealers`

export const getDealersFromApi = (token, dealerId) => {
    return async (dispatch) => {
        let modurl = dealerId ? `${dealerURL}/${dealerId}` : dealerURL;
        const request = await axios.get(modurl, {
            headers: {
                'Authorization': token,
            }
        })
        if (dealerId) {
            dispatch({
                type: SET_DEALER_INFO,
                payload: request
            })
        } else {
            dispatch({
                type: GET_DEALERS,
                payload: request
            })
        }
    }
}

export const deleteDealer = (token, IdDealer) => {
    return async (dispatch) => {
        await axios.delete(`${dealerURL}/${IdDealer}`, {
            headers: {
                'Authorization': token
            }
        }).then(() => {
            dispatch({
                type: DELETE_DEALER,
                payload: IdDealer
            })
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The Dealer has been deleted successfully',
                autoClose: true
            }
            showNotification(config);
        }).catch((error) => {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'An error has occurred. Please, try it again',
                autoClose: true
            }
            showNotification(config);
        })
    }
}

export const addDealer = (token, data, history) => {
    if (data.contactData && data.contactData.length === 1) {
        data.contactData[0].isMain = 1
    }
    return async (dispatch) => {
        var header = { 'Authorization': token }
        await axios.post(dealerURL, data, { headers: header })
            .then((response) => {
                dispatch({ type: ADD_DEALERS })
                let config = {
                    icon: 'check',
                    type: 'success',
                    message: 'The Dealer has been created successfully',
                    autoClose: true
                }
                showNotification(config);
                history.push('/dealers')
            })
            .catch((error) => {
                let errorMessage = 'An error has occurred. Please, try it again'
                let response = JSON.parse(error.request.response)
                if (response.code === "ER_DUP_ENTRY")
                    errorMessage = 'Error: This user already has a Dealer.'

                dispatch({ type: DEALER_ACTION_ERROR })
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: errorMessage,
                    autoClose: true
                }
                showNotification(config);
            })
    }
}

export const updateDealer = (token, id, data, history) => {
    return async (dispatch) => {
        var header = { 'Authorization': token };
        await axios.put(`${dealerURL}/${id}`, data, { headers: header })
            .then((response) => {
                dispatch({ type: UPDATE_DEALERS })
                let config = {
                    icon: 'check',
                    type: 'success',
                    message: 'The Dealer has been edited successfully',
                    autoClose: true
                }
                showNotification(config);
                history.push('/dealers')
            })
            .catch((error) => {
                let errorMessage = 'An error has occurred. Please, try it again'
                try {
                    let response = JSON.parse(error.request.response)
                    if (response && response.code === "ER_DUP_ENTRY")
                        errorMessage = 'Error: This user already has a Dealer.'
                }
                catch (error) { }

                dispatch({ type: DEALER_ACTION_ERROR })
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: errorMessage,
                    autoClose: true
                }
                showNotification(config);
            });
    }
}

export const updateFedexDealer = (token, id, data) => {
    return async (dispatch) => {
        var header = { 'Authorization': token };
        await axios.put(`${dealerURL}/${id}`, { itemName: 'FEDEXNUMBER', itemValue: data.FEDEXNUMBER }, { headers: header })
            .then((response) => {
                let config = {
                    icon: 'check',
                    type: 'success',
                    message: 'The FedEx Number Dealer has been updated successfully',
                    autoClose: true
                }
                showNotification(config);
                dispatch({
                    type: UPDATE_FEDEX_DEALERS,
                    payload: data
                })
            })
            .catch((error) => {
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: 'An error has occurred. Please, try it again',
                    autoClose: true
                }
                showNotification(config);
                dispatch({ type: DEALER_ACTION_ERROR })
            });
    }
}