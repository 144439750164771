import { SET_VIEWMODE } from '../actions'

export default function AppReducer(state = { currentViewMode: '' }, action) {
    switch (action.type) {
        case SET_VIEWMODE:
            return {
                ...state,
                currentViewMode: action.payload,
            }
        default:
            return state
    }
}
