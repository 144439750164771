import axios from 'axios'
import { BASE_URL } from '../settings.js'
import { showNotification } from '../services/notification'

export const GET_PRODUCTS = "GET_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const PRODUCT_ACTION_ERROR = "PRODUCT_ACTION_ERROR";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_ENABLE = "UPDATE_PRODUCT_ENABLE";
export const SAVE_IMAGE = "SAVE_IMAGE";
export const GET_PRODUTS_TYPES = "GET_PRODUTS_TYPES";

const url = `${BASE_URL}/products`

export const getProductsFromApi = (token, activeProduct = false) => {
    return async (dispatch) => {
        const request = await axios.get(url, {
            headers: {
                'Authorization': token,
            },
            params: {
                activeProduct: `${activeProduct}`
            }
        })
        dispatch({
            type: GET_PRODUCTS,
            payload: request
        })
    }
}

export const deleteProduct = (token, IdProduct) => {
    return async (dispatch) => {
        await axios.delete(`${url}/${IdProduct}`, {
            headers: {
                'Authorization': token,
            }
        }).then(() => {
            dispatch({
                type: DELETE_PRODUCT,
                payload: IdProduct
            })
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The Product has been deleted successfully',
                autoClose: true
            }
            showNotification(config);
        }).catch((error) => {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'An error has occurred. Please, try it again',
                autoClose: true
            }
            showNotification(config);
        })
    }
}

export const enableProduct = (token, IdProduct, value) => {
    return async (dispatch) => {
        await axios.put(`${url}/${IdProduct}/ENABLED/${value}`, {}, {
            headers: {
                'Authorization': token,
            }
        }).then(() => {
            dispatch({
                type: UPDATE_PRODUCT_ENABLE,
                payload: { id: IdProduct, name: 'ENABLED', value: value }
            })
            let config = {
                icon: 'check',
                type: 'success',
                message: 'The Product has been changed successfully',
                autoClose: true
            }
            showNotification(config);
        }).catch((error) => {
            let config = {
                icon: 'error',
                type: 'error',
                message: 'An error has occurred. Please, try it again',
                autoClose: true
            }
            showNotification(config);
        })
    }
}

export const addProduct = (token, data, history) => {
    return async (dispatch) => {
        var header = { 'Authorization': token };
        await axios.post(url, data, { headers: header })
            .then((response) => {
                dispatch({ type: ADD_PRODUCT })
                let config = {
                    icon: 'check',
                    type: 'success',
                    message: 'The Product has been created successfully',
                    autoClose: true
                }
                showNotification(config);
                history.push('/products')
            }).catch((error) => {
                dispatch({ type: PRODUCT_ACTION_ERROR })
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: 'An error has occurred. Please, try it again',
                    autoClose: true
                }
                showNotification(config);
                history.push('/home')
            });
    }
}

export const updateProduct = (token, id, data, history) => {
    return async (dispatch) => {
        var header = { 'Authorization': token }
        await axios.put(`${url}/${id}`, data, { headers: header })
            .then((response) => {
                dispatch({ type: UPDATE_PRODUCT })
                let config = {
                    icon: 'check',
                    type: 'success',
                    message: 'The Product has been edited successfully',
                    autoClose: true
                }
                showNotification(config);
                history.push('/products')
            }).catch((error) => {
                dispatch({ type: PRODUCT_ACTION_ERROR })
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: 'An error has occurred. Please, try it again',
                    autoClose: true
                }
                showNotification(config);
                history.push('/home')
            });
    }
}

export const saveImageForProduct = (product) => {
    return dispatch => {
        dispatch({
            type: SAVE_IMAGE,
            payload: product
        })
    }
}

export const insertCSV = (token, data, history) => {
    return async (dispatch) => {
        var header = { 'Authorization': token }
        await axios.post(`${BASE_URL}/products_batch`, data, { headers: header })
            .then((response) => {
                dispatch({ type: ADD_PRODUCT })
                let config = {
                    icon: 'check',
                    type: 'success',
                    message: 'The Product has been created successfully',
                    autoClose: true
                }
                showNotification(config);
                history.push('/products')
            }).catch((error) => {
                console.log(error)
                dispatch({ type: PRODUCT_ACTION_ERROR })
                let config = {
                    icon: 'error',
                    type: 'error',
                    message: 'An error has occurred. Please, try it again',
                    autoClose: true
                }
                showNotification(config);
            });
    }
}

export const getProductType = (token) => {
    return async (dispatch) => {
        let request = await axios.get(`${BASE_URL}/products/types`,
            {
                headers: {
                    'Authorization': token,
                }
            })
        dispatch({
            type: GET_PRODUTS_TYPES,
            payload: request.data.results
        })
    }
}