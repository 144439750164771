import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import AuthReducer from './AuthReducer';
import CustomersReducer from './CustomersReducer';
import DealersReducer from './DealersReducer';
import InventoriesReducer from './InventoriesReducer';
import KeysReducer from './KeysReducer';
import OrdersReducer from './OrdersReducer';
import ProductsReducer from './ProductsReducer';
import SalesReducer from './SalesReducer';
import UsersReducer from './UsersReducer';
import AppReducer from './AppReducer';
import PlaceOrderReducer from './PlaceOrderReducer';
import ReportSaleReducer from './ReportSaleReducer';
import DealerInfoReducer from './DealerInfoReducer';
import ProductTypeReducer from './ProductTypeReducer'
import { reducer as formReducer } from 'redux-form';


export default combineReducers({
	Router: routerReducer,
	Auth: AuthReducer,
	CustomersReducer: CustomersReducer,
	DealersReducer: DealersReducer,
	InventoriesReducer: InventoriesReducer,
	KeysReducer: KeysReducer,
	OrdersReducer: OrdersReducer,
	ProductsReducer: ProductsReducer,
	SalesReducer: SalesReducer,
	AppReducer: AppReducer,
	UsersReducer: UsersReducer,
	PlaceOrderReducer: PlaceOrderReducer,
	ReportSaleReducer: ReportSaleReducer,
	DealerInfo: DealerInfoReducer,
	ProductTypeReducer: ProductTypeReducer,
	form: formReducer
});