import React, { Component } from 'react';
import { withStyles, Card, TextField, Button, Grid, CardMedia, CardContent, Typography } from "@material-ui/core";
import logo from '../resources/Images/gemvision_authorized_dealer_icon.svg';

const styles = theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: '8%'
    },
    loginCard: {
        width: "500px !important"
    },
    content: {
        padding: "45px 70px !important"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: "100%",
        marginTop: 0,
        marginBottom: "20px"
    },
    media: {
        height: 100,
        backgroundColor: "#36363c",
        backgroundSize: "145px"
    },
    button: {
        margin: theme.spacing(),
        width: "80%"
    }
});

class ForgetPassword extends Component {
    render() {
        const {
            onInputValueChange,
            handleSubmit,
            email,
            classes,
            isButtonDisabled,
        } = this.props;
        return (
            <div className={classes.wrapper}>
                <Card className={classes.loginCard}>
                    <CardMedia
                        className={classes.media}
                        image={logo}
                        title="Contemplative Reptile"
                    />
                    <CardContent className={classes.content}>
                        <Typography gutterBottom variant="h5" component="h2">
                            Forgot username or password?
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            If your forgot your username or password, enter your email address and we will reset your password and send you an email with your new login information.
                        </Typography>
                        <form className="commentForm" onSubmit={e => {
                            e.preventDefault();
                            handleSubmit({ email });
                        }}>
                            <TextField
                                id="standard-username-input"
                                name="email"
                                label="Your email"
                                value={email}
                                className={classes.textField}
                                type="string"
                                autoComplete="current-password"
                                margin="normal"
                                onChange={onInputValueChange}
                            />
                            <Grid container justifyContent="center">
                                <Grid item xs={8}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        type="submit"
                                        className={classes.button}
                                        disabled={isButtonDisabled || email === ''}
                                    >
                                        Send
                                </Button>
                                </Grid>
                                <Grid container item xs={4} justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="large"
                                        className={classes.button}
                                        onClick={() => this.props.history.push('/')}
                                    >
                                        Cancel
                                </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(ForgetPassword)
