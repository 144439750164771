import React, { Component } from 'react';
import { withStyles, Paper, Typography, Grid } from '@material-ui/core';

class ReviewReportView extends Component {
    render() {
        const { userData } = this.props
        return (
            <Paper style={{ marginBottom: '10px', padding: '25px' }}>
                <Typography>{`This report is for: ${userData.COMPANY}`}</Typography>
                {!!this.props.showNoteToSaleReport ? <Typography>{`NOTE: If you need to report a sale for an additional user, complete this report and submit a separate report for each user.`}</Typography> : null}
                <Grid container>
                    <Grid item xs={6}>
                        <Typography >ADDRESS</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>CONTACT</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{userData.ADDRESS1}</Typography>
                        <Typography>{userData.CITY_STATEPROVINCE}</Typography>
                        <Typography>{userData.POSTALCODE}</Typography>
                        <Typography>{userData.Country}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{userData.FIRSTNAME} {userData.LASTNAME}</Typography>
                        <Typography>{userData.PHONE}</Typography>
                        <Typography>{userData.EMAIL}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(null)(ReviewReportView);